import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  usernames = '';
  loginpageUrl = "/login";
  logoutUrl = "/login";
  loginProfileImageUrl = 'https://coach.thap.in/thap_coach/assets/img/theme/team-4-800x800.jpg'
  constructor(location: Location, private element: ElementRef, private router: Router, private ApiService: ApiService) {
    this.location = location;
  }



  ngOnInit() {
    // if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
    //   window.location.href='#/'
    // }
    this.usernames = localStorage.getItem('userName');
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.getProfilePictureApi();

  }
  logout() {
    this.router.navigate([this.logoutUrl]);
    localStorage.removeItem('email');
    localStorage.removeItem('userid');
    localStorage.removeItem('userName');
    localStorage.removeItem('authtoken');
  }


  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  async getProfilePictureApi() {
    var userids = JSON.parse(localStorage.getItem('userid'));
    var postApi = "/coach_view_profile_picture";
    // --------------------
    var uploadPicPost = {
      "id": userids
    };
    (await this.ApiService.postApi(postApi, uploadPicPost)).subscribe(
      res => {
        var getProfilePicdata = res.result;
        for (let item of getProfilePicdata) {

          var imagedata = item.thcoach_image;

          // if (getProfilePicdata.length > 0) {
          //   this.loginProfileImageUrl = imagedata;
          // }
          // else if (getProfilePicdata.length = 0) {
          //   this.loginProfileImageUrl = 'https://fatneedle.com/thap_coach/assets/img/theme/team-4-800x800.jpg';
          // }

          if (imagedata == null) {
            this.loginProfileImageUrl = 'https://coach.thap.in//thap_coach/assets/img/Characters/happy.png';
          }
          else if (imagedata != null) {
            this.loginProfileImageUrl = imagedata;

          }

        }
      });
  }

  logout_clear() {
    this.router.navigate([this.loginpageUrl]);
    localStorage.clear();
  }



}
