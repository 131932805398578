<div class="row header_bar">
  <div class="col-2 project_name align_center ">
    <img alt="Image placeholder" class="img-fluid log_img" src="assets/img/Icon/Web_logo.png">
  </div>
  <div class="col-8 align_center header_heading">
    <p class="header_heading">An adventure to wholesome life awaits..........</p>
  </div>
  <div calss="col-2 pt_5">
    <!-- <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center bg_style">
            <span class="avatar avatar-sm rounded-circle">

              <img class="img-fluid header_image"
              style="border-radius: 50px; height: 36px; width: 36px;" [src]="loginProfileImageUrl">
            </span>
            <div class="media-body ml-2 d-none d-lg-block ">
              <span class="mb-0 text-sm  font-weight-bold white_clr">Dr.{{usernames}} &nbsp;<i class="fa fa-angle-down" aria-hidden="true"></i>&nbsp;&nbsp;</span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
          </div>
          <a routerLinkActive="active" [routerLink]="['/uploadprofile']" class="dropdown-item">
            <span>My profile</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/notification']" class="dropdown-item">
            <span>Notification</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/dashboard']" class="dropdown-item">
            <span>My Account Settings</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/dashboard']" class="dropdown-item">
            <span>FAQs</span>
          </a>
          <div class="dropdown-divider"></div>
          <a routerLinkActive="active" [routerLink]="['/login']" class="dropdown-item">
            <span>Logout</span>
          </a>
        </div>
      </li>
      </ul> -->
  </div>
</div>
<div class="row">
  <div class="body_bar col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 circle-section">
    <!-- -- -->
    <div class="padd-l-30 padd-t-20">
      <ol class="breadCrumb_flex fsize24">
        <li class="breadcrumb-item">
          <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/']">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Signup</li>
      </ol>
    </div>
    <!-- ---- -->
    <div class="container  pb-5 ">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-md-6 col-sm-6 col-xs-12">
          <div class="card shadow border-0">
            <img class="img-fluid" src="assets/img/Characters/happy.png">
            <div class="card-body px-lg-5 ">
              <div class="text-center text-muted mb-3 mt-4">
                <small class="login">Signup</small>
              </div>
              <div class="text-center text-muted mb-4">
                <small class="login_text">Happiness Coach Portal</small>
              </div>
              <div>
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Email *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="text" id="email" formControlName="email" class="form-control input_bdr_clr "
                        maxlength="100" />
                      <div *ngIf="submitted && registerForm.get('email').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('email').errors.required">Email is required</div>
                        <div *ngIf="registerForm.get('email').errors.email">Email is invalid</div>
                      </div>
                      <!-- <div *ngIf="submitted && registerForm.get('email').errors" class="invalid-feedback">
                        <div *ngIf="registerForm.get('email').errors.required">First Name is required</div>
                        </div> -->
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Password *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <div class="form-group">
                        <div>
                          <input type="password" id="passWord" formControlName="password" maxlength="50"
                            class="form-control input_bdr_clr " />
                        </div>
                        <!-- <div *ngIf="submitted && registerForm.get('password').errors" class="alert alert-danger">
                          <div *ngIf="registerForm.get('password').errors.required">Password is required</div>
                          </div> -->
                        <div class="errors fsize12" *ngIf="
                          registerForm.get('password').invalid &&
                          (registerForm.get('password').touched || registerForm.get('password').dirty)
                          ">
                          <div class="alert alert-danger fsize12"
                            *ngIf="registerForm.get('password').hasError('required')">
                            password is required
                          </div>
                          <div class="alert alert-danger fsize12"
                            *ngIf="registerForm.get('password').errors.minlength">
                            Password must be at least 8 characters
                          </div>
                          <div class="alert alert-danger fsize12"
                            *ngIf="registerForm.get('password').hasError('passwordStrength')">
                            {{ registerForm.get('password').errors['passwordStrength'] }}
                          </div>
                        </div>
                        <!-- -------- -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Confirm Password *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="password" id="confirmPassword" formControlName="confirmPassword" maxlength="50"
                        class="form-control input_bdr_clr " />
                      <div *ngIf="submitted && registerForm.get('confirmPassword').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('confirmPassword').errors.required">Confirm Password is required
                        </div>
                        <div *ngIf="registerForm.get('confirmPassword').errors.confirmPasswordValidator">Passsword and
                          Confirm Password didn't match.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Full Name *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="text" id="fullName" formControlName="fullName" maxlength="100"
                        class="form-control input_bdr_clr " />
                      <div *ngIf="submitted && registerForm.get('fullName').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('fullName').errors.required">Full Name is required</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Licence Number</label>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="text" id="licenceNumber" formControlName="licenceNumber"
                      maxlength="100"
                        class="form-control input_bdr_clr " />

                      <div *ngIf="submitted && registerForm.get('licenceNumber').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('licenceNumber').errors.required">Licence Number is required
                        </div>
                      </div>
                    </div>

                  </div>

                  </div> -->
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Area of expertise *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <!-- <input type="text" id="areaOfexpertise" formControlName="areaOfExpertise"
                        class="form-control input_bdr_clr" maxlength="4000" /> -->
                      <!-- <textarea id="areaOfexpertise" formControlName="areaOfExpertise"
                        class="form-control input_bdr_clr" maxlength="65535" >
                        </textarea> -->
                      <div style="border: 1px solid #cad1d7; border-radius:0.375rem;">
                        <select id="multiple-checkboxes" multiple="multiple">
                        <option [value]="[item.id]" *ngFor="let item of dropdownList">{{item.expertise}}</option>
                        </select>
                      </div>
                      <!-- <div *ngIf="submitted && registerForm.get('areaOfExpertise').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('areaOfExpertise').errors.required">Area of expertise is required
                        </div>
                        </div> -->
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Types of therapy/Methodology *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <!-- <input type="text" formControlName="methodology" id="methodology" maxlength="4000"
                        class="form-control input_bdr_clr " /> -->
                      <textarea formControlName="methodology" id="methodology" maxlength="65535"
                        class="form-control input_bdr_clr ">
                  </textarea>
                      <div *ngIf="submitted && registerForm.get('methodology').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('methodology').errors.required">Types of therapy is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Years of experience *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="text" formControlName="exPerience" id="exPerience"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="form-control input_bdr_clr " maxlength="2" />
                      <div *ngIf="submitted && registerForm.get('exPerience').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('exPerience').errors.required">Years of experience is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Qualification *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="text" id="accreditingBody" formControlName="accreditingBody"
                        class="form-control input_bdr_clr " maxlength="65536" />
                      <div *ngIf="submitted && registerForm.get('accreditingBody').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('accreditingBody').errors.required">Qualification is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">Language *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <!-- <input type="text" id="language" formControlName="language"
                        class="form-control input_bdr_clr " maxlength="255" /> -->
                      <div style="border: 1px solid #cad1d7; border-radius:0.375rem;">
                        <select id="multiple-checkboxesOne" multiple="multiple">
                        <option [value]="[item.id]" *ngFor="let item of dropdownLanguageList">{{item.language}}
                        </option>
                        </select>
                      </div>
                      <!-- <div *ngIf="submitted && registerForm.get('language').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('language').errors.required">Language is required
                        </div>
                        </div> -->
                    </div>
                  </div>
                </div>
                <!-- ------------------------------------------------ -->
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">What to expect in your first therapy session
                      *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="text" id="firstTherapySession" formControlName="firstTherapySession"
                        class="form-control input_bdr_clr " maxlength="65535" />
                      <div *ngIf="submitted && registerForm.get('firstTherapySession').errors"
                        class="alert alert-danger">
                        <div *ngIf="registerForm.get('firstTherapySession').errors.required">What to expect in your
                          first therapy session
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy">First Session Free *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <!-- <input type="text" id="firstSessionFree" formControlName="firstSessionFree"
                        class="form-control input_bdr_clr " maxlength="100" /> -->
                      <select (change)="questionOne($event)" id="firstSessionFree" formControlName="firstSessionFree"
                        class="form-control input_bdr_clr ">
                        <option value="0">Yes</option>
                        <option value="1">No</option>
                      </select>
                      <div *ngIf="submitted && registerForm.get('firstSessionFree').errors"
                        class="alert alert-danger">
                        <div *ngIf="registerForm.get('firstSessionFree').errors.required">What to expect in your first
                          therapy session
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="showNoDiv">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy marg-t-8">First Session Price *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="text" [(ngModel)]="firstSession_Free" id="sessionPrice"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      class="form-control input_bdr_clr " [ngModelOptions]="{standalone: true}"/>
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="showYesDiv">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy marg-t-8">First Session Price *</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="text"  id="sessionPrice"
                        disabled
                        placeholder="0"
                        class="form-control input_bdr_clr " />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row marg-0">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label class="fsize16 clr_black font_bold_gilroy marg-t-8"> Subsequent price*</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <input type="text" id="subsequent" [(ngModel)]="subsequent_price"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      class="form-control input_bdr_clr " />
                      <!-- <div *ngIf="submitted && registerForm.get('exPerience').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('exPerience').errors.required">Years of experience is required
                        </div>
                        </div> -->
                    </div>
                  </div>
                </div>
                <div class="row  marg-t-40 ">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                    <div class="form-group upload-form">
                      <button type="submit" class="btn  signup_btn" (click)="signin()">Submit</button>
                    </div>
                  </div>
                </div>
                </form>
                <div class="row  marg-t-40 ">
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                    <div class="form-group">
                      <button (click)="alreadyAccount()" class="btn  already_Sign_btn">Already have account <span
                        class="clr_green">Signin</span> </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="basr_cls div_last"></div>
