<div class="header pb-8 bgr_clr_f5fcff ">
    <div class="container-fluid">
        <div class="header-body">
            <div class="padd-80-50">
                <div class="">
                    <!-- <nav aria-label="breadcrumb"> -->
                    <ol class="breadCrumb_flex fsize24">
                        <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                                [routerLink]="['/dashboard']">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Availability Pick date</li>
                    </ol>
                    <!-- </nav> -->
                </div>
                <div class="  ">
                    <div class="row marg-0">
                        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12 padd-0">

                            <div class="calendar_bdr">

                                <div class="text_center padd-t-50"><img class="h-80 w-80 text_center"
                                        src="assets/img/Characters/happy.png"></div>

                                <div class="font_clr_white text-center fsize30">
                                    Availability
                                </div>
                                <div class="font_clr_white text-center fsize30 mt-5">
                                    <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="1"
                                    [minDate]="minPickerDate"
                                    [maxDate]="{year: 2108, month: 12, day: 31}"
                                        [dayTemplate]="t" outsideDays="hidden">
                                    </ngb-datepicker>

                                    <ng-template #t let-date let-focused="focused" let >
                                        <span class="custom-day" [class.focused]="focused"  [class.range]="isRange(date)"
                                            [class.faded]="isHovered(date) || isInside(date)"
                                            (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                            {{ date.day }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12 padd-0">

                            <div class="card_s marg-l-50">

                                <div class="padd-l-80 padd-t-36">
                                    <div class=" ">


                                        <div class="font_clr_black  fsize23 font_family_Gilroy">
                                            Pick date
                                        </div>

                                        <div class="marg-t-20">

                                            <div class="row marg-0 ">
                                                <div>
                                                    <button id="Single" (click)="selectbtns()" value="Single"
                                                        class="select_btn {{active_span_Select_single}}">Select
                                                        Single</button>
                                                </div>

                                                <div class="marg-l-20">
                                                    <button id="Multiple" value="Multiple" (click)="multipleBtn()"
                                                        class="select_btn {{active_span_Select_multiple}}">Select
                                                        Multiple</button>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="marg-t-44 font_clr_black fsize23 font_family_Gilroy">
                                            Duration of session

                                        </div>
                                        <div class="marg-t-20">
                                            <div class="row marg-0">

                                                <div>
                                                    <button id="thirty" value="00:30:00"
                                                        class="duration_btn {{active_span_day}}"
                                                        (click)=" thirtyMin_div()">30 mins
                                                    </button>
                                                </div>
                                                <div class="marg-l-20">
                                                    <button id="sixty" value="01:00:00"
                                                        class="duration_btn  {{active_span_week}}"
                                                        (click)="sixtymin_div()"> 60 mins
                                                    </button>
                                                </div>

                                            </div>
                                        </div>




                                        <div class="row marg-0">

                                            <div class="marg-t-20">

                                                <!-- <div class="input-group clockpicker">
                                                    <input type="text" class="h-60 w-245" style="padding: 10px;"
                                                        placeholder="Click To See The Watch">
                                                    <span class="input-group-addon">
                                                        <span class="glyphicon glyphicon-time"></span>
                                                    </span>
                                                </div> -->

                                                <div class="font_clr_black fsize23 font_family_Gilroy">
                                                    Select Start Time
                                                </div>

                                                <!-- <div class="input-group clockpicker" data-autoclose="true">
                                                    <input type="text" id="startTime" class="h-60 w-245" style="padding: 10px;"
                                                        placeholder="Click To See The Watch">
                                                    <span class="input-group-addon">
                                                        <span class="glyphicon glyphicon-time"></span>
                                                    </span>
                                                </div> -->
                                                <!-- ------ -->

                                                <div>
                                                    <!-- <label>Start time (12 hour clock):</label> -->
                                                    <input id="input-12-hour" class="h-60 w-245 selectTime_btn"
                                                        style="padding: 10px;" value="" data-default="09:30"
                                                        placeholder="Click To See The Watch" />
                                                </div>

                                            </div>

                                            <div class="marg-l-20 marg-t-20">

                                                <div class="font_clr_black fsize23 font_family_Gilroy">
                                                    Select End Time
                                                </div>


                                                <input id="input-12-hour_Two" class="h-60 w-245 selectTime_btn"
                                                    style="padding: 10px;" value="" data-default="09:30"
                                                    placeholder="Click To See The Watch" />

                                                <!-- <div class="input-group clockpicker" data-autoclose="true">
                                                    <input type="text" id="selectEndTime" class="h-60 w-245"
                                                        style="padding: 10px;" placeholder="Click To See The Watch">
                                                    <span class="input-group-addon">
                                                        <span class="glyphicon glyphicon-time"></span>
                                                    </span>
                                                </div> -->
                                            </div>
                                        </div>
                                        <!-- -------------------------------- -->

                                        <!-- <div *ngIf="show_30min_div">

                                            <div class="marg-t-20">
                                                <button id="clk_3_30min" value="03:00" (click)="time3clk_30min()"
                                                    class="time_work_btn">03:00 pm</button>
                                            </div>
                                            <div class="marg-t-15">
                                                <button id="clk3_30_30min" (click)="time3_30clk_30min()" value="03:30"
                                                    class="time_work_btn">03:30 pm</button>
                                            </div>
                                            <div class="marg-t-15">
                                                <button id="clk_4_30min" value="04:00" (click)="time4clk_30min()"
                                                    class="time_work_btn">04:00 pm</button>
                                            </div>
                                            <div class="marg-t-15">
                                                <button id="clk4_30_30min" value="04:30" (click)="time4_30clk_30min()"
                                                    class="time_work_btn">04:30 pm</button>
                                            </div>

                                        </div> -->
                                        <!-- ----------------------------------------------------------------- -->

                                        <!-- <div *ngIf="show_60min_div">

                                            <div class="marg-t-20">
                                                <button id="clk_3_60min" value="03:00" (click)="time3clk_60min()"
                                                    class="time_work_btn">03:00 pm</button>
                                            </div>
                                            <div class="marg-t-15">
                                                <button id="clk4_60min" value="04:00" (click)="time4clk_60min()"
                                                    class="time_work_btn">04:00 pm</button>
                                            </div>
                                            <div class="marg-t-15">
                                                <button id="clk5_60min" value="05:00" (click)="time5clk_60min()"
                                                    class="time_work_btn">05:00 pm</button>
                                            </div>
                                            <div class="marg-t-15">
                                                <button id="clk6_60min" value="06:00" (click)="time6clk_60min()"
                                                    class="time_work_btn">06:00 pm</button>
                                            </div>

                                        </div> -->


                                        <div class="marg-t-32 font_clr_black fsize23 font_family_Gilroy">
                                            Appointment cutoff time
                                        </div>

                                        <div class="marg-t-20">

                                            <div class="row marg-0">
                                                <div>
                                                    <button id="twentyFourHours" value="4" (click)="twentyfourHour()"
                                                        class="cuttoff_time_btn {{active_span_twentyFour_Hr}} ">
                                                        4 hours
                                                        before
                                                    
                                                    </button>
                                                </div>
                                                <div class="marg-l-20">
                                                    <button id="twellHours" value="12" (click)="twellHour()"
                                                        class="cuttoff_time_btn {{active_span_twell_Hr}}">12 hours
                                                        before</button>
                                                </div>
                                                <div class="marg-l-20">
                                                    <button id="nohour" value="0" (click)="noHours()"
                                                        class="cuttoff_time_btn {{active_span_no_Hr}}">No Cutoff time
                                                       </button>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="marg-t-40 padd-b-32">

                                            <button class="confirm_btn" (click)="confirm_btn()">Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- -------------------------content------------------------------------------- -->
                    <div>
                        <!-- <div class="text-center marg-t-30 padd-b-90">
                                <button class="done_btn" type="button">Update availability</button>
                            </div> -->






                    </div>

                </div>

            </div>
        </div>
    </div>
</div>