import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { DataTablesModule } from 'angular-datatables';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { AvailibilityComponent } from './pages/availibility/availibility.component';
import { TraineeNotesComponent } from './pages/trainee-notes/trainee-notes.component';
import { RosterJournalComponent } from './pages/roster-journal/roster-journal.component';
import { HappinessCommunityComponent } from './pages/happiness-community/happiness-community.component';
import { HappinessCommunityNotesComponent } from './pages/happiness-community-notes/happiness-community-notes.component';
import { AvailibiltyTwoComponent } from './pages/availibilty-two/availibilty-two.component';
import { AvailabilityMonthComponent } from './pages/availability-month/availability-month.component';
import { HappinessCommunityCommentComponent } from './pages/happiness-community-comment/happiness-community-comment.component';
import { RosterMessageCenterComponent } from './pages/roster-message-center/roster-message-center.component';
import { AvailabilityCalenderComponent } from './pages/availability-calender/availability-calender.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import { HappinessTrainingSessionComponent } from './pages/happiness-training-session/happiness-training-session.component';
import { AvailabilityPickdateComponent } from './pages/availability-pickdate/availability-pickdate.component';
// -----------------------------------------------------------------------------------------------------------------
// import {MaterialExampleModule} from '../material.module';
// import {DatepickerInlineCalendarExample} from './datepicker-inline-calendar-example';
import { MessageCenterListComponent } from './pages/message_center_list/message_center_list.component';
import { TraineeRosterListComponent } from './pages/trainee_roster_list/trainee_roster_list.component';
import { SignupComponent } from './signup/signup.component';
import { ReactiveFormsModule   } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TokenInterceptorInterceptor } from './token-interceptor.interceptor';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotpasswordOtpComponent } from './forgotpassword-otp/forgotpassword-otp.component';
import { CommonModule } from '@angular/common';
import { HappinessTraineeComponent } from './pages/happiness_trainee/happiness_trainee.component'; 
// import { NgxAgoraSdkNgModule } from 'ngx-agora-sdk-ng';
import { AgoraVideoPlayerDirective } from './pages/directives/agora-video-player.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RoundTranparentIconButtonComponent } from './pages/round-tranparent-icon-button/round-tranparent-icon-button.component';
import {ToastrModule} from 'ngx-toastr';
import { InputOutputSettingsComponent } from './pages/input-output-settings/input-output-settings.component';
import { MeetingControlsComponent } from './pages/meeting-controls/meeting-controls.component';
import { MeetingParticipantComponent } from './pages/meeting-participant/meeting-participant.component';
import {MeetingComponent} from './pages/meeting/meeting.component';
// import { MeetingPageComponent } from './pages/meeting-page/meeting-page.component';
import { MeetingPreviewComponent } from './pages/meeting-preview/meeting-preview.component';
import { CameraPreviewComponent } from './pages/camera-preview/camera-preview.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatIconModule } from '@angular/material/icon';
// import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
// import { MessageChatCenterComponent } from './message-chat-center/message-chat-center.component';
import { BillingHoursComponent } from './pages/billing-hours/billing-hours.component';
import { NotificationModuleComponent } from './pages/notification-module/notification-module.component';
import { ForgotmailpasswordComponent } from './pages/reset-mail-password/reset-mail-password.component';
import { TraineeAddNotesComponent } from './pages/trainee-add-notes/trainee-add-notes.component';
import { TraineeAddNewtipComponent } from './pages/trainee-add-newtip/trainee-add-newtip.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppointmenthistoryComponent } from './appointmenthistory/appointmenthistory.component';
import { ResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';

// import { SampleRedirectPageComponent } from './sample-redirect-page/sample-redirect-page.component';
// import { FormGroup, FormControl, } from '@angular/forms';

// import AgoraRTC from 'agora-rtc-sdk-ng';
// import { MessageCenterlistChatComponent } from './pages/message-centerlist-chat/message-centerlist-chat.component';
// import { MaterialTimePickerModule } from './material-timepicker/material-timepicker.module';
// import { NotificationComponent } from './pages/notification/notification.component';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    DataTablesModule,
    BrowserModule,
    MatSelectModule,

    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatDatepickerModule,
    MatCardModule,
    CommonModule,
    NgxMatTimepickerModule,
    MatIconModule,
    ToastrModule.forRoot(),
    // FormGroup,
    // FormControl,
    // NgxAgoraSdkNgModule.forRoot({
    //   AppID: '4288652efe744f3ebda5f5cf5a2cf4af',
    //   Video: { codec: 'h264', mode: 'rtc', role: 'host' }
    // }),

    NgxMaterialTimepickerModule,
    NgxPaginationModule
    // NotificationComponent
  // MaterialExampleModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AvailibilityComponent,
      TraineeNotesComponent,
      RosterJournalComponent,
      HappinessCommunityComponent,
      HappinessCommunityNotesComponent,
      AvailibiltyTwoComponent,
      AvailabilityMonthComponent,
      HappinessCommunityCommentComponent,
      RosterMessageCenterComponent,
      AvailabilityCalenderComponent,
      MessageCenterListComponent,
      TraineeRosterListComponent,
      HappinessTrainingSessionComponent,
      AvailabilityPickdateComponent,
      SignupComponent,
      ForgotPasswordComponent,
      ForgotpasswordOtpComponent,
      ResetPasswordComponent,
      HappinessTraineeComponent,
      AgoraVideoPlayerDirective,
      RoundTranparentIconButtonComponent,
      InputOutputSettingsComponent,
      MeetingControlsComponent,
      // MeetingPageComponent,
      MeetingParticipantComponent,
      MeetingComponent,
      MeetingPreviewComponent,
      CameraPreviewComponent,
      // MessageChatCenterComponent,
      BillingHoursComponent,
      NotificationModuleComponent,
      ForgotmailpasswordComponent,
      TraineeAddNotesComponent,
      TraineeAddNewtipComponent,
      AppointmenthistoryComponent,
      
      // SampleRedirectPageComponent,
      // MessageCenterlistChatComponent
     // DatepickerInlineCalendarExample
         ],
  providers: [DatePipe,{ provide:HTTP_INTERCEPTORS,
    useClass:TokenInterceptorInterceptor,
    multi:true}],
  bootstrap: [AppComponent],
 
})
export class AppModule { }
