<!-- <div class="row header_bar">

    <div class="col-2 project_name  "> 
       <img alt="Image placeholder" class="img-fluid log_img" src="assets/img/Icon/Web_logo.png">
    </div>
    <div class="col-8  header_heading"> 
         <p class="header_heading">An adventure to wholesome life awaits..</p>
    </div>
    <div calss="col-2 pt_5" >
        <p class="header_heading"><img class="img_menu" src="assets/img/Icon/menu.png"></p>
    </div>
 </div> -->

<div class="row">
  <div class="body_bar col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 circle-section">

    <div class="container  pb-5 margin_t_60 ">

      <div class="row justify-content-center">

        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <div class="card cardSize shadow border-0">
            <img class="img-fluid" src="assets/img/Characters/happy.png">
            <div class="card-body px-lg-5 ">
              <div class="text-center text-muted mb-3 mt-4">
                <small class="login">Forgot Password</small>
              </div>
              <div class="text-center text-muted mb-4">
                <small class="login_text">Please enter the OTP send to your Email / Phone Number</small>
              </div>
              <form role="form">
                <!-- <div class="form-group mb-3">
                    <div class=" marg-l-15">
                      <input class="form-control emailInput" placeholder="Email / Phone Number" id="email" type="email">
                    </div>
                  </div> -->

                <div class="row marg-0">

                  <div class=" marg-l-15">
                    <input class="otpInput marg-l-15" id="email">
                    <input class="otpInput marg-l-8" id="email">
                    <input class="otpInput marg-l-8" id="email">
                    <input class="otpInput marg-l-8" id="email">
                  </div>
                </div>

                <div class=" marg-t-18 marg-l-15">
                  <button type="button" (click)="submit_btn()"  class="btn submit_button">Submit</button>
                </div>

                <div class="row mt-3">
                  <div class="col-6">
                    <a href="javascript:void(0)" class=" ">
                      Back to
                      <a href="" class=" blue">Signin</a>

                      <!-- <small></small> -->

                    </a>
                  </div>
                  <div class="col-6 text-right">
                    <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/signup']" class=" blue">
                      Signup
                      <!-- <small></small> -->
                    </a>
                  </div>
                </div>

              </form>
            </div>
          </div>

        </div>


      </div>


    </div>
    <!-- <div class="circle-img left-side"> 
        <img class="img-fluid" src="assets/img/Icon/Bg_ptn_left.png">
      </div>
      <div class="circle-img right-side"> 
        <img class="img-fluid" src="assets/img/Icon/Bg_ptn_right.png">
      </div> -->


  </div>
</div>