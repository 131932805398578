import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
@Component({
  selector: 'app-trainee-notes',
  templateUrl: './trainee-notes.component.html',
  styleUrls: ['./trainee-notes.component.css']
})
export class TraineeNotesComponent implements OnInit {

  trainee_url = "/roster-journal";
  returnUrl = "/trainee-roster-list";
  messageUrl = "/trainee-roster";
  previousHistoryUrl = "/happiness_trainee";
  unauthorized_url = "/";
  addNewNotesUrl = "/trainee_add_Newnotes"
  addNewTipsUrl = "/trainee_add_NewTips"
  notesAll: any;
  goalAll: any;
  noteDatas: any;
  goalDatas: any;
  isShown: boolean = false;
  userAllId: any;
  userName: any;
  sessionUserid: any;
  getJournalList = [];
  journal_Type: any;
  journalTitle: any;
  user_Id: any;
  messageCenterText: any;
  journalDate: any;
  session_newId: any;
  journalUserId: any;
  constructor(private router: Router, private ApiService: ApiService, public toastr: ToastrService) { }

  async ngOnInit() {
    if (!localStorage.getItem('authtoken') && window.location.hash != '#/login') {
      window.location.href = '#/'
    }
    this.userName = localStorage.getItem('userListName');
    this.sessionUserid = localStorage.getItem('sessionHistoryUsrid');

    // this.session_newId = localStorage.getItem('session_newId');

    this.userAllId = this.ApiService.user_ids;
    this.user_Id = localStorage.getItem('userid');
    // var coach_Id = localStorage.getItem('coachId');
    var userids = JSON.parse(localStorage.getItem('userid'));
    this.journalUserId = localStorage.getItem('peerid');

    // var getapi = "/get_session_history?"  + "user_id=" + userids + "&type=notes" ;
    // (await this.ApiService.getApi(getapi)).subscribe((res) => {
    //   console.log(res.data);
    //   if (res) {
    //     if (res.status === 200) {
    //       this.notesAll = res.data[0].thchhistory_value;
    //       console.log(this.notesAll);
    //     }
    //     else if (res.status === 401) {
    //       this.toastr.error('everything is broken', 'Major Error', {
    //         timeOut: 3000,
    //       });
    //       this.router.navigate([this.unauthorized_url]);

    //     }
    //   }
    // });
    // -----------------------------
    var userids = JSON.parse(localStorage.getItem('userid'));
    // -----------------------------------------------------
    //     var postapi = "/get_session_history";
    // var postCommentdata = {
    //   "user_id":  userids,
    //   "type":"note"
    // };
    // (await this.ApiService.postApi(postapi, postCommentdata)).subscribe(
    //   res => {
    //     console.log(res);
    //     this.noteDatas = res.data;
    // });
    // ---------------
    // ---------------get Session Api goal-----------------
    // var postapi = "/get_session_history";
    // var postCommentdata = {
    //   "user_id":  this.userAllId,
    //   "type":"goal"
    // };
    // (await this.ApiService.postApi(postapi, postCommentdata)).subscribe(
    //   res => {
    //     console.log(res);
    //     this.goalDatas = res.data;
    //     for(let item of this.goalDatas ){
    //      this.goalAll = item.thchhistory_value;
    //     }
    //  });
    // -------------------------------
    this.getViewSessionApi();
    this.getShareJournalListApi();

  }

  journal_history() {
    this.router.navigate([this.trainee_url]);
  }
  back() {
    this.router.navigate([this.returnUrl]);
  }
  message() {
    this.saveListMsgApi();
    this.router.navigate([this.messageUrl]);
  }
  view_previousdata() {
    this.router.navigate([this.previousHistoryUrl]);
  }

  async getViewSessionApi() {
    var postapi = "/view_user_session";
    var postCommentdata = {
      "user_id": this.sessionUserid,
    };

    (await this.ApiService.postApi(postapi, postCommentdata)).subscribe(
      res => {
        console.log(res);
        this.goalDatas = res.data.reverse();
        for (let item of this.goalDatas) {
          var type = item.thchhistory_category;
          if (type == "note") {
            this.notesAll = item.thchhistory_value;
            //  console.log(pandi,'pandd')
          }
          if (type == "goal") {
            this.goalAll = item.thchhistory_value;
          }
          // if (item.session_id) {
          //   validSessionId.push(item.session_id);
          // }
          if (item.session_id) {

            this.session_newId = item.session_id;

            this.router.navigate(['/trainee-notes']);
            localStorage.setItem("session_id", JSON.stringify(res?.data?.[0]?.session_id))
            console.log("session_idsession_id", localStorage.setItem("session_id", JSON.stringify(res?.data?.[0]?.session_id)))
          }
        }
      });
  }

  // ----------------------------------------

  async getShareJournalListApi() {
    var postApi = "/user_share_journal_list";

    var postjournalUserId = {
      "user_id": this.journalUserId
    };

    (await this.ApiService.postApi(postApi, postjournalUserId)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            this.getJournalList = res.data;
            for (let item of this.getJournalList) {
              this.journal_Type = item.thjournal_input_type;
              console.log(this.journal_Type, 'types');
              var journal_titles = item.thjournal_title;
              // thjournal_title
              var datejournal = item.thjournal_date;
              this.journalDate = moment(datejournal).format('DD-MM-YYYY');

              this.journalTitle = journal_titles;
              // console.log(this.journalTitle,'audio');


            }

          }
        }
      });
  }

  //    updateSource(){
  //     var audio = document.getElementById('wavSource');
  //     audio.src = 'http://www.wavsource.com/snds_2018-06-03_5106726768923853/people/men/about_time.wav';

  //     var a = document.getElementById('audio');
  //     a.load();
  // }
  // -------------------------------------------


  async saveListMsgApi() {

    if (!this.session_newId) {
      console.error("Session ID is not available.");
      return;
    }

    var postApi = "/save_message";
    var getlistMsgdata = {
      "sender_id": this.user_Id,
      "receiver_id": this.sessionUserid,
      "session_id": this.session_newId,
      "message": this.messageCenterText
    };
    (await this.ApiService.postApi(postApi, getlistMsgdata)).subscribe(
      res => {
        console.log('API Response:', res);

      })
    error => {
      // Handle errors here
      console.error('API Error:', error);
    }
  }

  addNewNote() {
    this.router.navigate([this.addNewNotesUrl]);
  }
  addNewTips() {
    this.router.navigate([this.addNewTipsUrl]);
  }





}
