import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
declare var $;
declare var swal: any;
@Component({
  selector: 'app-availability-pickdate',
  templateUrl: './availability-pickdate.component.html',
  styleUrls: ['./availability-pickdate.component.css']
})
export class AvailabilityPickdateComponent implements OnInit {

  hoveredDate: NgbDate | null = null;
  isShown: boolean = false;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  show_30min_div = true;
  show_60min_div = false;
  show_both_div = false;
  active_span_day = 'active_span';
  active_span_Select_single = 'active_span';
  active_span_Select_multiple = '';
  active_span_week = '';
  active_span_month = '';
  active_span_twentyFour_Hr = 'active_span';
  active_span_twell_Hr = '';
  active_span_no_Hr = '';
  selectSingle: any;
  selctsingle: any = false;
  selectMultiple: any;
  // selctsingle = false;
  thirtyMinutesDifferent: any;
  multiplesSelect: any;
  thirtyMinute: any;
  sixtyMinute: any;
  both_min: any;
  allMinute: any;
  hours: any;
  to_date: any;
  from_date: any;
  clkTime: any;
  formControlItem: FormControl = new FormControl('');
  required: boolean = !1;
  minutessetTime: any;
  
  @ViewChild('timepicker') timepicker: any;
  dates: moment.Moment[] = [];

  // -------------------------------------------
  openFromIcon(timepicker: { open: () => void }) {
    if (!this.formControlItem.disabled) {
      timepicker.open();
    }
  }

  onClear($event: Event) {
    this.formControlItem.setValue(null);
  }
  minPickerDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  // ------------------------------------

  // --------------------------------------------
  constructor(calendar: NgbCalendar, private router: Router, private ApiService: ApiService,) {

    this.fromDate = calendar.getToday();
    // console.log(this.fromDate,'fromDate' );
    this.toDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    // console.log(this.toDate , 'To date');
  }

  returnUrl = "/availibility";

  singleDate: NgbDate = new NgbDate(parseInt(moment().format("YYYY")) as number, parseInt(moment().format("MM")) as number, parseInt(moment().format("DD")) as number);
  fromd: NgbDate = new NgbDate(parseInt(moment().format("YYYY")) as number, parseInt(moment().format("MM")) as number, parseInt(moment().format("DD")) as number);
  tod: NgbDate = new NgbDate(parseInt(moment().format("YYYY")) as number, parseInt(moment().format("MM")) as number, parseInt(moment().format("DD")) as number);

  ngOnInit() {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }
    var from_Date = localStorage.getItem("fromdates");
    console.log('from_Date',from_Date);

    var toDates = localStorage.getItem("toDates");
    console.log("toDates",toDates);


    // $('.clockpicker').clockpicker();
    // -------------------------------------------------
    this.thirtyMin_div()
    this.twentyfourHour()
    this.selectbtns()
    $(function () {
      $(".input-12-hour-icon-button").button({
        icons: {
          primary: ".ui-icon-clock"
        },
        text: false
      })
    });

    var input1 = $('#input-12-hour');
    input1.clockpicker({
      twelvehour: true,
      donetext: 'Done'
    });
    // -------------------------------
    $(function () {
      $(".input-12-hour_Two-icon-button").button({
        icons: {
          primary: ".ui-icon-clock"
        },
        text: false
      })
    });

    var input2 = $('#input-12-hour_Two');
    input2.clockpicker({
      twelvehour: true,
      donetext: 'Done'
    });
  }
  
  onDateSelection(date: NgbDate) {
    this.singleDate = date;
    console.log(date)
    if (this.selctsingle == true) {
      console.log(date)
      var todate = date.year + "-" + date.month + "-" + date.day;
      var singledate = moment(todate).format('YYYY-MM-DD');
      this.from_date = singledate,
        this.to_date = singledate
      console.log(this.from_date)
    }
    else {
      console.log(date.day)
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
        this.fromd = date;
        console.log(this.from_date, 'formateDate')

      } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
        this.tod = date;
        var todate = date.year + "-" + date.month + "-" + date.day;
        this.to_date = moment(todate).format('YYYY-MM-DD');
        console.log(this.to_date,'else if condition')
        // console.log(date, 'check')
      } else {
        this.fromd = date;
        this.tod = null;
        this.toDate = null;
        var fromdate = date.year + "-" + date.month + "-" + date.day;
        this.fromDate = date;

        this.from_date = moment(fromdate).format('YYYY-MM-DD');
        console.log(this.from_date);
        // var todate = date.year + "-" + date.month + "-" + date.day;
        this.to_date = moment(fromdate).format('YYYY-MM-DD');
        console.log(this.from_date);
      }
      var d = new Date(),
        month = '' + (d.getMonth()),
        day = '' + d.getDate(),
        year = d.getFullYear();

      let finalDate = year + "-" + month + "-" + day;
      console.log(this.to_date, 'To date,,,,,,,,,,,,');
      console.log(this.from_date, 'fromdate');


    }



    // ------------oldcodes-----------
    //   if(this.selctsingle==true){
    //     console.log(date)
    //     var todate = date.year + "-" + date.month + "-" + date.day;
    //   var singledate =moment(todate).format('YYYY-MM-DD');
    //     this.from_date=singledate,
    //     this.to_date=singledate
    //     console.log(this.from_date,'fromdate')
    //   }


    //  else if(this.multiplesSelect == "1"){

    //     if (!this.fromDate && !this.toDate) {
    //       this.fromDate = date;

    //        var singledate =moment( this.fromDate).format('YYYY-MM-DD');
    //             this.from_date=singledate;
    //             console.log(this.from_date,'fromdate')

    //     console.log(this.fromDate,'startDate');



    //     } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
    //       this.toDate = date;
    //       var multipledate =moment( this.toDate).format('YYYY-MM-DD');
    //       this.to_date=multipledate;
    //       console.log(this.to_date,'multiple')

    //       console.log(this.toDate,'tolastDate')
    //     } else {
    //       this.toDate = null;
    //       this.fromDate = date;
    //       console.log(this.fromDate,'startDate1');
    //       var fromDate = date.year + "-" + date.month + "-" + date.day;
    //           var singledate =moment( this.fromDate).format('YYYY-MM-DD');
    //             this.from_date=singledate;
    //             console.log(this.from_date,'fromdate');
    //     }

    //   }
    // --------------old code-----------------------------------




    // --------------------------------------------
    // console.log(date)
    //     if(this.selctsingle==true){
    //       console.log(date)
    //       var todate = date.year + "-" + date.month + "-" + date.day;
    //     var singledate =moment(todate).format('YYYY-MM-DD');
    //       this.from_date=singledate,
    //       this.to_date=singledate
    //       console.log(this.from_date)
    //     }
    //    else{
    //     console.log(date.month)
    //     if (!this.fromDate && !this.toDate) {
    //       this.fromDate = date;

    //       console.log(this.from_date, 'formateDate')

    //     } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {

    //      var todate = date.year + "-" + date.month + "-" + date.day;
    //      this.to_date =moment(todate).format('YYYY-MM-DD');
    //      console.log(this.to_date)

    //     } else {
    //       this.toDate = null;
    //         var fromdate = date.year + "-" + date.month + "-" + date.day;
    //       this.fromDate = date;

    //       this.from_date =moment(fromdate).format('YYYY-MM-DD');
    //       console.log(this.from_date)
    //     }
    //     var d = new Date(),
    //       month = '' + (d.getMonth() ),
    //       day = '' + d.getDate(),
    //       year = d.getFullYear();

    //     let finalDate = year + "-" + month + "-" + day;
    //     console.log(this.to_date, 'To date');
    //     console.log(this.from_date, 'fromdate');


    //   }

  }



  isHovered(date: NgbDate) {
    if (this.multiplesSelect == "1") {
      // console.log(this.hoveredDate,"this.hoveredDate");
      return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);



    }
  }

  isInside(date: NgbDate) {
    if (this.multiplesSelect == "1") {
      return this.tod && date.after(this.fromd) && date.before(this.tod);
    } else {
      return date.equals(this.singleDate);
    }
  }

  isRange(date: NgbDate) {
    if (this.multiplesSelect == "1") {
      return date.equals(this.fromd) || (this.tod && date.equals(this.tod)) || this.isInside(date) || this.isHovered(date);
    } else {
      return date.equals(this.singleDate);
    }
  }


  thirtyMin_div() {
    this.show_30min_div = true;
    this.show_60min_div = false;

    this.active_span_day = 'active_span';
    this.active_span_week = '';
    this.active_span_month = '';
    this.allMinute = $("#thirty").val();

    this.minutessetTime = "30";

  }
  sixtymin_div() {
    this.show_30min_div = false;
    this.show_60min_div = true;
    this.active_span_day = '';
    this.active_span_week = 'active_span';
    this.active_span_month = '';
    this.allMinute = $("#sixty").val();
    this.minutessetTime = "60";
  }



  selectbtns() {
    this.selctsingle = true;
    this.multiplesSelect = false;
    this.selectSingle = $("#Single").val();
    console.log(this.selectSingle, 'btnss');
    this.active_span_Select_single = 'active_span';
    this.active_span_Select_multiple = '';
  }

  multipleBtn() {
    this.multiplesSelect = "1";
    this.selctsingle = false;
    this.selectSingle = $("#Multiple").val();
    console.log(this.selectSingle, 'btnss');
    this.active_span_Select_single = '';
    this.active_span_Select_multiple = 'active_span';
  }

  twellHour() {
    this.hours = $("#twellHours").val();
    this.active_span_twentyFour_Hr = '';
    this.active_span_twell_Hr = 'active_span';
    this.active_span_no_Hr = '';
  }
  twentyfourHour() {
    this.hours = $("#twentyFourHours").val();
    this.active_span_twentyFour_Hr = 'active_span';
    this.active_span_twell_Hr = '';
    this.active_span_no_Hr = '';
  }
  noHours() {
    this.hours = $("#nohour").val();
    this.active_span_twentyFour_Hr = '';
    this.active_span_twell_Hr = '';
    this.active_span_no_Hr = 'active_span';
  }

  time3clk_30min() {
    this.clkTime = $("#clk_3_30min").val();
  }
  time3_30clk_30min() {
    this.clkTime = $("#clk3_30_30min").val();
  }

  time4clk_30min() {
    this.clkTime = $("#clk_4_30min").val();
  }
  time4_30clk_30min() {
    this.clkTime = $("#clk4_30_30min").val();
  }
  time3clk_60min() {
    this.clkTime = $("#clk_3_60min").val();
  }

  time4clk_60min() {
    this.clkTime = $("#clk_4_60min").val();

  }
  time5clk_60min() {
    this.clkTime = $("#clk_5_60min").val();

  }
  time6clk_60min() {
    this.clkTime = $("#clk_6_60min").val();

  }

  async confirm_btn() {
    var today_date = new Date();
    var sessionToday_Date = moment(today_date).format('YYYY-MM-DD');

    if (this.from_date == undefined) {
      this.from_date = sessionToday_Date;
    }

    var ends_Date = new Date();
    var sessionEnd_Date = moment(ends_Date).format('YYYY-MM-DD');

    if (this.to_date == undefined) {
      this.to_date = sessionEnd_Date;

      console.log(this.to_date,'this.to_date')
    }




    var today1 = new Date();

    var today_Dates = moment(today1).format('YYYY-MM-DD');
    var startTime = $("#input-12-hour").val();
    var starttime1 = moment(startTime, 'hh:mm A').format('HH:mm:ss');
    console.log(starttime1, 'start Time');
    var convertedStartTime = moment(startTime, 'hh:mm A').format('HH:mm:ss')
    var slectEndTime = $("#input-12-hour_Two").val();
    var convertedEndTime = moment(slectEndTime, 'hh:mm A').format('HH:mm:ss')
    console.log(convertedEndTime, 'raja2');
    // this.thirtyMinutesDifferent = moment.utc(moment(convertedEndTime).diff(moment(convertedStartTime)));

    // console.log(mins , 'minnn')

    var numberstart = moment(convertedStartTime, ["HH.mm"]).format("hh:mm a");
    // console.log(numberstart,'nummmberrrstartrr')
    var numberend = moment(convertedEndTime, ["HH.mm"]).format("hh:mm a");


    this.thirtyMinutesDifferent = moment.utc(moment(numberend, "HH:mm:ss").diff(moment(numberstart, "HH:mm:ss"))).format("mm");
    console.log(this.thirtyMinutesDifferent, 'minnndiff');

    // start time and end time

    let x1 = {
      slotInterval: 30,
      openTime: convertedStartTime,
      closeTime: convertedEndTime
    };

    //Format the time
    let startTimeone = moment(x1.openTime, "HH:mm");

    //Format the end time and the next day to it
    let endTime = moment(x1.closeTime, "HH:mm");

    //Times
    let allTimes = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTimeone < endTime) {
      //Push times
      allTimes.push(startTimeone.format("HH:mm"));
      //Add interval of 30 minutes
      startTimeone.add(x1.slotInterval, 'minutes');
    }

    console.log(allTimes, 'all30');

    // ---------------------------------------------------------
    // this.router.navigate([this.returnUrl]);

    let x = this.clkTime + this.allMinute;
    console.log(x);

    let first: number = this.clkTime;

    let second: number = this.allMinute;

    console.log('Addition:', first + second);

    var currentHours = new Date(new Date());
    var currentTimes = moment(currentHours, 'hh:mm A').format('HH:mm:ss');
    console.log(currentTimes, 'currreestarttimrnew');


    console.log(starttime1, 'starttimr');
    // if(currentTimes < starttime1 && today_Dates == this.from_date ){
    //   console.log('currentTimes');
    // }

    if (currentTimes > starttime1 && today_Dates === this.from_date) {
      swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'The given time range had slots in past time. The slots are not created for past time.!',
      })
    }
    else {

      if (resultStartTime >= numberstart) {
        console.log(numberstart, "correctionTime startttttt");
        console.log(resultStartTime, "correctionTime reeeesuuuulllt");

      }
      else if (resultStartTime < numberstart) {

        console.log("If a user selects a duration like 30 mins or 60 mins and gives a time value below 30 or 60 mins it not  accepted the slot  value ")

      }
      //  ---------------------------------------------------





      var timeto = $('#input-12-hour').val();
      // console.log(timeto,'timeeto')
      var timefrom = $('#input-12-hour_Two').val();
      // console.log(timefrom,'timeefrom1')
      console.log(convertedStartTime, 'convertedStartTime')
      var resultStartTime = moment(convertedEndTime, 'hh:mm A').subtract(this.minutessetTime, 'minute').format('HH:mm:ss');
      console.log(resultStartTime, 'resulttime')
      if (resultStartTime >= convertedStartTime) {

        var numberStartTime = moment(numberstart, 'HH:mm:ss a');

        var endTime3 = moment(numberend, 'HH:mm:ss a');


        // calculate total duration
        var numberstart = moment(convertedStartTime, ["HH.mm"]).format("hh:mm a");
        var resultStartTime = moment(numberStartTime, 'HHmm').add(this.minutessetTime, 'minute').format('hh:mm a');
        console.log(resultStartTime, '1234456677');

        // -----------------Api Service-----------------------------------------


        console.log('slot  booked');
        var postapi = "/update_availability";
        var user_Id = localStorage.getItem('userid');

        var availabilitydata = {
          "date_type": this.selectSingle,
          "start_date": this.from_date,
          "end_date": this.to_date,
          "start_time": convertedStartTime,
          "specific_time": this.from_date,
          "end_time": convertedEndTime,
          "duration": this.allMinute,
          "cutoff_time": this.hours,
          "coach_id": user_Id,
        };

        console.log(availabilitydata, 'availadata');




        if (convertedStartTime == "Invalid date" || convertedEndTime == "Invalid date") {

          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'please Fill The Details !',
          })

        }
        else {

          (await this.ApiService.postApi(postapi, availabilitydata)).subscribe(
            res => {
              if (res) {
                if (res.status === "success") {

                  swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Slot Updated Successfully',
                  })
                  this.router.navigate([this.returnUrl]);
                }
                else if (res.status === 404) {

                  swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Slot already exit!',
                  })
                }
              }
            });

        }

      }
      else if (resultStartTime < convertedStartTime) {

        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'If a user selects a duration like 30 mins or 60 mins and gives a time value below 30 or 60 mins it not  accepted the slot  value!',
        })
      }

    }
  }



}
