<div class="header pb-8  ">
  <div class="container-fluid">
    <div class="header-body">

      <div class="row">
        <div class=" mt-4">

          <ol class="breadCrumb_flex fsize24 marg-b-0">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                [routerLink]="['/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Trainee Add New Tips</li>
          </ol>

        </div>
        <!-- <div class="col-9 mt-4">
            <p class="text_div">Happiness Trainee: </p>
            <p class="text_div_align">Age: </p>
            <p class="text_div_align">Session Number: </p>
  
          </div> -->
        <!-- <div class="col-3 mt-4">
          <div class="image_box"><img class="img-fluid header_image_check" src="assets/img/Characters/happy.png"></div>
        </div> -->
      </div>
    </div>
    <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="height: 200px !important;">
          <div class="modal-body">
            <span style="font-size:30px;color:red;float:right" data-dismiss="modal" >x</span>
            <div class="fsize18 font_bold_gilroy text-center" style="margin-top:40px;"> Add Voice Note</div>
            <div class="fsize16" style="justify-content: center;display: flex;align-items: center; margin-top: 30px;">
              <span> <button (click)="startPlay()" *ngIf="showhidediv"
                  style="background-color: #fff;outline: none;border: none;" class=""><img class="" height="30px"
                    width="30px" src="assets/img/Icon/MicrosoftTeams-image (3).png" alt=""></button></span>
              <span> <button (click)="stopPlay()" *ngIf="showhide"
                  style="background-color: #fff;outline: none;border: none;" class=""><img class="" height="30px"
                    width="30px" src="assets/img/Icon/stop - pink.png" alt=""></button></span>
            </div>

            <div *ngIf="voiceText" class="text-center" style="margin-top: 20px;">

              Voice Recording .........
            </div>

          </div>
          <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">

          </div>
        </div>
      </div>
    </div> -->

    <!-- --------------------------New UI------------------------------------------------------------- -->

    <div class="row" style="margin-top:20px;">
      <div class="col-2">
        <div class="image_box" style="margin-left:30px;"><img class="img-fluid header_image_check"
            src="assets/img/Characters/happy.png"></div>
      </div>
      <div class="col-6">
        <div style="
          background: #FFFFFF;
      box-shadow: 0px 7px 30px rgb(19 18 49 / 8%);
      border-radius: 40px;
  
      ">
          <div style=" min-height: 310px;
      width: 500px;">

            <div class="row"
              style="padding:20px 30px 0px 30px;justify-content:center;color: #6CC3B8;font-family: Gilroy-Bold !important;font-size:20px ">

              <div>
                NewTip
              </div>


            </div>


            <div style="word-wrap: break-word; padding: 25px;">
              <textarea style="padding-left: 12px;
        height: 350px;
      width: 550px;
      border: 1px solid #fff;" class="box_detail_text" [(ngModel)]="addNewGoals">

    </textarea>
            </div>

          </div>

          <div style="padding:20px 30px 30px 30px; text-align: right;">
            <!-- 
                <button  style="color: #FFFFFF;
                background: #FD1C70;
                border-radius: 10px;
                border: 1px solid #FD1C70; height:40px;width:100px;margin-right:18px" >  Back  </button> -->

            <button (click)="saveAddgoalsApi()" style="background: #6CC3B8;border: 1px solid #6CC3B8;
        border-radius: 10px;color: #FFFFFF;height:40px;width:100px;">
              Save
            </button>
          </div>

        </div>

      </div>

      <div class="col-1"></div>


      <div class="col-3">
        <!-- <div>
  
  
            <button type="button" class="history_button" data-toggle="modal" data-target="#exampleModal">Add Voice
              Note</button>
       
            <div>
  
              <div class=" mt-3" style="width:370px;">
  
  
  
                <div class="row" style="width:225px;
            background: #fff; box-shadow: 0px 7px 30px rgb(19 18 49 / 8%);">
                  <div style="padding-left:30px;">
                    <img (click)="skip('back')" src="assets/img/Icon/fast-backward - gray.png" style="height:30px;">
                  </div>
                  <div style="padding-left:30px;">
  
  
                    <span> <button (click)="playpause()" style="background-color: #fff;outline: none;border: none;"
                        class=""><img class="" height="30px" width="30px" src="assets/img/Icon/play - blue.png"
                          alt=""></button></span>
                  </div>
  
                  <div style="padding-left:30px;">
                    <img (click)="skip('fwd')" src="assets/img/Icon/fast-forward - gray.png" style="height:30px;">
                  </div>
  
                </div>
  
  
              </div>
          
               <div>
   
              </div> 
     </div>

  
          </div> -->

      </div>

    </div>





    <!-- -----------------------------End -------------------------------------------------- -->
    <div class="row justify-content-center mt-5">
      <button type="button" class="done_btn" (click)="addNewTip()">Back</button>
    </div>
  </div>

</div>