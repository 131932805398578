
 <div class="padd-l-30 padd-t-20">
  <!-- <nav aria-label="breadcrumb"> -->
  <ol class="breadCrumb_flex fsize24">
    <li class="breadcrumb-item">
      <a [routerLinkActive]="['active']" (click)="isShown = false"
        [routerLink]="['/dashboard']">Home</a>
      
      </li>
    <li class="breadcrumb-item active" aria-current="page">Forgot Password</li>
  </ol>
  <!-- </nav> -->
</div>
 
 <div class="row">
   <div class="body_bar col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 circle-section">
     
     <div class="container pb-5 margin_t_60 ">
       
       <div class="row justify-content-center">
         
         <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">  
           <div class="card cardSize shadow border-0 ">
             <img class="img-fluid" src="assets/img/Characters/happy.png">
             <div class="card-body px-lg-5 ">
               <div class="text-center text-muted mb-3 mt-4">
                 <small class="login">Forgot Password</small>
               </div>
               <div class="text-center text-muted mb-4">
                 <small class="login_text">Happiness Coach Portal</small>
               </div>
               <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" >
                 <div class="form-group mb-3">
                   <div class=" marg-l-15">
                     <input class="form-control emailInput" formControlName="email"  placeholder="Please Enter The Email" id="email" type="email">
                   </div>
                   <div *ngIf="submitted && registerForm.get('email').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('email').errors.required">Email is required</div>
                  </div>

                 </div>
         
                 
                 <div class=" marg-t-18 marg-l-15">
                   <button type="submit"    class="btn submit_button">Submit</button>
                 </div>
   
                 <div class="row mt-3">
                   <div class="col-6">
                     <a  [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/login']"  href="javascript:void(0)" class="blue">
                        Back to
                        
                        <a href="" class=" blue" > Signin</a>
                        
                     
                        <!-- <small></small> -->
                    
                    </a>
                   </div>
                   <div class="col-6 text-right">
                     <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/login']" class=" blue">
                        Signup
                         <!-- <small></small> -->
                        </a>
                   </div>
                 </div>
                 
               </form>
             </div>
           </div>
          
         </div>
      
        
       </div>
     
     
     </div>
     <div class="circle-img left-side"> 
       <img class="img-fluid" src="assets/img/Icon/Bg_ptn_left.png">
     </div>
     <div class="circle-img right-side"> 
       <img class="img-fluid" src="assets/img/Icon/Bg_ptn_right.png">
     </div>
     
     
   </div>
 </div>
 

 