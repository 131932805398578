import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { DatePipe } from '@angular/common';
import { faMicrophoneAlt, faMicrophoneAltSlash, faVideo, faVideoSlash, faCog } from '@fortawesome/free-solid-svg-icons'
import { Subscription } from 'rxjs';
// import { MediaService, MediaStreamType } from '../../services/media.service';
import { MediaService, MediaStreamType } from '../services/media.service';
// ------------------------meeting-preview---------
import { FormBuilder, FormGroup } from '@angular/forms';
import { TokenService } from '../services/token.service';
// -----------------
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import AgoraRTC from "./dynamic";
import RTCType from './dynamic-types';
const AgoraRTCType = new RTCType();
import { ILocalTrack } from 'agora-rtc-sdk-ng';
const { IAgoraRTCClient, IAgoraRTCRemoteUser, IRemoteTrack } = AgoraRTCType.agoraRTC;
// ------------------Agora Chat--------------------------------
import Chart from 'chart.js';
// import AgoraRTM from 'agora-rtm-sdk';
// import RTMClient from 'agora-rtm-sdk'
import * as moment from 'moment';
import * as AgoraRTM from 'agora-rtm-sdk';
// --------------------------------------------
declare var swal: any;
declare var $: any;
// ----

var localTracks = {
  videoTrack: null,
  audioTrack: null
};
@Component({
  selector: 'app-happiness-training-session',
  templateUrl: './happiness-training-session.component.html',
  styleUrls: ['./happiness-training-session.component.css']
})
export class HappinessTrainingSessionComponent implements OnInit {
  // -----meting-preview Button------
  @Input()
  channelid: any;
  joinLoading = false;
  today: any;
  newLoading = false;
  connectionInfoForm?: FormGroup;
  isShown: boolean = false;
  channelname: any;
  count: any = 0;
  countmic: any = 0;
  meetingparticipent: any = false;
  divshow: any = false;
  username: any;
  divshowfirst: any = false;
  defaultsDiv = false;
  // ---------End-----------------
  @ViewChild('camera') cameraElementRef!: ElementRef;
  // private subscriptions: Subscription[] = [];
  private stream?: MediaStream;
  public microphoneIcon = faMicrophoneAlt;
  public microphoneMutedIcon = faMicrophoneAltSlash;
  public cameraIcon = faVideo;
  public cameraOffIcon = faVideoSlash;
  public settingIcon = faCog;
  public isCameraOff = true;
  public isMicrophoneMute = true;
  public selectedVideoInId?: string;
  showSettings = false;
  session_Id: any
  returnUrl = "/happiness_trainee";
  returnStartUrl = "/meetings";
  user_Name: any;
  // ---------------------------------

  @ViewChild('localVideo', { static: true }) localVideo?: ElementRef;
  channel = '';
  link = '';
  subscriptions: Subscription[] = [];
  userList: [] = [];
  audioInId = '';
  videoInId = '';
  audioOutId = '';
  token = '';
  mediaTrack?: any;
  pinnedUser?: any;
  sessionUserid: any;
  sessions_id: any;
  sessionDate: any;
  app_numbers: any;
  video_Token: any
  chaneel_Name: any;
  session_Userid: any;
  appnumber1: any;
  session_coachId: any;
  appntCoachId: any;
  optionUid: any;
  endcallstatus: any;

  // -----------------chat----------------------------------
  // returnUrl = "/trainee-notes";
  // isShown: boolean = false;
  messageArray: Array<{ peerId: String, type: any, text: any }> = [];
  messageText = "";
  AppId;
  text: string;
  user: string;
  rtmtoken: any;
  userid: any;
  getpeerid: any;
  peerId: any;
  // token: string;
  file_name: any;
  fileBlob: any;
  msg: any;
  error: any;
  appoin_sesionid: any;
  senderMsg: any;
  receiverMsg: any;
  client = AgoraRTM.createInstance('4288652efe744f3ebda5f5cf5a2cf4af');
  session_id: any;
  usernames: any;
  clientUsrName: any;
  getListData = [];
  // user_Name:any;
  isMouseDown: any;
  initX: any;
  initY: any;
  height: any;
  width: any;
  msgNotification = false;
  coach: 'Coach';
  appointment_id: any;
  constructor(private router: Router, private ApiService: ApiService) { }

  async ngOnInit() {
    if (!localStorage.getItem('authtoken') && window.location.hash != '#/login') {
      window.location.href = '#/'
    }
    // --------------------------------------------------
    this.username = localStorage.getItem('userName');
    this.appointment_id = localStorage.getItem('session_id');
    console.log('this.appointment_idffff==>', this.appointment_id);

    this.appntCoachId = this.ApiService.appointmentCoachId
    this.user_Name = localStorage.getItem('userListName');
    this.sessionUserid = localStorage.getItem('sessionUsrId');
    this.sessions_id = localStorage.getItem('session_id');
    this.sessionDate = localStorage.getItem('session_Date');
    this.clientUsrName = localStorage.getItem('coachUserName');
    this.divshow = true;
    this.defaultsDiv = true;
    this.today = new Date();
    this.appnumber1 = localStorage.getItem('appnumber');
    console.log(this.appnumber1, 'app_Number');

    console.log(this.channelid + "hello")
    this.previewVideo();
    this.appointmentagora();

    // ---------------------chat------------------------------
    this.user_Name = localStorage.getItem('userListName');
    this.appoin_sesionid = this.ApiService.session_Ids;
    this.usernames = localStorage.getItem('userName');
    this.clientUsrName = localStorage.getItem('coachUserName');
    this.userid = localStorage.getItem('userid');
    console.log(this.userid, 'useridd123');
    this.getpeerid = localStorage.getItem('peerid');
    console.log(this.getpeerid, 'peerid');
    this.session_id = localStorage.getItem('session_id');
    this.peerId = this.getpeerid
    var appnumber = localStorage.getItem('appnumber');
    var postApi = "/live_chat";
    var getlivechatdata = {
      "app_number": appnumber
    };
    (await this.ApiService.postApi(postApi, getlivechatdata)).subscribe(
      res => {
        this.rtmtoken = res.token
        this.login()
      })

    this.subscribeEvents();
    // this.getListMsgApi();
    this.getListCommenMsgApi();

    let scroll_to_bottom = document.getElementById('scroll-to-bottom');
    function scrollBottom(element) {
      element.scroll({ top: element.scrollHeight, behavior: "smooth" })
    }

    scrollBottom(scroll_to_bottom);

  }


  async appointmentagora() {
    var postapi = "/video_Call";
    var useridData = {
      "app_number": this.appnumber1
    };

    (await this.ApiService.postApi(postapi, useridData)).subscribe(
      res => {
        this.channelname = res.channelName;
        console.log(this.channelname, 'ddddd');
        this.ApiService.sessionNames = res.channelName;
        console.log(this.ApiService.sessionNames, 'this.ApiService.sessionNames............................');
        localStorage.setItem('videotoken', res.token);
        localStorage.setItem('channelname', res.channelName);
        localStorage.setItem('app_Numbers', res.appID);

        this.raja();
      })

  }

  previewVideo() {
    AgoraRTC.createCameraVideoTrack().then((track) => {
      localTracks.videoTrack = track;
      localTracks.videoTrack.play("local-player");
    })
  }

  stopPreview() {
    localTracks.videoTrack.stop();
    localTracks.videoTrack.close();
  }
  //
  // var rajal = this.channelname ;

  async raja() {

    var client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })

    /*
     * Clear the video and audio tracks used by `client` on initiation.
     */

    /*
     * On initiation no users are connected.
     */
    var remoteUsers = {};
    /*
       * On initiation. `client` is not attached to any project or channel for any specific user.
       */
    var options = {
      appid: null,
      channel: null,
      uid: null,
      token: null
    };

    AgoraRTC.onAutoplayFailed = () => {
      alert("click to start autoplay!")
    }

    AgoraRTC.enableLogUpload();
    AgoraRTC.setLogLevel(1);

    AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
      // When plugging in a device, switch to a device that is newly plugged in.
      if (changedDevice.state === "ACTIVE") {
        localTracks.audioTrack.setDevice(changedDevice.device.deviceId);
        // Switch to an existing device when the current device is unplugged.
      } else if (changedDevice.device.label === localTracks.audioTrack.getTrackLabel()) {
        const oldMicrophones = await AgoraRTC.getMicrophones();
        oldMicrophones[0] && localTracks.audioTrack.setDevice(oldMicrophones[0].deviceId);
      }
    }

    AgoraRTC.onCameraChanged = async (changedDevice) => {
      // When plugging in a device, switch to a device that is newly plugged in.
      if (changedDevice.state === "ACTIVE") {
        localTracks.videoTrack.setDevice(changedDevice.device.deviceId);
        // Switch to an existing device when the current device is unplugged.
      } else if (changedDevice.device.label === localTracks.videoTrack.getTrackLabel()) {
        const oldCameras = await AgoraRTC.getCameras();
        oldCameras[0] && localTracks.videoTrack.setDevice(oldCameras[0].deviceId);
      }
    }
    /*
        * When this page is called with parameters in the URL, this procedure
        * attempts to join a Video Call channel using those parameters.
        */
    $(() => {
      var urlParams = new URL(location.href).searchParams;
      console.log('urlParams', urlParams.get("token"))
      options.appid = urlParams.get("appid");
      options.channel = urlParams.get("channel");
      options.token = urlParams.get("token");
      options.uid = this.appntCoachId;
      this.optionUid = options.uid
      if (options.appid && options.channel) {

        $("#join-form").submit();
      }
    })
    const that = this;
    /*
        * When a user clicks Join or Leave in the HTML form, this procedure gathers the information
        * entered in the form and calls join asynchronously. The UI is updated to match the options entered
        * by the user.
        */
    that.app_numbers = localStorage.getItem('app_Numbers');
    that.video_Token = localStorage.getItem('videotoken');
    that.chaneel_Name = localStorage.getItem('channelname');
    that.session_Userid = localStorage.getItem('sessionUsrId');
    that.session_coachId = localStorage.getItem('session_CoachId');
    // console.log(that.app_numbers,'that.app_numbers');
    // console.log(that.video_Token,'that.video_Token');
    // console.log(that.chaneel_Name,'that.chaneel_Name');
    // console.log(that.session_Userid,'that.session_Userid');
    // console.log(that.session_coachId,'that.session_coachId');
    // console.log( that.ApiService.sessionNames,'this.ApiService.sessionNames');
    // this.channelname
    // session_CoachId

    $("#join").click(() => {
      this.stopPreview();

      $("#join").attr("disabled", true);

      // that.appointmentagora();

      try {


        options.appid = that.app_numbers;
        options.token = that.video_Token;
        options.channel = that.chaneel_Name;
        that.optionUid = (Number(that.appntCoachId));


        console.log(options, '_new-------------------------->');


        // console.log(appnumber,'appnumber',video_Token,"video",chaneel_Name,'chan',typeof(session_Userid),'usrid'),
        join().then(() => {
          if (options.token) {
            $("#success-alert-with-token").css("display", "block");
          } else {
            $("#success-alert a").attr("href", `index.html?appid=${options.appid}&channel=${options.channel}&token=${options.token}`);
            $("#success-alert").css("display", "block");
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        $("#leave").attr("disabled", false);
      }
      // that.defaultsDiv = false;

      // -----------------------------------------------------
      var time_Duration = localStorage.getItem('timeDuration');
      var thirtyMinuteDurations = "00:30:00";
      var sixtyMinuteDurattions = "01:00:00"
      // if(raja == "")
      // timeDuration

      if (thirtyMinuteDurations == time_Duration) {

        setTimeout(() => {
          this.msgNotification = true;
        }, 1.2e+6)
        // setTimeout(() => {
        //   this.msgNotification = true;
        // }, 10000)
      }
      if (sixtyMinuteDurattions == time_Duration) {
        setTimeout(() => {
          this.msgNotification = true;
        }, 3e+6)
      }



    })

    //  ---------------------------------------------------------------





    //  ---------------------------------------------------------------------------
    /*
     * Called when a user clicks Leave in order to exit a channel.
     */
    $("#leave").click(function (e) {
      leave();
      this.stopPreview();
    })

    /*
     * Join a channel, then create local video and audio tracks and publish them to the channel.
     */
    async function join() {
      console.log(remoteUsers, "ids of remote user");
      // Add an event listener to play remote tracks when remote user publishes.
      client.on("user-published", handleUserPublished);
      client.on("user-unpublished", handleUserUnpublished);

      // Join a channel and create local tracks. Best practice is to use Promise.all and run them concurrently.
      [that.optionUid,
      localTracks.audioTrack,
      localTracks.videoTrack
      ] = await Promise.all([
        // Join the channel.
        client.join(options.appid, options.channel, options.token || null, that.optionUid || null),
        // Create tracks to the local microphone and camera.
        AgoraRTC.createMicrophoneAudioTrack(),
        AgoraRTC.createCameraVideoTrack()
      ]);

      // Play the local video track to the local browser and update the UI with the user ID.
      localTracks.videoTrack.play("local-player");
      $("#local-player-name").text(`localVideo(${that.optionUid})`);

      // Publish the local video and audio tracks to the channel.
      await client.publish(Object.values(localTracks));
      console.log("publish success");
    }

    /*
     * Stop all local and remote tracks then leave the channel.
     */

    async function leave() {

      for (var trackName in localTracks) {
        var track = localTracks[trackName];
        if (track) {
          track.stop();
          track.close();
          localTracks[trackName] = undefined;
        }
      }

      // Remove remote users and player views.
      remoteUsers = {};
      $("#remote-playerlist").html("");

      // leave the channel
      await client.leave();

      $("#local-player-name").text("");
      $("#join").attr("disabled", false);
      // $("#leave").attr("disabled", true);
      console.log("client leaves success");

    }




    $('#videoMute').click(() => {

      console.log('videoOn');
      localTracks.videoTrack.setMuted(!localTracks.videoTrack.muted);

      var x = document.getElementById("myDIV");
      var y = document.getElementById("myDIV1");
      if (x.style.display === "none") {
        x.style.display = "block";
        y.style.display = "none";

      } else {
        x.style.display = "none";
        y.style.display = "block";
      }

    })

    $('#audioMute').click(() => {
      // console.log('raja');

      var mics1 = document.getElementById("mic1");
      var mics2 = document.getElementById("mic2");
      if (mics1.style.display === "none") {
        mics1.style.display = "block";
        mics2.style.display = "none";

      } else {
        mics1.style.display = "none";
        mics2.style.display = "block";
      }

      localTracks.audioTrack.setMuted(!localTracks.audioTrack.muted);

    })

    /*
     * Add the local use to a remote channel.
     *
     * @param  {IAgoraRTCRemoteUser} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iAgoraRTCremoteuser.html| remote user} to add.
     * @param {trackMediaType - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/itrack.html#trackmediatype | media type} to add.
     */
    async function subscribe(user, mediaType) {
      const uid = user.uid;

      console.log(uid, 'uid remote')

      // subscribe to a remote user
      await client.subscribe(user, mediaType);
      console.log("subscribe success");
      if (mediaType === 'video') {
        const player = $(`
         <div id="player-wrapper-${uid}">
           <p class="player-name">remoteUser(${uid})</p>
           <div  id="player-${uid}" class="player"></div>
         </div>
       `);
        $("#remote-playerlist").append(player);
        user.videoTrack.play(`player-${uid}`);
      }
      if (mediaType === 'audio') {
        user.audioTrack.play();
        // user.audioTrack.stop();
      }
    }

    /*
     * Add a user who has subscribed to the live channel to the local interface.
     *
     * @param  {IAgoraRTCRemoteUser} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iAgoraRTCremoteuser.html| remote user} to add.
     * @param {trackMediaType - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/itrack.html#trackmediatype | media type} to add.
     */
    function handleUserPublished(user, mediaType) {
      const id = user.uid;
      console.log(id, 'handleUid')
      remoteUsers[id] = user;
      subscribe(user, mediaType);
    }

    /*
     * Remove the user specified from the channel in the local interface.
     *
     * @param  {string} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iAgoraRTCremoteuser.html| remote user} to remove.
     */
    function handleUserUnpublished(user, mediaType) {
      if (mediaType === 'video') {
        const id = user.uid;
        console.log(id, 'iduserRemote2')
        delete remoteUsers[id];
        $(`#player-wrapper-${id}`).remove();

      }
    }
  }




  // ----------------------------------------------------------------------------------------




  async saveNotice() {

    var textValue = $("#textAreaId").val();

    if (textValue != "") {

      var todayDate = new Date().toISOString().slice(0, 10);
      console.log(todayDate);
      var postapi = "/create_session_history";
      var coach_Id = localStorage.getItem('coachId');
      var textValue = $("#textAreaId").val();
      var userids = JSON.parse(localStorage.getItem('userid'));
      console.log(userids)
      var signupdata =
      {
        "date": todayDate,
        "session_id": this.sessions_id,
        "user_id": this.sessionUserid,
        "value": textValue,
        "type": "note"
      };
      (await this.ApiService.postApi(postapi, signupdata)).subscribe(
        res => {
          if (res) {
            if (res.status === 200) {

              swal.fire({
                icon: 'success',
                title: 'Success',
                text: ' Add Note Successfully',
              })
            }

          }
        });

    }

    else if (textValue == "") {
      swal.fire({
        icon: 'error',
        title: 'error',
        text: 'Please Fill The Details ',
      })
    }
  }

  // -----------

  async saveGoals() {

    var textPracticeValue = $("#textAreaPractice").val();
    console.log(textPracticeValue, '......textPracticeValue')
    if (textPracticeValue != "") {

      var todayDate = new Date().toISOString().slice(0, 10);
      console.log(todayDate);
      var postapi = "/create_session_history";
      var coach_Id = localStorage.getItem('coachId');
      var textPracticeValue = $("#textAreaPractice").val();
      var userids = JSON.parse(localStorage.getItem('userid'));
      console.log(userids)
      var signupdata =
      {
        "date": todayDate,
        "session_id": this.sessions_id,
        "user_id": this.sessionUserid,
        "value": textPracticeValue,
        "type": "goal"
      };
      (await this.ApiService.postApi(postapi, signupdata)).subscribe(
        res => {

          if (res) {
            if (res.status === 200) {

              swal.fire({
                icon: 'success',
                title: 'Success',
                text: ' Add Goal Successfully',
              })
            }

          }
        });
    }
    else if (textPracticeValue == "") {
      swal.fire({
        icon: 'error',
        title: 'error',
        text: 'Please Fill The Details ',
      })
    }



  }

  onLocalLeave1() {
    this.endCallSession();
    this.router.navigate(['/appointment_list']);
  }


  async endCallSession() {
    var postapi = "/end_appointment";
    var sesiionId =
    {
      "id": this.sessions_id,
      "status": this.endcallstatus
    };

    (await this.ApiService.postApi(postapi, sesiionId)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'End Appointment successfully',
            })
            this.router.navigate(['/appointment_list']);
          }
        }
      });
  }


  done_Btn() {
    this.router.navigate([this.returnUrl]);
  }



  ngOnDestroy() {
    const that = this;
    that.raja();
    // const that = this;
    var client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    async function leave() {
      console.log('raaaaaj')
      for (var trackName in localTracks) {
        var track = localTracks[trackName];
        if (track) {
          track.stop();
          track.close();
          localTracks[trackName] = undefined;
        }
      }
      var remoteUsers;
      // Remove remote users and player views.
      remoteUsers = {};
      $("#remote-playerlist").html("");

      // leave the channel
      await client.leave();

      $("#local-player-name").text("");
      $("#join").attr("disabled", false);
      // $("#leave").attr("disabled", true);
      console.log("client leaves channel success");
    }

    leave();

    that.session_coachId = null || "" || undefined;
    // var options = {
    //   appid: null,
    //   channel: null,
    //   uid: null,
    //   token: null
    // };

    that.optionUid = undefined || null || "";

    window.location.reload();

  }

  // --------------chat-----------------------------------------
  /// Agora Rtm messaging service
  getimg: any = '';

  finalimg: any;
  subscribeEvents() {
    console.log("message2")
    this.client.on('MessageFromPeer', async (message, peerId) => {
      console.log(message)
      this.messageArray.push({ peerId: 'client', type: 'text', text: message })
    })
  }

  login() {
    this.client.login({ uid: this.userid, token: this.rtmtoken }).then(() => {
      this.msg = "Login Success"
      console.log('AgoraRTM client login success');
    }).catch(err => {
      this.error = "Login Failed"
      console.log('AgoraRTM client login failure');
    });
  }

  logout() {
    this.client.logout();
    this.msg = "Logged Out"
  }

  sendMessage(text, peerId) {
    this.messageArray.push({ peerId: 'user', type: 'text', text: { text } })
    this.saveListMsgApi();

    this.client.sendMessageToPeer({ text }, peerId)
      .then(sendResult => {
        this.messageText = "";
        if (sendResult.hasPeerReceived) {
          this.messageText = "";
          //this.messageArray.push({peerId:peerId,text:text})

        }
        else {
          console.log("false");
        }
      })

  }



  /// Agora Rtm messaging service end
  back() {
    this.router.navigate([this.returnUrl]);
  }

  async getListMsgApi() {
    var postApi = "/list_message";
    var getlistMsgdata = {
      "session_id": this.session_id
    };
    (await this.ApiService.postApi(postApi, getlistMsgdata)).subscribe(
      res => {
        var sender_id = this.getpeerid
        this.getListData = res.data.reverse();
        console.log(this.getListData, 'xyz');
        this.senderMsg = this.getListData.filter(function (itm) {
          return sender_id.indexOf(itm.sender_id) > -1;

        });

        var receiver_id = this.userid
        //  this.getListData = res.data.reverse();
        console.log(this.getListData, 'xyz');
        this.receiverMsg = this.getListData.filter(function (itm) {
          return sender_id.indexOf(itm.receiver_id) > -1;

        });

        // receiverMsg
        // ------------------------------------------------------
        var data = { records: [{ "empid": 1, "fname": "X", "lname": "Y" }, { "empid": 2, "fname": "A", "lname": "Y" }, { "empid": 3, "fname": "B", "lname": "Y" }, { "empid": 4, "fname": "C", "lname": "Y" }, { "empid": 5, "fname": "C", "lname": "Y" }] }
        var empIds = [1, 4, 5]
        // var filteredArray = data.records.filter(function(itm){
        //   return empIds.indexOf(itm.empid) > -1;

        // });
        console.log(this.senderMsg, 'yyyy');
        // for(let item of filteredArray ){
        //   var pandd = item.message;
        //   console.log(pandd,'uu');
        // }

        // filteredArray = { records : filteredArray };
      })
  }


  async saveListMsgApi() {
    // alert();
    this.sessions_id = localStorage.getItem('session_id');
    var postApi = "/save_message";
    var getlistMsgdata = {
      "receiver_id": this.getpeerid,
      "sender_id": this.userid,
      "session_id": this.sessions_id,
      "message": this.messageText,
      "device_type": "coach",
      "appointment_key": this.appointment_id
    };
    (await this.ApiService.postApi(postApi, getlistMsgdata)).subscribe(
      res => {
        var saveAlldata = res.data;
        console.log('saveAlldata', saveAlldata);
        // for (let item of saveAlldata){
        //   var raja = item.message;
        //   console.log(raja,'rajaa')
        // }
      })
  }

  async getListCommenMsgApi() {
    var postApi = "/common_list_message";
    var getlistMsgdata = {
      "user_id": this.getpeerid,
      "coach_id": this.userid
    };
    (await this.ApiService.postApi(postApi, getlistMsgdata)).subscribe(
      res => {
        var sender_id = this.getpeerid;
        var getCommonAllData = res.data.reverse();
        // console.log( getCommonAllData,'ra');

        for (let item of getCommonAllData) {
          var sivu = moment(new Date(moment.utc(item.date + " " + item.time + " Z", "DD-MM-YYYY hh:mm:ss A Z").toLocaleString())).format("hh:mm A DD MMM[,] YYYY")
          console.log(sivu, 'timings')
          this.getListData.push({ ...item, time: sivu });
        }

        // this.getListData = res.data;
        console.log(this.getListData, 'xyz');
        this.senderMsg = this.getListData.filter(function (itm) {
          return sender_id.indexOf(itm.sender_id) > -1;

        });

        var receiver_id = this.userid
        //  this.getListData = res.data.reverse();
        console.log(this.getListData, 'xyz');
        this.receiverMsg = this.getListData.filter(function (itm) {
          return sender_id.indexOf(itm.receiver_id) > -1;

        });

        // receiverMsg

        // ------------------------------------------------------

        var data = { records: [{ "empid": 1, "fname": "X", "lname": "Y" }, { "empid": 2, "fname": "A", "lname": "Y" }, { "empid": 3, "fname": "B", "lname": "Y" }, { "empid": 4, "fname": "C", "lname": "Y" }, { "empid": 5, "fname": "C", "lname": "Y" }] }
        var empIds = [1, 4, 5]
        // var filteredArray = data.records.filter(function(itm){
        //   return empIds.indexOf(itm.empid) > -1;

        // });



        console.log(this.senderMsg, 'yyyy');
        // for(let item of filteredArray ){
        //   var pandd = item.message;
        //   console.log(pandd,'uu');
        // }

        // filteredArray = { records : filteredArray };


      });
  }


  openForm() {
    document.getElementById("myForm").style.display = "block";
  }

  closeForm() {
    document.getElementById("myForm").style.display = "none";
  }

  notifyClose() {

    this.msgNotification = false;
  }


}
