<div class="row header_bar">

  <div class="col-2 project_name align_center ">
    <img alt="Image placeholder" class="img-fluid log_img" src="assets/img/Icon/Web_logo.png">
  </div>
  <div class="col-7 text-right header_heading">
    <p class="header_heading">An adventure to wholesome life awaits..</p>
  </div>
  <div calss="col-3 pt_5">
    <ul class="navbar-nav align-items-center d-none d-md-flex" style="margin-left: 48px;">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center bg_style">
            <span class="avatar avatar-sm rounded-circle">
              <!-- <img alt="Image placeholder" src="assets/img/theme/team-4-800x800.jpg"> -->
              <img class="img-fluid header_image imgFitSize" style="border-radius: 50px; height: 36px; width: 36px;"
                [src]="loginProfileImageUrl">
            </span>
            <div class="media-body ml-2 d-none d-lg-block ">
              <span class="mb-0 text-sm  font-weight-bold white_clr">{{usernames}} &nbsp;<i class="fa fa-angle-down"
                  aria-hidden="true"></i>&nbsp;&nbsp;</span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
          </div>
          <a routerLinkActive="active" [routerLink]="['/uploadprofile']" class="dropdown-item">
            <span>My profile</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/notify-module']" class="dropdown-item">
            <span>Notification</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/dashboard']" class="dropdown-item">
            <span>My Account Settings</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/dashboard']" class="dropdown-item">
            <span>FAQs</span>
          </a>
          <div class="dropdown-divider"></div>
          <!-- [routerLink]="['/login']" -->
          <a routerLinkActive="active" (click)="logout()" class="dropdown-item">
            <span>Logout</span>
            <!-- <span (click)="logout_clear()" >Logout</span> -->
          </a>
        </div>
      </li>
    </ul>
  </div>
</div>