<div class="camera-container">
  <div class="top-controls-container">
    <a (click)="onShowSettings()">
      <fa-icon [icon]="settingIcon" class="text-white settings"></fa-icon>
    </a>
    <app-sound-meter *ngIf="!isMicrophoneMute" class="sound-meter"></app-sound-meter>
  </div>
  <h1 [ngClass]="{'text-white' : isCameraOff}" *ngIf="isCameraOff">Camera is off</h1>
  <video *ngIf="!isCameraOff" #camera id="camera"></video>
  <div class="webcam-overlay"></div>
  <div class="bottom-controls-container">
    <app-round-tranparent-icon-button class="m-3" [isActive]="isMicrophoneMute" type="toggle" [activeIcon]="microphoneMutedIcon"
      [inactiveIcon]="microphoneIcon" (onClick)="onMicrophoneClicked($event)">
    </app-round-tranparent-icon-button>
    <app-round-tranparent-icon-button class="m-3" [isActive]="isCameraOff" type="toggle" [activeIcon]="cameraOffIcon" [inactiveIcon]="cameraIcon"
      (onClick)="onCameraClicked($event)">
    </app-round-tranparent-icon-button>
  </div>
</div>
<app-input-output-settings (closed)="onCloseSettings()" *ngIf="showSettings"></app-input-output-settings>
