<div class="header pb-8  ">
  <div class="container-fluid">
    <div class="header-body">
      <!-- Card stats -->
      <div class=" mt-4">
        <!-- <nav aria-label="breadcrumb"> -->
        <ol class="breadCrumb_flex fsize24 marg-b-0">
          <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
              [routerLink]="['/dashboard']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Happiness Trainee Notes</li>
        </ol>
        <!-- </nav> -->
      </div>

      <!-- --------------new----------------- -->



      <!-- <h2>Read More Read Less Button</h2>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas vitae scel
  
  
  
  <span id="dots">...</span><span id="more">erisque enim ligula venenatis dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae massa. Fusce luctus vestibulum augue ut aliquet. Nunc sagittis dictum nisi, sed ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida venenatis. Integer fringilla congue eros non fermentum. Sed dapibus pulvinar nibh tempor porta.</span></p>
<button (click)="myFunction()" id="myBtn">Read more</button> -->












      <!-- ------------------new end------------- -->

      <div class="row">

        <div class="col-9">
          <p class="text_div">Happiness Trainee: {{userName}}</p>
          <!-- <p class="text_div_align">Age: 30</p> -->
          <!-- <p class="text_div_align">Session Number: 2</p> -->
          <!-- <p class="text_div_align">Gender: Male</p> -->
        </div>
        <div class="col-3 ">
          <div class="image_box"><img class="img-fluid imgFitSize header_image_check"
              src="assets/img/Characters/happy.png"></div>
        </div>
      </div>



      <div class="row">
        <div class="col-lg-6 col-xs-12  mt-3">

<div >

  <div class="card container1 br_div" >


    
 <div class="text_div">
  <div class="row" style="float:right; margin-right:10px;">
    <button
    (click)="addNewNote()"
        style="width: 130px;
    height: 34px; font-size: 14px; margin-right:20px; background: #FD1C70;border: 1px solid #FD1C70;color: #fff;border-radius:10px;"
        > Add New Note</button>
</div>
<div style="margin-top: 10px;"><p class="box_head_text">Previous Notes</p></div>
      
      <div class="" *ngFor="let item of datas; let i = index">
        <div *ngIf="i === 0" class="row marg-b-20">

          <div class="col-8">
            <div class="fontNotes">
              <span>{{item.thchhistory_value}}</span>
            </div>
          </div>
          <div class="col-4 padd_0">
            <button type="button" class="history_button" (click)="view_notes(item)">View History</button>
          </div>


        </div>
        <div *ngIf="i === 1" class="row marg-b-20">

          <div class="col-8">
            <div class="fontNotes">
              <span>{{item.thchhistory_value}}</span>
            </div>
          </div>
          <div class="col-4 padd_0">
            <button type="button" class="history_button" (click)="view_notes(item)">View History</button>
          </div>


        </div>


        <div *ngIf="i != 0 && i != 1 " class=" marg-b-20">

          <div id="more">
            <div class="row">
              <div class="col-8">
                <div class="fontNotes">
                  <span>{{item.thchhistory_value}}</span>
                </div>
              </div>
              <div class="col-4 padd_0">
                <button type="button" class="history_button" (click)="view_notes(item)">View History</button>
              </div>

            </div>

          </div>

        </div>

      </div>
      <span id="dots"></span>
      <button class="readMoreBtn" (click)="myFunction()" id="myBtn">View more</button>
    </div>
  </div>
</div>

        </div>
        <div class="col-lg-6 col-xs-12  mt-3">
          <div >
          <div class="card container1 br_div" >
            <div class="text_div">
              <div class="row" style="float:right; margin-right:10px;">
                <button
                (click)="addNewTips()"
                    style="width: 130px;
                height: 34px; font-size: 14px; margin-right:20px; background: #FD1C70;border: 1px solid #FD1C70;color: #fff;border-radius:10px;"
                    > Add New Tip</button>
            </div>
            <div  style="margin-top: 10px;">
              <p class="box_head_text">Previous Practice goals</p>
            </div>
           

              <div class=" marg-b-20" *ngFor="let item of goals; let i = index">


                <div *ngIf="i == 0 " class="row marg-b-20">

                  <div class="col-8">
                    <p class="box_detail_text">

                      {{item.thchhistory_value}}

                    </p>
                  </div>
                  <div class="col-4 padd_0">
                    <button type="button" class="history_button" (click)="view_goals(item)">View History</button>
                  </div>


                </div>


                <div *ngIf="i == 1 " class="row marg-b-20">

                  <div class="col-8">
                    <p class="box_detail_text">

                      {{item.thchhistory_value}}

                    </p>
                  </div>
                  <div class="col-4 padd_0">
                    <button type="button" class="history_button" (click)="view_goals(item)">View History</button>
                  </div>


                </div>




                <div *ngIf=" i != 0 && i != 1 " class=" marg-b-20">

                  <div  id="moreone" style="display:none;" >
                    <div class="row">
                      <div class="col-8">
                        <p class="box_detail_text">

                          {{item.thchhistory_value}}

                        </p>
                      </div>
                      <div class="col-4" style="padding: 0px;" >

                        <div>
                          <button type="button" class="history_button" (click)="view_goals(item)">View History</button>
                        </div>
                       
                      </div>

                    </div>

                  </div>

                </div>

              </div>

            
              <span id="dots1"></span>
              <button class="readMoreBtn" (click)="myFunction1()" id="myBtn1">View more</button>
    
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-xs-12 div_box_style1 mt-3">
          <div class="card container1 br_div" *ngIf="previousNotesEmtyDiv">
            <div class="text_div">
              <p class="box_head_text text-center">Previous Notes</p>
              <div class="card-body">
                <div class="row">
                  <div class="col-4"></div>
                  <div class="col-4"><img class="img-fluid header_image_check" src="assets/img/Icon/no record.png">
                  </div>
                  <div class="col-4"></div>
                </div>
                <div class="row">
                  <!-- <div class=""> -->
                  <div class="col-4"></div>
                  <div class="col-4">
                    <p class="box_content_text text-center">No previous notes</p>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 div_box_style1 mt-3">
          <div class="card container1 br_div" *ngIf="previousGoalsEmtyDiv">
            <div class="text_div">
              <p class="box_head_text text-center">Previous Practice goals</p>
              <div class="card-body">
                <div class="row">
                  <div class="col-4"></div>
                  <div class="col-4"><img class="img-fluid header_image_check" src="assets/img/Icon/no record.png">
                  </div>
                  <div class="col-4"></div>
                </div>
                <div class="row">
                  <!-- <div class=""> -->
                  <div class="col-4"></div>
                  <div class="col-4">
                    <p class="box_content_text text-center">No previous practice goals</p>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <!-- <div class="text-center row justify-content-center"> -->
          <div>
            <button type="button" class="align_button mt-4" (click)="back()">Back</button>
          </div>

          <div class="marg-l-20">
            <button type="button" class="align_cnt_button mt-4 " (click)="next()">Done</button>
          </div>
       
      
        <!-- </div> -->
      </div>
 </div>
  </div>

</div>