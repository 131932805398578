import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-happiness-community-notes',
  templateUrl: './happiness-community-notes.component.html',
  styleUrls: ['./happiness-community-notes.component.css']
})
export class HappinessCommunityNotesComponent implements OnInit {

  trainee_url= "/happiness-community-comment";
  isShown: boolean = false;
  communityNotes:any;
  constructor(private router: Router) {}

  ngOnInit(){
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }
  this.communityNotes = localStorage.getItem('communityTopicName');
  }

  next(){
    this.router.navigate([this.trainee_url]);
  }

}
