<!-- @author:Rajapandi k
      Date : 23/12/2021 -->
<div class="header pb-8 bgr_clr_f5fcff ">
    <div class="container-fluid">
        <div class="header-body">

            <div class="padd-l-30 padd-t-20">
                <ol class="breadCrumb_flex fsize24">
                    <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                            [routerLink]="['/happiness-community']">Back</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Happiness Community Notes</li>
                </ol>
            </div>

            <div class="text-center fsize30 font_clr_green marg-5rem">

                <!-- <div class="text-center "> Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>

                <div class="text-center marg-t-30">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis,
                    lectus magna fringilla urna, porttitor
                </div>


                <div class="text-center marg-t-30"> Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam
                </div>

                <div class="text-center marg-t-30">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis,
                    lectus magna fringilla urna, porttitor
                </div>

                <div class="text-center marg-t-30">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis,
                    lectus magna fringilla urna, porttitor
                </div>


                <div class="text-center marg-t-30">
                    Lorem ipsum dolor sit amet, consectetur
                </div> -->
                <!-- -------------------------------------------------------------- -->

                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Anxiety'">
                    Sometimes, just knowing you aren't alone wins half the battle!
                    Use this space to share your thoughts and experiences about anxiety.
                    Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2
                    happiness points!
                    Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll
                    deduct 20 happiness points.
                    Comments made in the happiness community is not medical advice. Schedule a session with a happiness
                    coach if you wish to seek professional advice.


                </div>

                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Depression'">
                    Sometimes, just knowing you aren't alone wins half the battle!
                    Use this space to share your thoughts and experiences about depression.
                    Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2
                    happiness points!
                    Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll
                    deduct 20 happiness points.
                    Comments made in the happiness community is not medical advice. Schedule a session with a happiness
                    coach if you wish to seek professional advice.
                </div>


                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Grief & Loss'">

                    Sometimes, just knowing you aren't alone wins half the battle!
                    Use this space to share your thoughts and experiences about overcoming grief & loss.
                    Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2
                    happiness points!
                    Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll
                    deduct 20 happiness points.
                    Comments made in the happiness community is not medical advice. Schedule a session with a happiness
                    coach if you wish to seek professional advice.
                </div>

                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Stress'">
                    Sometimes, just knowing you aren't alone wins half the battle!
                    Use this space to share your thoughts and experiences about dealing with stress.
                    Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2
                    happiness points!
                    Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll
                    deduct 20 happiness points.
                    Comments made in the happiness community is not medical advice. Schedule a session with a happiness
                    coach if you wish to seek professional advice.
                </div>

                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Loneliness'">
                    Sometimes, just knowing you aren't alone wins half the battle!
                    Use this space to share your thoughts and experiences about loneliness.
                    Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2
                    happiness points!
                    Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll
                    deduct 20 happiness points.
                    Comments made in the happiness community is not medical advice. Schedule a session with a happiness
                    coach if you wish to seek professional advice.

                </div>

                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Toxic Relationships'">
                    Sometimes, just knowing you aren't alone wins half the battle!
                    Use this space to share your thoughts and experiences about dealing with toxic relationships.
                    Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2
                    happiness points!
                    Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll
                    deduct 20 happiness points.
                    Comments made in the happiness community is not medical advice. Schedule a session with a happiness
                    coach if you wish to seek professional advice.
                </div>


                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Gender Nonconformity & Identity'">
                    Sometimes, just knowing you aren't alone wins half the battle!
                    Use this space to share your thoughts and experiences about gender nonconformity & identity
                    Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2
                    happiness points!
                    Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll
                    deduct 20 happiness points.
                    Comments made in the happiness community is not medical advice. Schedule a session with a happiness
                    coach if you wish to seek professional advice.
                </div>

                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Low Self Esteem'">
                    Sometimes, just knowing you aren't alone wins half the battle!
                    Use this space to share your thoughts and experiences about low self esteem.
                    Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2
                    happiness points!
                    Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll
                    deduct 20 happiness points.
                    Comments made in the happiness community is not medical advice. Schedule a session with a happiness
                    coach if you wish to seek professional advice.
                </div>


                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Panic Attack'">
                    Sometimes, just knowing you aren't alone wins half the battle!
                    Use this space to share your thoughts and experiences about dealing with panic attacks
                    Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2
                    happiness points!
                    Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll
                    deduct 20 happiness points.
                    Comments made in the happiness community is not medical advice. Schedule a session with a happiness
                    coach if you wish to seek professional advice.
                </div>


                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Economic Hardships'">
  Sometimes, just  knowing you aren't alone wins half the battle! 
Use this space to share your thoughts and experiences about economic hardships. 
Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2 happiness points! 
Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll deduct 20 happiness points. 
Comments made in the happiness community is not medical advice. Schedule a session with a happiness coach if you wish to seek professional advice. 

                </div>


                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Social Anxiety'">
 Sometimes, just  knowing you aren't alone wins half the battle! 
Use this space to share your thoughts and experiences about social anxiety. 
Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2 happiness points! 
Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll deduct 20 happiness points. 
Comments made in the happiness community is not medical advice. Schedule a session with a happiness coach if you wish to seek professional advice. 
                </div>

                <div class="text-center marg-t-30" *ngIf="communityNotes === 'Social Anxiety'">
                    Sometimes, just  knowing you aren't alone wins half the battle! 
                   Use this space to share your thoughts and experiences about social anxiety. 
                   Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2 happiness points! 
                   Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll deduct 20 happiness points. 
                   Comments made in the happiness community is not medical advice. Schedule a session with a happiness coach if you wish to seek professional advice. 
                                   </div>

              <div class="text-center marg-t-30" *ngIf="communityNotes === 'OCD'">
                Sometimes, just  knowing you aren't alone wins half the battle! 
  Use this space to share your thoughts and experiences about dealing with OCD. 
                 Click on the heart icon to show your appreciation for a comment that helped you! We'll gift them 2 happiness points! 
                  Click on the thumbs down icon next to an entry to flag an inappropriate comment . Upon review, we'll deduct 20 happiness points. 
             Comments made in the happiness community is not medical advice. Schedule a session with a happiness coach if you wish to seek professional advice. 
              </div>








            </div>


            <div class="text-center marg-t-76">
                <button class="done_btn" (click)="next()">Join</button>
            </div>

        </div>
    </div>
</div>