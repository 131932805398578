import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roster-message-center',
  templateUrl: './roster-message-center.component.html',
  styleUrls: ['./roster-message-center.component.css']
})
export class RosterMessageCenterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }
  }

}
