import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
declare var swal: any;
@Component({
  selector: 'app-happiness-community-comment',
  templateUrl: './happiness-community-comment.component.html',
  styleUrls: ['./happiness-community-comment.component.css']
})
export class HappinessCommunityCommentComponent implements OnInit {
  retunUrl = "/happiness-community";
  communityPostId: any;
  postComments: any;
  getlistpostComments: any = [];
  isShown: boolean = false;
  postData: any;
  postAllId: any;
  postCreateId: any;
  ids: any;
  loginUserId:any;
  getlistposts = [];
  showDeleteCircleIcon = false;
  page = 1;
  pageSize =5;
  allpageItems = [];
  commenttt : any;
  getlistLength:any;
  postIds:any;
  commentsId:any;
  postDelete_Id:any;
  showHeartInActiveDiv = true;
  showHeartActiveDiv  = false;
  showDislikeInActiveDiv = true;
  showDislikeActiveDiv = false;
  dummyarr=[];
  totalPostList:any;
  joinIds:any;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }
    var userids = JSON.parse(localStorage.getItem('userid'));
    this.joinIds = localStorage.getItem('joinCommunityId');
     this.loginUserId =  userids;
     this.communityPostId = this.ApiService.community_Id;
    this.postCreateId = this.ApiService.post_Ids;

    this.getPost_ListApi();
    this.getPostCommentListApi();


  }

  back() {
    this.router.navigate([this.retunUrl]);
  }

  async CommunityPostSubmit() {
    this.postCreateId = this.ApiService.post_Ids;
    console.log(this.postCreateId, 'ee')
    var postapi = "/add_post_comment";
    var postCommentdata = {
      "post_id": this.ids,
      "comment": this.postComments
    };
    (await this.ApiService.postApi(postapi, postCommentdata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: ' Add Post Comment Successfully',
            })
            this.postComments = "";
          }
        }
   this.getPost_ListApi();
      });
  }

  async getPostCommentListApi() {

    this.postCreateId = this.ApiService.post_Ids;
    // if (this.ids != "") {
    //   var rajan = this.ids
    // }
    // else if (this.postCreateId != "") {
    //   var rajan = this.postCreateId
    // }


    var postapi = "/list_post_comment";
    var communityAdddata = {
      "post_id": this.ids,
    };
    (await this.ApiService.postApi(postapi, communityAdddata)).subscribe(
      res => {
        this.getlistpostComments = res.data;
      });
  }

  async like_or_dislikeCommentApi() {
    var postapi = "/like_or_dislike_post";
    var like_dislikeData = {
      "id": "2",
      "comment_status": "dislike",
      "comment_like": "1",
      "comment_dislike": "2"
    };
    (await this.ApiService.postApi(postapi, like_dislikeData)).subscribe(
      res => {

        this.getlistpostComments = res.data;
      });
  }

  async commentsLike(likeItems: any) {
    this.showHeartInActiveDiv = false;
    this.showHeartActiveDiv = true;
    var commentId = likeItems.id;
    var likeId = likeItems.comment_like;
    var disLikeId = likeItems.comment_dislike;

    var postapi = "/like_or_dislike_post";
    var like_dislikeData = {
      "id": commentId,
      "comment_status": "like",
      "comment_like": likeId,
      "comment_dislike": disLikeId
    };
    (await this.ApiService.postApi(postapi, like_dislikeData)).subscribe(
      res => {

        this.getlistpostComments = res.data;
        if (res) {
          if (res.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: ' Like Post Comment Successfully',
            })

          }
          else if (res.status === 500){
            console.log(res)
          }

        }
        this.getPost_ListApi();
      });

  }

  async commentsDislike(disLikeItem: any) {



 this.showDislikeInActiveDiv = false;
 this.showDislikeActiveDiv = true;
  var commentId = disLikeItem.id;

    var likeId = disLikeItem.comment_like;
    var disLikeId = disLikeItem.comment_dislike;

    var postapi = "/like_or_dislike_post";
    var like_dislikeData = {
      "id": commentId,
      "comment_status": "dislike",
      "comment_like": likeId,
      "comment_dislike": disLikeId
    };
    (await this.ApiService.postApi(postapi, like_dislikeData)).subscribe(
      res => {

        this.getlistpostComments = res.data;

        if (res) {
          if (res.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: ' Dislike Post Comment Successfully',
            })

          }
          else if (res.status > 200){
            swal.fire({
              icon: 'error',
              title: 'error',
              text: ' Already  disliked',
            })
          }
        }



        this.getPost_ListApi();

      });

  }

  async removeComments(deleteItem: any) {
    this.postIds = deleteItem.post_id;
    this.commentsId = deleteItem.id;

    // -----------------------------------------
}

async removeCommentApi(){
  var postapi = "/remove_comment";
  var removeIds = {
    "post_id": this.postIds,
    "comment_id": this.commentsId
  };
  (await this.ApiService.postApi(postapi, removeIds)).subscribe(
    res => {

      if (res) {
        if (res.status === 200) {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Community Post Comment Deleted Successfully',
          })
          this.getPost_ListApi();
        }
      }


    });
 }






  async getPost_ListApi() {
    var userids = JSON.parse(localStorage.getItem('userid'));
    var postapi = "/list_post_community";
    var communityAdddata = {
      "community_id": this.communityPostId,
    };
    (await this.ApiService.postApi(postapi, communityAdddata)).subscribe(
      res => {
        this.getlistposts = res.data;
        // this.getlistLength = this.getlistposts.length;
        this.totalPostList = Math.ceil(this.getlistLength/5)
        console.log(this.totalPostList,'Totalmath.ceil')


        for (let item of this.getlistposts) {

             var communityUserId = item.user_id;
             var postdiv = item.post;
            //  var postPara = "<p>" +postdiv+ "<p>"
            //  this.dummyarr.push( {...item,post:postPara});
           if(communityUserId == userids){
              var community_userid = item.user_id;
              if(community_userid == userids ){
                this.showDeleteCircleIcon = true
              }
                    }


   }

   for (let i = 1; i <= this.totalPostList; i++) {
    this.getlistposts.push( this.getlistposts);
  }
   });
  }


  async addPost_Createapi() {
    var postapi = "/add_post_community";
    var communityAddPost = {
      "post": this.postData,
      "community_id": this.communityPostId
    };
    (await this.ApiService.postApi(postapi, communityAddPost)).subscribe(
      res => {

        this.ApiService.post_Ids = res.post_id;
        if (res) {
          if (res.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: ' Add Post Community Successfully',
            })
            this.postData = "" ;
          }
        }
  this.getPost_ListApi();
      });
  }

  craetePostdatass(itemss: any) {
    this.postAllId = itemss.id;
    console.log(this.postAllId, 'postids')
  }

   deletePostApi(items: any) {

    this.postDelete_Id = items.id;


  }

 async removePostDeleteApi(){

    var postapi = "/remove_post";
    var deleteAdd = {
      "post_id": this.postDelete_Id
    };
    (await this.ApiService.postApi(postapi, deleteAdd)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            console.log(res, 'status')
            // swal({
            //   title: "Success!",
            //   text: "Community Post Deleted Successfully ",
            //   type: "success"
            // }, function () {
            //   alert('yo');
            // });

            swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Community Post Deleted Successfully',

            })


            this.getPost_ListApi();
          }
        }
      });

  }

  onChange($event, item) {
     this.commenttt = item = $event;
    console.log( this.commenttt);
  }


  async comment_postid(itemis: any) {

    this.ids = itemis.id;
  //  this.getPost_ListApi();
  var post_Comments = $("#postcomments").val();
   var postapi = "/add_post_comment";
   var postCommentdata = {
     "post_id": this.ids,
     "comment":   this.commenttt
   };

   console.log("dataa__------------------------",postCommentdata);

   (await this.ApiService.postApi(postapi, postCommentdata)).subscribe(
     res => {

       if (res) {
         if (res.status === 200) {
           swal.fire({
             icon: 'success',
             title: 'Success',
             text: ' Add Post Comment Successfully',
           })
           this.postComments = "";
         }
       }
  this.getPost_ListApi();
     });





  }







}
