import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-message-center-list',
  templateUrl: './message_center_list.component.html',
  styleUrls: ['./message_center_list.component.scss']
})
export class MessageCenterListComponent implements OnInit {
  title = 'datatables';
  usernames = '';
  isShown: boolean = false;
  messageList: any;
  dtOptions: DataTables.Settings = {};
  brainImageUrl = 'https://coach.thap.in//thap_coach/assets/img/Characters/happy.png';
  chatMsg_Url = "/trainee-roster";
  tokens: any;
  useri_ds: any;
  session_messsageid: any;
  constructor(private ApiService: ApiService, private router: Router,) { }
  ngOnInit() {
    if (!localStorage.getItem('authtoken') && window.location.hash != '#/login') {
      window.location.href = '#/'
    }
    this.usernames = localStorage.getItem('userName');
    this.getcoachuserlistApi();
    this.getProfilePictureApi();
  }

  async getcoachuserlistApi() {
    var getapi = "/message_center_list";
    var token = this.ApiService.loginToken;
    this.tokens = localStorage.getItem('authtoken');

    var table;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res);
      this.messageList = res.data;
      $(function () {
        table = $("#myTable").DataTable({
          "ordering": false
        });
      })

      console.log("message_view",this.messageList);

      // for(let item of this.messageList){

      //   var rajaa = item.coach_view;
      //   console.log(rajaa)

      // }


    });
  }



  async getProfilePictureApi() {
    var userids = JSON.parse(localStorage.getItem('userid'));
    var postApi = "/coach_view_profile_picture";
    // --------------------
    var uploadPicPost = {

      "id": userids
    };
    (await this.ApiService.postApi(postApi, uploadPicPost)).subscribe(
      res => {

        var getProfilePicdata = res.result;
        for (let item of getProfilePicdata) {

          var imagedata = item.thcoach_image;

          // if (getProfilePicdata.length > 0) {
          //   this.brainImageUrl  = imagedata;
          // }
          // else if (getProfilePicdata.length = 0) {
          //   this.brainImageUrl  = 'https://fatneedle.com/thap_coach/assets/img/Characters/happy.png';
          // }

          if (imagedata == null) {
            this.brainImageUrl = 'https://coach.thap.in//thap_coach/assets/img/Characters/happy.png';
          }
          else if (imagedata != null) {
            this.brainImageUrl = imagedata;

          }

        }


      });
  }


  messageBtn(item) {
    this.useri_ds = item.thusr_id;
    this.msgDashboardCount();
    // localStorage.setItem('coach_id',item.coach_id);
    localStorage.setItem('peerid', item.thusr_id);
    localStorage.setItem('session_id', item.session_id);
    // localStorage.setItem('session_UsrName', item.thusr_name);
    localStorage.setItem('session_UsrName', item.thusr_name);
    this.router.navigate([this.chatMsg_Url]);
  }



  async msgDashboardCount() {
    var postApi = "/update_message_dashboard";

    var uploadPicPost = {
      "user_id": this.useri_ds
    };
    (await this.ApiService.postApi(postApi, uploadPicPost)).subscribe(
      res => {
        console.log(res);
      });
  }



}
