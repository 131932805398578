import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import * as moment from 'moment';
declare var $: any;
declare let swal: any;
import { ConfirmPasswordValidator } from 'src/app/user-reset-password/confirm-password.validator';
import { Alert } from 'selenium-webdriver';
@Component({
  selector: 'app-availibility',
  templateUrl: './availibility.component.html',
  styleUrls: ['./availibility.component.css']
})
export class AvailibilityComponent implements OnInit {
  // availibility
  // availability-pickdate
  samepageroute = "/availibility";
  trainee_url = "/availability-pickdate";
  back_url = "/dashboard";

  show_day_div = true;
  slotdate: any;
  show_week_div = false;
  show_month_div = false;
  active_span_day = 'active_span';
  active_span_week = '';
  active_span_month = '';
  type: any
  getSlot_Start_time = "";
  getSlot_End_time = "";
  allTimes = [];
  isShown: boolean = false;
  getAll_SlotTime = [];
  session_Id: any;
  getAvailabilityAllData = [];
  slotStartDate: any;
  getavailabilityresult: any;
  totalslotTime: any;
  // -----------
  buttonsObj = {
    action1: { isSelected: false },
    action2: { isSelected: false },
    action3: { isSelected: false },
    action4: { isSelected: false }
  };
  slotsTime: any;
  slotsIds = [];
  ischecked: boolean = false;
  checkAllTrades: boolean = false;
  dayDeleteSlot = false;
  checkboxes = [
    { label: 'check 1', selected: false },
    { label: 'check 2', selected: false },
    { label: 'check 3', selected: false },
    { label: 'check 4', selected: false }
  ];
  slotbook:any;
  deleteAllSlotIds :number[];

  constructor(private router: Router, private ApiService: ApiService) { }

  async ngOnInit() {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }
    // ==========================================
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
    // $('#example').tooltip()

    // ------------------------------------------------------
    $(".multiple-select").mousedown(function (e) {

      var self = e.target;
      $(self).prop("selected", $(self).prop('selected') ? false : true).toggleClass("selected");
      return false;

    });

    $(".multiple-select-button").click(function (e) {

      var selectValue = $(".multiple-select").val();
      console.log(selectValue, 'selectValue.........');


      if (selectValue !== null) {
        selectValue = $.map(selectValue, function (val, i) {
          return val = " " + val;
        });

        $(".multiple-select-value").html("Selected values is: " + selectValue);
      }
      else {
        $(".multiple-select-value").html("Make your choice please!");
      }

    })

 // ---------------------------------------
    var user_Id = localStorage.getItem('userid');
    var postapi = "/get_availability_filter";

    var availabilityvariant = {
      "type": 'day'
    };
    (await this.ApiService.postApi(postapi, availabilityvariant)).subscribe(
      res => {
        console.log(res.result);

        var getslotstarttimeSlice = res.result[0].slot_start_time
        this.getSlot_Start_time = getslotstarttimeSlice;
        console.log(this.getSlot_Start_time, 'this.getSlot_Start_time......');


        var getslotEndtimeSlice = res.result[0].slot_end_time
        this.getSlot_End_time = getslotEndtimeSlice;

        this.getAll_SlotTime = res.result;
        console.log(this.getAll_SlotTime, 'times');
        //  -------------------------------------------------------------
        this.getAvailabilityAllData = res.result;
        for (let item of this.getAvailabilityAllData) {

          this.slotStartDate = item.slot_start_date;

        }

      });

    var postapi = "/get_availability";
    var useridData = {
      "user_id": user_Id
    };

    (await this.ApiService.postApi(postapi, useridData)).subscribe(
      res => {
        if (res) {
          var getslotstarttimeSlice = res.result[0].slot_start_time
          this.getSlot_Start_time = getslotstarttimeSlice

          var getslotEndtimeSlice = res.result[0].slot_end_time
          this.getSlot_End_time = getslotEndtimeSlice;

          this.getAll_SlotTime = res.result;
          console.log(this.getAll_SlotTime, 'times');
          //  -------------------------------------------------------------
          this.getAvailabilityAllData = res.result;
          for (let item of this.getAvailabilityAllData) {

            this.slotStartDate = item.slot_start_date;

            // console.log(this.slotStartDate,'slotStartDate');

          }

        }
      });

    var convertedStartTime = moment("8:30 PM", 'hh:mm A').format('HH:mm')
    var convertedEndTime = moment("11:30 PM", 'hh:mm A').format('HH:mm')


    //Data
    let x1 = {
      slotInterval: 30,
      openTime: convertedStartTime,
      closeTime: convertedEndTime
    };

    //Format the time
    let startTimeone = moment(x1.openTime, "HH:mm");
    //Format the end time and the next day to it
    let endTime = moment(x1.closeTime, "HH:mm");
    //Times
    // let allTimes = [];
    //Loop over the times - only pushes time with 30 minutes interval
    while (startTimeone < endTime) {
      //Push times
      this.allTimes.push(startTimeone.format("HH:mm"));
      //Add interval of 30 minutes
      startTimeone.add(x1.slotInterval, 'minutes');
    }
    console.log(this.allTimes, 'all30');
    // -----------------------------------------------------------
    this.day_div();
    this.gettype('day')
    // ----------------------------------------------------

  }
  async gettype(e: any) {
    this.type = e
    //console.log(e);
    var availabilityvariant = {
      "type": e
    };
    var postapi = "/get_availability_filter";
    (await this.ApiService.postApi(postapi, availabilityvariant)).subscribe(
      res => {
        //console.log(res.result);
        this.getavailabilityresult = res.result
        this.slotsIds = res.result;
        this.slotdate = res.slot_date;
        var getslotstarttimeSlice = res.result[0].slot_start_time
        this.getSlot_Start_time = getslotstarttimeSlice;
        console.log(res.result[0].time_slot, 'this.getSlot_Start_time.........................>>>>>');
        this.totalslotTime = res.result[0].time_slot;


        var getslotEndtimeSlice = res.result[0].slot_end_time
        this.getSlot_End_time = getslotEndtimeSlice;

        this.getAll_SlotTime = res.result;
        console.log(this.getAll_SlotTime, 'times');
        //  -------------------------------------------------------------
        this.getAvailabilityAllData = res.result;
        for (let item of this.getAvailabilityAllData) {

          this.slotStartDate = item.slot_start_date;

        }

      });
  }
  // -------------------------------------------------





  // ---------------------------------------------

  async availability_delete(e: any) {
    console.log(e.target.id);

  }

  async sweetdelete(e) {
    swal.fire({
      title: 'Are you sure you want to delete the Slot?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.value) {
        //var deleteurl = "/stock_management/"+e.target.id;
        var availabilityvariant = {
          "slot_id": e.target.id
        };
        var postapi = "/delete_slot";
        (await this.ApiService.postApi(postapi, availabilityvariant)).subscribe(
          res => {
            const currentUrl = this.samepageroute;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([currentUrl]);
            });
          })

      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire(
          'Cancelled',
          'The Slot has not been deleted',
          'error'
        )
      }
    })
  }

  day_div() {
    this.show_day_div = true;
    this.active_span_day = 'active_span';
    this.active_span_week = '';
    this.active_span_month = '';
    this.show_week_div = false;
    this.show_month_div = false;
  }

  week_div() {
    this.show_week_div = true;
    this.active_span_day = '';
    this.active_span_week = 'active_span';
    this.active_span_month = '';
    this.show_day_div = false;
    this.show_month_div = false;
  }

  month_div() {
    this.show_week_div = false;
    this.show_day_div = false;
    this.show_month_div = true;
    this.active_span_day = '';
    this.active_span_week = '';
    this.active_span_month = 'active_span';
  }
  // ------------------------------
  next() {
    this.router.navigate([this.back_url]);

  }
  updatenext() {
    this.router.navigate([this.trainee_url]);
  }
  // -------------------------------------------------------


  deleteAllSlots(event, items) {
    // if (isChecked.target.checked) {
    //   this.slotsIds.push(items.slot_id)
    //   console.log(this.slotsIds, 'items.slot_id....');
    //   this.multipleSlotsDelete();
    //   console.log(this.slotsIds, 'false....');

    // } else {
    //   let index = this.slotsIds.indexOf(items.slot_id);
    //   this.slotsIds.splice(index, 1);

    //   this.multipleSlotsDelete();
    // }

    // -----------------------------------------------------
    console.log('deleteSlots');
    // if (event.target.checked == true) {
    this.dayDeleteSlot = true;
    const checked = event.target.checked;
    items.forEach(item => item.selected = checked);
    console.log(items.map((d) => d.slot_id), ' this.totalslotTime//////');
    this.slotsIds = items.map((d) => d.slot_id)
    this.ischecked = true;
    console.log(this.slotsIds, 'true....');
    // }
    // ------------------------------------------------

    // else if (event.target.checked == false) {
    //   const checked = event.target.checked;
    //   items.forEach(item => item.selected = checked);
    //   console.log(items.map((d) => d.slot_id), ' this.totalslotTime//////');
    //   this.slotsIds = items.map((d) => d.slot_id)

    //   console.log(this.slotsIds, 'false....');
    // }
  }

  deleteSelectSlots(event: any, ind){
    const arr:any[]=this.getavailabilityresult[ind].time_slot;

    this.deleteAllSlotIds = arr.filter(o=>o.selected && o.status!= '2').map(o=>o.slot_id)
    console.log(arr.filter(o=>o.selected && o.status!= '2').map(o=>o.slot_id));
// this.deleteMultipleFunction();

  }


  async deleteMultipleFunction(){

    var postapi = "/delete_slots";
    var slotData = {
      "slot_ids": this.deleteAllSlotIds
    };
    // ------------------------------------
    console.log(slotData, 'slotData.....');
    (await this.ApiService.postApi(postapi, slotData)).subscribe(
      res => {
        console.log(res);
        if (res.status === 200) {
          window.location.reload();
          this.gettype('day')
        }

      });


  }

  async multipleSlotsDelete() {
    var postapi = "/delete_slots";
    var slotData = {
      "slot_ids": this.slotsIds
    };
    // ------------------------------------
    console.log(slotData, 'slotData.....');
    (await this.ApiService.postApi(postapi, slotData)).subscribe(
      res => {
        console.log(res);
        if (res.status === 200) {
          window.location.reload();
          this.gettype('day')
        }

      });


  }
  // ----------------------------------------------

  setButtonAction(objBtn: any) {
    var btnName = objBtn.name;
    console.log(btnName, '......btnName')
    var isSelected = this.buttonsObj[btnName].isSelected;
    console.log(isSelected, '.......isSelected')
    if (isSelected) {
      objBtn.classList.remove('btn-pressed');
    } else {
      objBtn.classList.add('btn-pressed');
    }
    this.buttonsObj[btnName].isSelected = !isSelected;
  }

  // --------------------------------

  toggleCheckboxAll(event, items:any[], ind) {
    if (event.target.checked == true) {
      this.getavailabilityresult[ind].time_slot=this.getavailabilityresult[ind].time_slot.map(item => ({...item, selected : item.status== '2'? false : true }));
    }else {
      this.getavailabilityresult[ind].time_slot=this.getavailabilityresult[ind].time_slot.map(item => ({...item, selected : false}));
    }
    console.log(this.getavailabilityresult[ind].time_slot)
  }

  singleCheckbox(event, items) {
    if (event.target.checked == true) {
      this.ischecked = true;
      // this.slotsIds = items.slot_id;
      // this.multipleSlotsDelete();
      const checked = event.target.checked;
      items.forEach(item => item.selected != checked);

      this.slotbook = items.map((d) => d.slot_id )
      console.log(items.map((d) => d.slot_id), '  Slot//////');

      console.log(items.slot_id, 'items....single');
    }
    else if(event.target.checked == false){
      const checked = event.target.checked;
      items.forEach(item => item.selected != checked);

      this.slotbook = items.map((d) => d.slot_id )
      console.log(items.map((d) => d.slot_id), ' Not Use Availability Slot//////');


    }

    else if (this.ischecked && this.checkAllTrades) {
      event.target.checked = true
    }
    else {
      let index = this.slotsIds.indexOf(items.slot_id);
      this.slotsIds.splice(index, 1);
    }
  }


  // ------------------Try Check Box ------------------------------






}


