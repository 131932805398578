<!-- @author:Rajapandi k
      Date : 23/12/2021 -->
<div class="header pb-8 bgr_clr_f5fcff ">
    <div class="container-fluid">
        <div class="header-body">

            <div class="padd-t-80">
                <div class="row marg-2rem ">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12  padd-0 ">

                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 padd-0 ">
                        <div class=" cardNotes ">
                            <div class="padd-36-74">

                                <div class="fsize16 font_clr_6a7387 marg-t-20 ">
                                    <div class="row marg-0">
                                        <div>
                                            Availability for:
                                        </div>

                                        <div class="row marg-l-6rem">
                                            <div>
                                                <a>Day</a>
                                            </div>
                                            <div class="marg-l-25">

                                                <a>Week</a>

                                            </div>
                                            <div class="marg-l-25">
                                                <a>Month</a>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="font_clr_6a7387 fsize13">06, December 2021 - 12, December 2021</div>

                                </div>
                                <!-- -------------------------content------------------------------------------- -->

                                <div class="fsize15 font_clr_orng marg-t-32">
                                    06, December 2021
                                </div>


                                <div class="marg-t-20">


                                    <div class="row marg-0">
                                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">
                                            <div class="w-160">
                                                <button class="time_btn"> 03:00 pm </button>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0  ">

                                            <div class="w-160 marg-l-8">
                                                <button class="time_btn"> 03:30 pm </button>
                                            </div>


                                        </div>

                                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">

                                            <div class="w-160 marg-l-16">
                                                <button class="time_btn"> 04:00 pm </button>
                                            </div>

                                        </div>

                                    </div>
                                    <!-- ------------------------------------- -->
                                    <div class="marg-t-20">
                                        <div class="row marg-0">
                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">
                                                <div class="w-160">
                                                    <button class="time_btn"> 04:30 pm </button>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0  ">

                                                <div class="w-160 marg-l-8">
                                                    <button class="time_btn"> 05:00 pm </button>
                                                </div>


                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">

                                                <div class="w-160 marg-l-16">
                                                    <button class="time_btn"> 05:30 pm </button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>


                                    <div class="marg-t-40 fsize15 font_clr_orng">

                                        07, December 2021

                                    </div>

                                    <div class="marg-t-20">
                                        <div class="row marg-0">
                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">
                                                <div class="w-160">
                                                    <button class="time_btn"> 03:00 pm </button>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0  ">

                                                <div class="w-160 marg-l-8">
                                                    <button class="time_btn"> 03:30 pm </button>
                                                </div>


                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">

                                                <div class="w-160 marg-l-16">
                                                    <button class="time_btn"> 04:00 pm </button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="marg-t-20">
                                        <div class="row marg-0">
                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">
                                                <div class="w-160">
                                                    <button class="time_btn"> 04:30 pm </button>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0  ">

                                                <div class="w-160 marg-l-8">
                                                    <button class="time_btn"> 05:00 pm </button>
                                                </div>


                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">

                                                <div class="w-160 marg-l-16">
                                                    <button class="time_btn"> 05:30 pm </button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="marg-t-40 fsize15 font_clr_orng">

                                        08, December 2021

                                    </div>

                                    <div class="marg-t-20">
                                        <div class="row marg-0">
                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">
                                                <div class="w-160">
                                                    <button class="time_btn"> 03:00 pm </button>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0  ">

                                                <div class="w-160 marg-l-8">
                                                    <button class="time_btn"> 03:30 pm </button>
                                                </div>


                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">

                                                <div class="w-160 marg-l-16">
                                                    <button class="time_btn"> 04:00 pm </button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>




                                    <div class="marg-t-40 fsize15 font_clr_orng">

                                        09, December 2021

                                    </div>

                                    <div class="marg-t-20">
                                        <div class="row marg-0">
                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">
                                                <div class="w-160">
                                                    <button class="time_btn"> 04:30 pm </button>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0  ">

                                                <div class="w-160 marg-l-8">
                                                    <button class="time_btn"> 05:00 pm </button>
                                                </div>


                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 ">

                                                <div class="w-160 marg-l-16">
                                                    <button class="time_btn"> 05:30 pm </button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>







                                    <div class="text-center marg-t-50">
                                        <button class="done_btn">Update availability</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12  padd-0 ">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>