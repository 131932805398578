import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
declare var moment: any;
declare var $: any;
@Component({
  selector: 'app-billing-hours',
  templateUrl: './billing-hours.component.html',
  styleUrls: ['./billing-hours.component.css']
})
export class BillingHoursComponent implements OnInit {
  isShown: boolean = false;
  getAllBillingCount: any;
  appoinmenCounts: any;
  couchFees: any;
  earnedDay: any;
  earnedWeek: any;
  earnedMonth: any;
  getAllcoach_statistics = [];
  countCouchStatics: any;
  sessionBooked = "0";
  sessionHoursMissed = "0";
  new_Client = "0";
  total_sessionHours = "0";
  totalFees = "0";
  grossNetEarnings = "0";
  appointmentTime: any;
  timing: any;
  timingArray = [];
  sessionStart_Date = "";
  sessionEnd_Date = "";
  usernames = "";

  pages: any;
  page = 1;
  pageSize = 5;

  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }
    this.usernames = localStorage.getItem('userName');

    //  this.getdashboardCountApi();

    // const that = this;
    // $('#clickable-picker').click(function () {
    //   that.datePickerFunctions();
    // });
    this.datePickerFunctions();

  }

  sessionCountApi = async () => {

    var postapi = "/coach_statistics";
    var sessionDate = {

      "from": this.sessionStart_Date,

      "to": this.sessionEnd_Date + " 23:59:59",
    };
    (await this.ApiService.postApi(postapi, sessionDate)).subscribe(
      res => {
        console.log(res);
        if (res.status === 200) {

          this.countCouchStatics = res.data;
          this.sessionBooked = res.data.sessionhoursbooked;
          this.sessionHoursMissed = res.data.missedsessionhours;
          this.new_Client = res.data.newclients;
          this.total_sessionHours = res.data.totalsessionhours;
          this.totalFees = res.data.totalfee;
          this.grossNetEarnings = res.data.grossnetrevenue;
          // newclients grossnetrevenue

          console.log(this.sessionHoursMissed, 'sessionBooked')

        }
      });


  }


  datePickerFunctions = () => {
    var startDate;
    var endDate;
    var date_range;
    const that = this;

    $('#picker').daterangepicker({
      opens: 'left',
      timePicker: true,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      startDate: moment().subtract(29, 'days'),
      endDate: moment()
    },
      async function (start, end) {
        $('#daterange-btn span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
        console.log(start.format('DD-MM-YYYY'), 'startDate'),
          $('input').val(start.format('DD-MM-YYYY') + ' - ' + end.format('DD-MM-YYYY'))
        // this.raja = end.format('DD-MM-YYYY'),'startDate';
        that.sessionStart_Date = start.format('YYYY-MM-DD');
        console.log(that.sessionStart_Date, 'start')
        that.sessionEnd_Date = end.format('YYYY-MM-DD');
        if (start != "" && end != "") {
          that.sessionCountApi();
          that.getdashboardCountApi();
        }
        console.log(that.sessionEnd_Date, 'end date');
      }


    );


  }


  async getdashboardCountApi() {



    var getapi = "/session_user_details";

    var sessionDate1 = {

      "from": this.sessionStart_Date,

      "to": this.sessionEnd_Date,
    };

    (await this.ApiService.postApi(getapi, sessionDate1)).subscribe((res) => {
      console.log(res);
      // timingArray
      this.timingArray = res.data;


      // var table
      //       $(function () {
      //         table = $("#my_Table").DataTable({
      //      "ordering": false
      //        });
      //        })

      this.getAllcoach_statistics=[];
      for (let item of res.data) {

        var appointmentstatus = item.sessionStatus;
        console.log(appointmentstatus, 'status')

        if (appointmentstatus != "Waiting") {

          // -----

          var timeString = item.startTime;
          var hourEnd = timeString.indexOf(":");
          var H = +timeString.substr(0, hourEnd);
          var h = H % 12 || 12;
          var ampm = H < 12 ? "AM" : "PM";
          this.appointmentTime = h + timeString.substr(hourEnd, 3) + ampm;
          this.timing = this.appointmentTime;
          // --------------------------------------------

          var timeStringend = item.endTime;
          var hourEnd = timeStringend.indexOf(":");
          var H = +timeStringend.substr(0, hourEnd);
          var h = H % 12 || 12;
          var ampm = H < 12 ? "AM" : "PM";
          var appointmentEndTime = h + timeStringend.substr(hourEnd, 3) + ampm;
          var timingEnd = appointmentEndTime;
          // ------------------------------------------------
          var session_Date = item.date;
   var sessionDate = moment(session_Date).format('DD-MM-YYYY');

          this.getAllcoach_statistics.push({ ...item, startTime: this.timing, endTime: timingEnd, date: sessionDate });

          // -------------
        }
      }
      console.log(this.getAllcoach_statistics, 'coachStatics')
    });
  }

  handlePageChange(e: any) {
    // this.page = e;
    console.log(e);
    this.page = e;
  }




  // async getdashboardCountApi() {
  //   var getapi = "/coach_billing_count";

  //   (await this.ApiService.getApi(getapi)).subscribe((res) => {
  //     console.log(res);
  //     this.getAllBillingCount = res.data;
  //     for (let item of this.getAllBillingCount) {
  //       this.appoinmenCounts = item.appoint_count;
  //       this.couchFees = item.coach_fees;
  //       this.earnedDay = item.today_earn_count;
  //       this.earnedWeek = item.week_earn_count;
  //       this.earnedMonth = item.month_earn_count;

  //       if (this.couchFees == null) {
  //         this.couchFees = "0";
  //       }
  //       if (this.earnedDay == null) {
  //         this.earnedDay = "0";

  //       }
  //       if (this.earnedWeek == null) {
  //         this.earnedWeek = "0";
  //       }
  //       if (this.earnedMonth == null) {
  //         this.earnedMonth = "0";
  //       }
  //     }
  //   });
  // }

}
