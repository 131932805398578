import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
declare var $: any;
@Component({
  selector: 'app-happiness_trainee',
  templateUrl: './happiness_trainee.component.html',
  styleUrls: ['./happiness_trainee.component.scss']
})
export class HappinessTraineeComponent implements OnInit {
  notes_url = "/add_notes";
  goals_url = "/add_goals";
  trainee_url = "/trainee-notes";
  session_url = "/happiness-training-session";
  bacKHome_url = "/dashboard";
  notesAllHistory = [];
  allarrayValue = "";
  // datas = [];
  new = [];
  // datas = [];
  datas: string[] = [];
  goals : string[] = [];
  isShown:boolean = false;
  previousNotesEmtyDiv = false;
previousGoalsEmtyDiv = false;
previousGoalsDataDiv = false;
previousNotesDataDiv = false;
userName:any;
session_UsrId:any;
userAllid:any;
addNewNotesUrl = "/trainee_add_Newnotes"
  addNewTipsUrl = "/trainee_add_NewTips"
  constructor(private router: Router, private ApiService: ApiService) {
  }

 ngOnInit() {
  if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
    window.location.href='#/'
  }

    this.userName = localStorage.getItem('userListName');
    var userids = JSON.parse(localStorage.getItem('userid'));
    this.session_UsrId = localStorage.getItem('sessionUsrId');
    this.userAllid = localStorage.getItem('sessionHistoryUsrid');
  //   var getapi = "/get_session_history?"  + "user_id=" + userids + "&type=note" ;
  //   (await this.ApiService.getApi(getapi)).subscribe((res) => {
  //     this.datas = res.data;
  //     if (this.datas.length > 0) {
  //       this.previousNotesEmtyDiv = false;
  //       this.previousGoalsEmtyDiv = false;
  //       this.previousGoalsDataDiv = true;
  //       this.previousNotesDataDiv = true;
  //     }
  //     else if (this.datas.length = 0) {
  //       this.previousNotesEmtyDiv = true;
  //       this.previousGoalsEmtyDiv = true;
  //       this.previousGoalsDataDiv = false;
  //       this.previousNotesDataDiv = false;
  //     }
  // });
// ---------------------------------------------------
this.goalfun();
this.notefun();

// var userids = JSON.parse(localStorage.getItem('userid'));
// var getapiGoal = "/get_session_history?"+"user_id=" + userids + "&type=goal"  ;
//   (await this.ApiService.getApi(getapiGoal)).subscribe((res) => {
//     this.goals = res.data;
//     console.log(this.goals, 'goalsValues');
// });
 }

 async notefun(){
    var postapi = "/get_session_history";
var postCommentdata = {
  "user_id": this.userAllid,
  "type":"note",
  // "coach_id":"129"
};
(await this.ApiService.postApi(postapi, postCommentdata)).subscribe(
  res => {
    console.log(res);
    this.datas = res.data;
    if (this.datas.length > 0) {
      this.previousNotesEmtyDiv = false;
      this.previousGoalsEmtyDiv = false;
      this.previousGoalsDataDiv = true;
      this.previousNotesDataDiv = true;
    }
    else if (this.datas.length = 0) {
      this.previousNotesEmtyDiv = true;
      this.previousGoalsEmtyDiv = true;
      this.previousGoalsDataDiv = false;
      this.previousNotesDataDiv = false;
    }

  });

  }

async goalfun(){
    var postapi = "/get_session_history";
var postgoaldata = {
  "user_id":  this.userAllid,
  "type":"goal",
  // "coach_id":"129"
};
(await this.ApiService.postApi(postapi, postgoaldata)).subscribe(
  res => {
    console.log(res);
    this.goals = res.data;
    if (this.datas.length > 0) {
      this.previousNotesEmtyDiv = false;
      this.previousGoalsEmtyDiv = false;
      this.previousGoalsDataDiv = true;
      this.previousNotesDataDiv = true;
    }
    else if (this.datas.length = 0) {
      this.previousNotesEmtyDiv = true;
      this.previousGoalsEmtyDiv = true;
      this.previousGoalsDataDiv = false;
      this.previousNotesDataDiv = false;
    }

  });
  }
  next() {
    this.router.navigate([this.bacKHome_url]);
  }

  back() {
    this.router.navigate([this.trainee_url]);
  }
  view_notes(items:any) {
  this.ApiService.noteSessionId = items.thchhistory_id;
  localStorage.setItem('noteSession_Id', items.thchsession_id);
  localStorage.setItem('noteHistoryUserid', items.thchhistory_user_id);
  localStorage.setItem('noteHistoryDate', items.thchhistory_date);
  localStorage.setItem('noteHistoryId', items.thchhistory_id);
  // thchhistory_id
   this.router.navigate([this.notes_url]);
  }

  view_goals(item) {

    this.ApiService.goalSessionId = item.thchsession_id;
    localStorage.setItem('goalSession_Id', item.thchsession_id);
    localStorage.setItem('goalHistoryUserid', item.thchhistory_user_id);
    localStorage.setItem('goalHistoryDate', item.thchhistory_date);
    localStorage.setItem('goalHistoryId', item.thchhistory_id);


    this.router.navigate([this.goals_url]);
  }
  myFunction1(){
    var dots2 = document.getElementById("dots1");
    var moreText1 = document.getElementById("moreone");
    var btnText1 = document.getElementById("myBtn1");

    if (dots2.style.display === "none") {
      dots2.style.display = "inline";
      btnText1.innerHTML = "View more";
      moreText1.style.display = "none";
    } else {
      dots2.style.display = "none";
      btnText1.innerHTML = "View less";
      moreText1.style.display = "inline";
    }

  }


  myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "View more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "View less";
      moreText.style.display = "inline";
    }
  }



  addNewNote(){
    this.router.navigate([this.addNewNotesUrl]);
  }
  addNewTips(){
    this.router.navigate([this.addNewTipsUrl]);
  }


}
