<div class="row">
    <div class="body_bar col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 circle-section">

        <div class="container  pb-5 margin_t_60 ">

            <div class="row justify-content-center">

                <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
                    <div class="card cardSize shadow border-0">
                        <img class="img-fluid" src="assets/img/Characters/happy.png">
                        <div class="card-body px-lg-5 ">
                            <div class="text-center text-muted mb-3 mt-4">
                                <small class="login">Forgot Password</small>
                            </div>
                            <div class="text-center text-muted mb-4">
                                <small class="login_text">Happiness Coach Portal</small>
                            </div>
                            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                <div class="form-group mb-3">
                                    <div class=" marg-l-15">
                                        <input class=" emailInput" style="padding-left: 15px;" [(ngModel)]="password"
                                            formControlName="password" max="8" placeholder="New Password"
                                            type="password" autocomplete="off">
                                    </div>
                                    <div *ngIf="submitted && registerForm.get('password').errors"
                                        class="alert alert-danger">
                                        <div *ngIf="registerForm.get('password').errors.required">Password is required
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="form-group mb-3">
                                    <div class=" marg-l-15">
                                        <input class=" emailInput" style="padding-left: 15px;"
                                            [(ngModel)]="confirmPassword" formControlName="confirmPassword" max="8"
                                            placeholder="Confirm Password" id="newpassword" type="password"
                                            autocomplete="off">
                                    </div>

                                    <div *ngIf="submitted && registerForm.get('confirmPassword').errors"
                                        class="alert alert-danger">
                                        <div *ngIf="registerForm.get('confirmPassword').errors.required">Confirm
                                            Password is required</div>

                                        <div
                                            *ngIf="registerForm.get('confirmPassword').errors.confirmPasswordValidator">
                                            Passsword and
                                            Confirm Password didn't match.</div>
                                    </div>
                                </div>

                                <div class=" marg-t-18 marg-l-15">
                                    <button type="submit" class="btn submit_button" data-toggle="modal">Submit</button>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-6">
                                        <a href="javascript:void(0)">
                                            Back to
                                            <a href="" class=" blue"> Signin</a>
                                        </a>
                                    </div>
                                    <div class="col-6 text-right">
                                        <a [routerLinkActive]="['active']" (click)="isShown = false"
                                            [routerLink]="['/signup']" class=" blue">
                                            Signup
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>


            </div>


        </div>

    </div>
</div>