<div class="header pb-8 bgr_clr_whole ">
  <div class="container-fluid">
    <div class="header-body">
      <!-- Card stats -->
      <div class="row">
        <div class="col-9 mt-4">
          <p class="text_div">Happiness Trainee: {{clientUsrName}}</p>
          <!-- <p class="text_div_align">Age: 30</p> -->
          <!-- <p class="text_div_align">Session Number: 2</p>
           <p class="text_div_align">Gender: Male</p> -->
        </div>
        <div class="col-3 mt-4">
          <div class="image_box"><img class="img-fluid header_image_check" src="assets/img/Characters/happy.png"></div>
        </div>
      </div>
    </div>
    <!-- ---------------------------------------------- -->
    <div *ngIf="msgNotification">
      <div aria-live="polite" aria-atomic="true" style="position: relative;">
        <div class="toast"
          style="position: absolute; top: 0; right: 0;border: 2px solid green;padding: 8px;  height: 80px;margin: 10px 10px 0px 0px;background-color:green; color: #fff;width:250px;">
          <div class="toast-header">

            <div>
              <strong class="mr-auto">Notification</strong>

              <button  (click)="notifyClose()" type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="toast-body">
            <div>
              There are only 10 minutes left
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- ---------------------------------------------- -->

    <div class="">
      <!-- <nav aria-label="breadcrumb"> -->
      <ol class="breadCrumb_flex fsize24">
        <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
            [routerLink]="['/dashboard']">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Happiness Training Session </li>
      </ol>
      <!-- </nav> -->
    </div>
    <div class="padd-l-50 padd-t-70">
      <div class="row marg-0">


        <div class="col-lg-8 col-md-6 col-sm-6 col-xs-12 ">
          <div class="card_s padd-32-30">
            <div class="bdr_card ">




              <div class="wholeDiv">

                <div class="page">
                  <div class=" d-flex flex-row flex-wrap w-100 justify-content-center">

             <div>

    <div class="container">

                        <form id="join-form">
<!--
                          <div  >
                                              <div class="row join-info-group">
                                                <div class="col-sm">
                                                  <p class="join-info-text">APP ID</p>
                                                  <input id="appid" type="text" placeholder="Enter the appid" required>
                                                  <p class="tips">You find your APP ID in the <a href="https://console.agora.io/projects">Agora Console</a></p>
                                                </div>
                                                <div class="col-sm">
                                                  <p class="join-info-text">Token(optional)</p>
                                                  <input id="token" type="text" placeholder="Enter the app token">
                                                  <p class="tips">To create a temporary token, <a href="https://console.agora.io/projects">edit your project</a> in Agora Console.</p>
                                                </div>
                                                <div class="col-sm">
                                                  <p class="join-info-text">Channel Name</p>
                                                  <input id="channel" type="text" placeholder="Enter the channel name" required>
                                                  <p class="tips">You create a channel when you create a temporary token. You guessed it, in <a href="https://console.agora.io/projects">Agora Console</a></p>
                                                </div>
                                                <div class="col-sm">
                                                  <p class="join-info-text">User ID(optional)</p>
                                                  <input id="uid" type="text" onkeyup="this.value=this.value.replace(/[^0-9]/g,'')" onafterpaste="this.value=this.value.replace(/[^0-9]/g,'')" placeholder="Enter the user ID">
                                                </div>
                                              </div>
                                            </div> -->


                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                              <p id="local-player-name" class="player-name"></p>
                              <div id="local-player" class="player"></div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                              <!-- <div   >

                              </div> -->
                              <div id="remote-playerlist"  style="background-color: black; height: 450px; width: 350px;"   ></div>
                            </div>


                          </div>

                          <!-- <div class="row video-group">
                                                <div class="col">
                                                  <p id="local-player-name" class="player-name"></p>
                                                  <div id="local-player" class="player"></div>
                                                </div>
                                                <div class="w-100"></div>
                                                <div class="col">
                                                  <div id="remote-playerlist"></div>
                                                </div>
                                              </div> -->





                          <!-- ------------------------ -->
                        </form>
                      </div>
                    </div>
                    <!--
                                              This local media splayer is enabled when the user has successfully joined a Video Call channel.
                                            -->
                  </div>
                  <div class="button-group">
                    <!-- <button id="videoMute">Video</button> -->
                    <!-- <button id="audioMute">Audio</button> -->
                    <!-- <button id="join" type="button" class="btn btn-primary btn-sm">Join</button>
                    <button id="leave" type="button" class="btn btn-primary btn-sm" disabled>Leave</button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom_bdr">
            <div class="row marg-0">
              <div class="padd-l-60 padd-t-48 font_clr_white"> {{username}}</div>
              <!-- <div class="padd-l-34 padd-t-48 font_clr_white">Lic No</div> -->
<!-- ------------------------------------------------------------------------------------- -->

<div style="background-color:#fff; height: 30px; width: 50px; padding: 1px 0px 0px 8px;;border-radius: 3px; margin-left: 50px;" class="padd-t-48 "  >

  <button id="videoMute" style="border: none; background-color: #fff; outline: none; "   >


    <img id="myDIV" src="assets/img/Icon/video.png" class=""  style="height: 22px; width: 22px; "  alt="">
<img  id="myDIV1" style="display: none; height: 22px; width: 22px;" src="assets/img/Icon/video-off.png" alt="">

  </button>

<!-- <div id="myDIV1" style="display: none;" >
  jjj
</div> -->
</div>




<!-- <div style="background-color:#fff; height: 30px; width: 50px; padding: 2px 0px 0px 15px;border-radius: 3px; margin-left: 16px;" class="padd-t-48 "  >

 <img src="assets/img/Icon/mic.png" class=""  style="height: 20px; width: 20px; "  alt="">

</div> -->


<div style="background-color:#fff; height: 30px; width: 50px; padding: 1px 0px 0px 8px;;border-radius: 3px; margin-left: 16px;outline: none; " class="padd-t-48 "  >

  <button id="audioMute" style="border: none; background-color: #fff; ">
     <img id="mic1" src="assets/img/Icon/mic.png" class=""  style="height: 22px; width: 22px; "  alt="">
    <img id="mic2" src="assets/img/Icon/mic-off.png" style="height: 22px; width: 22px; display: none; "   alt="">

    </button>


</div>










              <div class=" padd-t-45"   style="margin-top: 32px; padding-left: 50px; "  >
                <button id="join" type="button" class="start_whiteBtn"  >Start</button>
              </div>

              <div class=" padd-t-45"   style="margin-top: 32px; padding-left: 20px; "  >
                <button id="leave" type="button"   class="start_whiteBtn"   data-toggle="modal"
                data-target="#comment_delete"  >End Call</button>
              </div>





            </div>

          </div>

        </div>



        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0  ">

          <div class="small_card padd-48-34">
            <div> <span class="fsize21 font_clr_green">Practise tips:</span>
              <span class="fsize21 float_right font_clr_green">Update: {{ sessionDate }} </span>
            </div>
            <div class="marg-t-20 font_clr_6a7387">
              <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus
                            venenatis, lectus magna fringilla urna, porttitor -->
              <textarea class="textAreaInput" id="textAreaPractice"></textarea>
            </div>

            <div class="row marg-0 marg-t-30">
              <div class="">
                <button class="save_btn w-135" (click)="saveGoals()"   >Save</button>
              </div>
              <!-- <div class="marg-l-20">
                                <button class="share_btn">Share</button>
                            </div> -->
            </div>
          </div>

          <div class="marg-t-18">
            <div class="small_card padd-48-34">
              <div> <span class="fsize21 font_clr_green">Notes:</span>
                <span class="fsize21 float_right font_clr_green">Update: {{ sessionDate }}</span>
              </div>
              <div class="marg-t-20 font_clr_6a7387">
                <textarea class="textAreaInput" id="textAreaId"></textarea>
              </div>

              <div class=" marg-t-30">
                <div class="">
                  <button class="save_btn w-190" (click)="saveNotice()" >Save</button>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- ---------------------------------------- -->


    <div class="modal fade" id="comment_delete" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="height: 200px !important;">
            <div class="modal-body">
                <div class="fsize18 font_bold_gilroy" style="color:#000;font-weight:600; margin-top: 18px; "> Confirmation ! </div>
                <!-- <div class="fsize16 marg-t-16" style="padding: 25px 0px;color:#000;font-weight:500"> End Call  Status -->
                <!-- </div> -->
            </div>
            <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
              <div style="padding: 25px 0px;color:#000000;font-weight:500">
                <span style="color:#000000;font-weight:600"  >Status</span>:
                  <input type="radio" id="html" [(ngModel)]="endcallstatus" name="fav_language" value="Noshow">
                <span style="margin-left:8px;">No Show</span>

                  <input type="radio" id="css" name="fav_language" [(ngModel)]="endcallstatus" value="Completed">
                <span style="margin-left:8px;">Completed</span>

              </div>



                <!-- <button type="button" class="btn_submit_cancel bgr_clr_red"
                    data-dismiss="modal" >No Show</button>
                <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue" style="margin-left: 20px;"
                    data-dismiss="modal">Completed</button> -->
            </div>
            <div class="row justify-content-center"  >
              <button class="btn_submit_cancel bgr_clr_red" (click)="endCallSession()" >Confirm </button>

            </div>
        </div>
    </div>
    </div>


    <!-- --------------------------------------------- -->

  </div>

  <div class="text-center marg-t-70">
    <button type="button" class="done_btn" (click)="done_Btn()"  >Done</button>

  </div>

<!-- ================================================================================ -->


<button class="open-button" (click)="openForm()">Chat</button>

<div class="chat-popup" id="myForm" style="min-width: 480px;background-color:#f4f1f1;">
  <!-- <form action="/action_page.php" class="form-container"> -->
    <h1 class="text-center">Chat</h1>


    <!-- ------------------------Start---------------------------- -->
    <div   class="header pb-2 bgr_clr no-select" style="background-color:#f4f1f1;" >
      <div class="container-fluid">
        <div class="header-body">

          <p class="roster_p pl-4 font_family_gilroy text-center">Message Center</p>
          <div class="row ">
            <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-3"></div> -->
            <div class="mt-3">
              <div class="row justify-content-center">
                <div    class="message_div" id="scroll-to-bottom">

    <div class="content">




                  <div class="" style="height: 290px; overflow-y:scroll;" >

                    <!-- <div class="user " *ngFor="let item of receiverMsg" class="text-right user">
                      <span class="message_cls txt_msg marg-l-10 font_family_gilroy" >{{usernames}}: <span style="margin-left: 8px;">{{item.message}}</span> </span>
                    </div> -->
                    <ng-container>
                      <div *ngFor="let item of getListData" >
                      <div class="user " *ngIf = "item.sender_id == userid" class=" user" style=" width: 300px; margin-left: 20rem;"  >

                        <div class="b">
                          <span style="color:#fff;" class="message_cls txt_msg  font_family_gilroy">{{usernames}}:   <span
                            >{{item.message}}</span>  </span>
                            <!-- <label for="">Date:{{item.date}}</label> -->

                        </div>
                        <div style="margin-bottom:40px;width:300px; margin-top: 4px;">
                          <!-- <span class="fsize12  font_family_gilroy" style="float:right;" >{{item.date}}</span> -->
                          <span class="fsize12  font_family_gilroy" style="float:right;">{{item.time}}</span>

                        </div>


                      </div>



                        <!-- <div  style="margin-bottom: 40px;" *ngIf = "item.sender_id == getpeerid"   >
                          <span class="message_cls_from txt_msg marg-l-10 font_family_gilroy"> {{clientUsrName}}: <span
                              style="margin-left: 8px;">{{item.message}}</span>  </span>
                        </div> -->

                        <div class="user "    *ngIf = "item.sender_id == getpeerid" class=" user" style=" width: 300px; margin-bottom: 40px; "  >

                          <div class="a">
                            <span class="message_cls_from txt_msg  font_family_gilroy"> {{clientUsrName}}: <span
                              >{{item.message}}  </span>  </span>

                          </div>

                          <div style="margin-bottom:40px;width:300px; margin-top: 4px;">
                            <!-- <span class="fsize12  font_family_gilroy" style="float:right;" >{{item.date}}</span> -->
                            <span class="fsize12  font_family_gilroy" style="float:right;">{{item.time}}</span>

                          </div>

                     </div>

      </div>






                      <div *ngFor="let item of messageArray">

                        <!-- <div class="text-right user" *ngIf="item.peerId=='user'">


                          <span class="message_cls txt_msg marg-l-10 font_family_gilroy">{{usernames}}: <span
                              style="margin-left: 8px;">{{item.text.text}}</span> </span>
                        </div> -->


                        <div

                        *ngIf="item.peerId=='user'" class=" user" style=" width: 300px; margin-left: 20rem;"  >

                          <div class="b">
                       <span style="color:#fff;" class="message_cls txt_msg  font_family_gilroy">{{usernames}}: <span
                              style="margin-left: 8px;">{{item.text.text}}</span> </span>
                          </div>

                          <div style="margin-bottom:40px;width:300px; margin-top: 4px;">
                            <!-- <span class="fsize12  font_family_gilroy" style="float:right;" >{{item.date}}</span> -->
                            <span class="fsize12  font_family_gilroy" style="float:right;">{{item.time}}</span>

                          </div>
                        </div>





                        <!-- <div class="user" *ngIf="item.peerId=='client'">



                          <span class="message_cls_from txt_msg marg-l-10 font_family_gilroy">{{clientUsrName}}: <span
                              style="margin-left: 8px;">{{item.text.text}}</span> </span>
                        </div> -->



                        <div   *ngIf="item.peerId=='client'" class=" user" style=" width: 300px; "  >

                          <div class="b">
                            <span style="color:#fff;" class="message_cls_from txt_msg marg-l-10 font_family_gilroy">{{clientUsrName}}: <span
                              style="margin-left: 8px;">{{item.text.text}}</span> </span>
                        </div>
                        <div style="margin-bottom:40px;width:300px; margin-top: 4px;">
                          <!-- <span class="fsize12  font_family_gilroy" style="float:right;" >{{item.date}}</span> -->
                          <span class="fsize12  font_family_gilroy" style="float:right;">{{item.time}}</span>

                        </div>

                        </div>





                      </div>






                    </ng-container>
                    <!--<div class="marg-t-10">
                      <p class="message_cls_from text_messages font_family_gilroy">May i help you?</p>
                    </div>-->

                  </div>

                  <div class="marg-t-30 marg-b-30 padd-0-30" style="margin-left:40px;">
                    <input type="hidden" name="peerId" id="peerId" class="form-control" [(ngModel)]="peerId">
                    <div class="row marg-0-30 msg_input">
                      <input type="text" class="bdr_none_input w-440"  id="messageText" [(ngModel)]="messageText"
                        placeholder="Type a message...">
                      <div class="send_btn marg-t-3" style="cursor:pointer"  (click)="sendMessage(messageText,peerId)">
                        <img height="20px" width="20px" src="assets/img/Icon/Send.png" alt="" >
                      </div>
                    </div>
                  </div>
                  <!--
                <div class="marg-t-30 padd-0-30">

                  <div class="row marg-0-30 msg_input">
                    <input type="text" class="bdr_none_input w-440" placeholder="Type a message..." >
                    <div class="send_btn marg-t-3">
                      <img height="20px" width="20px" src="assets/img/Icon/Send.png" alt="">
                    </div>
                  </div>

                </div> -->


              </div>


                </div>
              </div>
              <!-- <div class="row justify-content-center padd-t-102">
                <button type="button" class="btn done_btn" (click)="back()">Done</button>
              </div> -->
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-3"></div>
            </div>
          </div>
        </div>
      </div>
    <!-- ----------------------scroll bar-------------------------------------- -->

      <!--<div class="container-fluid ml-4">

      <div class="row">
        <div class="messageerror"><div *ngIf="error" class="alertred alertbox1">
                                  {{error}}
                  </div> <div *ngIf="msg" class="alertgreen alertbox2">
                                  {{msg}}
                  </div></div>
      </div>
      <br>
      <div class="row">
        <div class="col-lg-3">

        </div>
          <div class="col-lg-7">

              <div class="form-group">
                  <label for="username">Username</label>
                  <input type="text" [(ngModel)]="user" class="form-control" id="user" placeholder="username">

              </div>
             <div class="form-group">
                  <label for="token">RTM token</label>
                  <input type="text" [(ngModel)]="token" class="form-control" id="token" placeholder="token">

              </div>
              <div class="col-sm-2">
                      <button type="button" class="btn btn-success pull-right" (click)="login()">login</button>
                      <button type="button" class="btn btn-success pull-right" (click)="logout()">logout</button>
              </div>

        <div *ngIf="msg==='Login Success'">
          <br>
     <div class="row">

          <div class="col-lg-7 col-sm-7">
              <label for="PeerId">Peer Id</label>
              <input type="text" name="peerId" id="peerId"
               class="form-control" [(ngModel)]="peerId">


          </div>

          <div class="col-lg-7 col-sm-7">
          <div class="row">
            <div class="col-sm-10">
            <label for="Messages">Message</label>
                  <input type="text" class="form-control" [(ngModel)]="messageText">
                      <button type="button" class="btn btn-success pull-right" (click)="sendMessage(messageText,peerId)">Send</button>
              </div>
          </div>
          </div>
          <div class="col-lg-7 col-sm-7">

                  <input type="file" class="file-input" id="fileinput" [(ngModel)]="fileText" (change)="selectImage($event)" >
                    [(ngModel)]="fileimg"
                 <img [src]="imageSource || 'static path'" alt="My Profile Image"/>
                      <button type="button" class="btn btn-success pull-right" (click)="sendimage(peerId)">Send Image</button>
              </div>
          </div>
          </div>

      </div>

    </div>



      <div class="row">
          <div class="well" style="height:200px; padding:15px;">
              <div *ngFor="let item of messageArray">

              <h4 *ngIf="item.type=='text'">{{item.peerId}}:{{item.text.text}}</h4>
              <div *ngIf="item.type=='image'">
              <h4>{{item.peerId}}:<img width="200"  src="{{item.text}}" id="output"></h4>
              </div>
              </div>

          </div>
    <div id="container" style="width:350px;height:250px">
    <canvas id="CANVAS" width=300 height=300></canvas>
    </div>
    </div>
    </div>-->

    <!-- -------- -->

    <div>
    </div>

  <div style="display:flex;justify-content: center;  ">
    <button style="min-width: 100px;background-color: #FD1C70; color: #fff;" type="button" class="btn cancel" (click)="closeForm()">Close</button>
  </div>

  </div>

    <!-- ---------------------End-------------------------------------- -->
       <!-- <button type="submit" class="btn">Send</button> -->

  <!-- </form> -->
</div>














