import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Location } from '@angular/common';

declare var swal: any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  isShown: boolean = false;
  registerForm: FormGroup;
  submitted: boolean = false;

  Email: any;
  Key: any;



  constructor(private router: Router, private location: Location, private ApiService: ApiService, private fb: FormBuilder, private activatedRoute: ActivatedRoute) { }
  forgotpassword_url = '/forgotpassword-otp';

  ngOnInit(): void {
    // if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
    //   window.location.href='#/forgotmailpassword'
    // }
    var aValue = localStorage.getItem('otp');
    console.log('hhhhhhh',aValue);

    this.registerForm = this.fb.group(
      {
        email: ["", Validators.required],

      },
    );

  }

  async onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    var postapi = "/forgot_password_coach";
    var email = $("#email").val();
    localStorage.setItem('currentUser', JSON.stringify(email));
    var signupdata = {
      'email': email,

    };

    (await this.ApiService.postApi(postapi, signupdata)).subscribe(
      res => {
        localStorage.setItem('token', '');
        localStorage.setItem('otp', res.data);
        if (res) {
          // this.router.navigate([this.forgotpassword_url]);
         
          if (res.status === 200) {
            localStorage.setItem('email',res.email);
            localStorage.setItem('key',res.key);
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Mail sent successfully',
            })
            .then(() => {
              this.router.navigate(['/login']);
            });

          }
          else if (res.status == 404) {
            swal.fire({
              icon: 'error',
              title: 'error',
              text: 'Please enter the registered email',
            })

          }
        }
      });

  }
}
