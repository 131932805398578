<!-- @author:Rajapandi k
      Date : 23/12/2021 -->
<div class="header pb-8 bgr_clr_f5fcff ">

<div *ngIf = "showLoaderDiv"  >

    <div id="pause" class="d-flex align-items-center justify-content-center">
        <div id="spinner"></div>
    </div>

</div>
  
 <div class="padd-l-30 padd-t-20">
    <ol class="breadCrumb_flex fsize24">
        <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/dashboard']" >Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Happiness Community</li>
      </ol>
 </div>

    <div class="container-fluid">
        <div class="header-body">

            <div class="text-center fsize30 font_clr_green marg-t-76">
                A safe space to share your thoughts with a community of wellness seekers
            </div>

            <div class="marg-t-70">

            <div class="row  marg-2rem"  >
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 " *ngFor="let item of getCommunity_ListApi" style="margin-bottom: 20px;">

                    <div class="text-center"> 
                        <div >
                            <div>
                                <img class="h-250 w-250"  [src]="item.image " />
                            </div>
                      
                         </div>
                    </div>
                   
                    <div class="text-center marg-t-28 font_clr_green fsize26">{{item.topic_name}}</div>

                    <div class="text-center marg-t-28 "><button class="community_btn" (click)= "join_community(item)"> 
                        <!-- <span>{{item.joined === '1' ? 'Join the Community' : 'Joined'  }}</span> -->
                        <span *ngIf="item.joined === 1"> Joined </span>
                        <span *ngIf="item.joined === 0"> Join the Community </span>
     </button></div>
                </div>

                <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 ">
                    <div class="text-center">  <img class="h-250 w-250" src="assets/img/Img/img2.png" alt=""></div>
                    <div class="text-center marg-t-28 font_clr_green fsize26">Depression</div>
                    <div class="text-center marg-t-28 "><button class="community_btn" (click)= "join_community()">Join the Community</button></div>
                </div> -->

                <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 ">

                    <div class="text-center">  <img class="h-250 w-250" src="assets/img/Img/img3.png" alt=""></div>
                    <div class="text-center marg-t-28 font_clr_green fsize26">Economic Hardship</div>
                    <div class="text-center marg-t-28 "><button class="community_btn" (click)= "join_community()">Join the Community</button></div>
                </div> -->
<!-- 
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12  ">

                    <div class="text-center"  > <img class="h-250 w-250" src="assets/img/Img/img4.png" alt=""></div>
                    <div class="text-center marg-t-28 font_clr_green fsize26">Toxic Relationship</div>
                    <div class="text-center marg-t-28 "><button class="community_btn" (click)= "join_community()">Join the Community</button></div>
                </div> -->


   </div>

  </div>
            <!-- <div class="text-center marg-t-60">
                    <button class="done_btn" >Done</button>
                </div> -->

        </div>
    </div>
</div>