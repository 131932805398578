<app-navbar></app-navbar>


<div class="header pb-8  ">
  <div class="container-fluid">
    <div class="header-body">
      <!-- Card stats -->
      <div class="row ">
        <div class="col-2  mt-3">
          <div class="image_border text-center">
            <!-- <img class="img-fluid header_image"
              src="assets/img/Characters/happy.png"> -->
            <img class="img-fluid header_image imgFitSize" style="border-radius: 70px; height: 135px; width: 135px;"
              [src]="brainImageUrl">

          </div>
        </div>
        <div class="col-10 mt-3 ">
          <p class="page_heading pl-4">Welcome {{usernames}}</p>

        </div>
        <!-- ------------------- -->

        <!-- ------------ -->
      </div>


      <!-- <div class="padd-l-30 padd-t-20">
        <ol class="breadCrumb_flex fsize24">
          <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
              [routerLink]="['/dashboard']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Appointment List</li>
        </ol>

      </div> -->
      <div class="row mb-3 justify-content-between padd-t-20">
        <div>
          <ol class="breadCrumb_flex fsize24">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                [routerLink]="['/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Appointment History</li>
          </ol>
        </div>
        <!-- <div class="marg-r-20">
          <button class="appointmentBttn">Appointment History</button>
        </div> -->

      </div>




      <div class="row">
        <div class="col-lg-12 ">
          <div class="card table_card grid-appointment-list">
            <table id="myTable" class="row-border hover dataTable no-footer">
              <thead>
                <tr style="width: 100%;
                text-align: center;">
                  <th>S.No</th>
                  <th>App Number</th>
                  <!-- <th>Appointment Type</th> -->
                  <th>Name</th>
                  <th style="width:70px;">Detail</th>
                  <!-- <th>Type</th> -->
                  <th>Date</th>
                  <th>Time</th>
                  <th style="width:90px;">Duration</th>
                  <th>Booked Date</th>
                  <th>Status</th>
                  <!-- <th  >Action</th> -->
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of dummyarr; let i = index" style="    width: 100%;
                text-align: center;">
                  <td>{{i + 1}}</td>
                  <td>{{item.app_number}}</td>
                  <!-- <td>{{item.appointment_type}}</td> -->
                  <td>{{item.thusr_name}}</td>
                  <td>{{item.message}}</td>
                  <!-- <td class="text-center">{{item.user_type}}</td> -->
                  <td>{{item.date}}</td>
                  <td>{{item.time}}</td>
                  <td class="text-center">{{item.duration}}</td>
                  <td>{{(item.reschedule_datetime)?item.reschedule_datetime:item.booked_datetime}}</td>
                  <td
                    [ngStyle]="{'color': (item.appointment_status == 'Completed') ? 'green' : ((item.appointment_status == 'Noshow') ? 'orange' : 'blue') }">
                    {{item.appointment_status}}</td>
                  <!-- <td class="appoint-bttn">

                    <a ng-href="/happiness-training-session" class="btn session" [channelid]="item.app_number"
                      (change)="show_more_menu(event)" (click)="session(item)">Start Session</a>

                    <button type="button" class="btn message" (click)="message(item)">Message</button>
                    <button
                      type="button" class="btn cancel_st" data-toggle="modal" data-target="#comment_delete"
                      (click)="cancelAppointment(item)">Cancel</button>
                  </td> -->

                </tr>
                <!--   -->
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <!-- -------------------Alert Pop up-------------------------------------------- -->


      <div class="modal fade" id="comment_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="height: 200px !important;">
            <div class="modal-body">
              <div class="fsize18 font_bold_gilroy"> Confirmation ! </div>
              <div class="fsize16 marg-t-16" style="padding: 25px 0px;"> Are you want to cancel the session
              </div>
            </div>
            <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
              <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                (click)="cancelAppointmentDeleteApi()">Delete</button>
              <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue" style="margin-left: 20px;"
                data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>



      <!-- ----------------------- -->
    </div>
  </div>
</div>