<div class="header pb-8 bgr_clr_f5fcff ">
    <div class="container-fluid">


      <div class="marg-t-18">
   
        <ol class="breadCrumb_flex fsize24">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Notification</li>
        </ol>
   
    </div>


      <div class="header-body">
        <div class="row marg-t-30">


                 
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12  ">
            <div class="padd-l-70 marg-t-60">
  
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12  ">
  
            <div>
              <table>
                <tr *ngFor="let item of listNotification | paginate: { itemsPerPage: 3, currentPage: page }" >
                  <td class="">

                    <div class="marg-t-18 border_input">
                      <div class="padd-t-30 padd_20 fsize18 font_clr_1c1d20">Message:{{item.message}}</div>
                      <div class="marg-t-10  padd_20 font_clr_date fsize18">Date:{{item.date}}</div>
                    </div>
       
                  </td>
                </tr>
              </table>
            </div>


            <div style="margin-top:30px;">
              <pagination-controls (pageChange)="handlePageChange($event)" previousLabel="Previous" nextLabel="Next"></pagination-controls>
            </div>
       
  
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12  ">
  
          </div>
  

        </div>
      </div>
    </div>
</div>
