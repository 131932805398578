import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import * as moment from 'moment';
declare var $;
declare var swal: any;
@Component({
  selector: 'app-availability-calender',
  templateUrl: './availability-calender.component.html',
  styleUrls: ['./availability-calender.component.css']
})
export class AvailabilityCalenderComponent implements OnInit {
  isShown: boolean = false;
  // selected: Date | null;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  constructor(calendar: NgbCalendar, private router: Router, private ApiService: ApiService) {
    //  var dateVal :any;
    // dateVal = {year: '2023', month: '6', day: '19'}
    //  this.fromDate = dateVal;
    // console.log(this.fromDate,'this.fromDate....>');
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd',10);
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
        console.log(this.toDate,'this.fromDate....>');
  }

  availabilty_url = '/availibility';
  myDateValue: any;
  ngOnInit(): void {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }
    this.myDateValue = new Date("12-08-2019");
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      var singledate = moment(this.fromDate).format('YYYY-MM-DD');
      console.log("this.fromDate = date;", singledate);

    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      var singletodate = moment(this.toDate).format('YYYY-MM-DD');
      localStorage.setItem("toDates", singletodate);
      console.log("this.toDate = date;", singletodate);

    } else {
      this.toDate = null;
      this.fromDate = date;
      var tosingledate = moment(this.fromDate).format('YYYY-MM-DD');
      console.log("this.fromDate = date;", tosingledate);
      localStorage.setItem("fromdates", tosingledate);
      // localStorage.setItem('dd',this.fromDate)
      // sessionStorage.setItem("key", this.fromDate);
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }


  update_availability() {
    this.getCheckAvailability()
    // this.router.navigate([this.availabilty_url]);
  }

  async getCheckAvailability() {
    var getapi = "/check_availability";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var checkStatus = res.status;
      console.log(checkStatus, '');
      if (checkStatus == 0) {
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Looks like it’s time to update your schedule for the next two weeks! Click on update availability and get started!',
        })
        this.router.navigate([this.availabilty_url]);
      }
      else if (checkStatus > 0) {
        this.router.navigate([this.availabilty_url]);
      }

    });
  }

}
