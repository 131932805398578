import { Component } from "@angular/core";
import { Router } from "@angular/router";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Coach App";

  constructor(private Route: Router) {
    // if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
    //   window.location.href='#/'
    // }
  }
  async ngOnInit() {
    // if (
    //   !localStorage.getItem("authtoken") &&
    //   window.location.hash != "#/login"
    // ) {
    //   window.location.href = "#/";
    // }

    // this.settingtime();


    // var autoLogoutTimer;
    // var count = 0;
    // resetTimer();
    // $(document).on(
    //   "mouseover mousedown touchstart click keydown mousewheel DDMouseScroll wheel scroll",
    //   document,
    //   function (e) {
    //     resetTimer();
    //   }
    // );
    // function resetTimer() {
    //   count = 0;
    //   clearInterval(autoLogoutTimer);
    //   autoLogoutTimer = setInterval(idleLogout, 300000 );
    // }
    // function idleLogout() {
    //   // if (window.location.hash != "#/happiness-training-session") {
    //   //   count += 1;
    //   //   // console.log('yes',count);
    //   var inactivityTimeout = 300000 ;

    //   if (++count >= inactivityTimeout) {
    //     localStorage.clear();
    //     count = 0;
    //     window.location.href = "#/login";
    //   }
    //   // }
    // }


    var idleTimer;
    var idleDuration = 90 * 60 * 1000; 
    var redirectUrl = '/#login'

    resetTimer();

    // $(document).on("mouseover mousedown touchstart click keydown mousewheel DDMouseScroll wheel scroll", document, function (e) {
    //   resetTimer();
    // });

    $(document).on("mousemove", resetTimer);
    $(document).on("keydown", resetTimer);


    function resetTimer() {
      clearInterval(idleTimer);
      idleTimer = setInterval(resetIdleTimer,idleDuration);
    }

    // function idleLogout() {
    //   var inactivityTimeout = 300000;

    //   if (++count >= inactivityTimeout) {
    //     localStorage.clear();
    //     count = 0;
    //     window.location.href = "#/login";
    //   }
    // }

    function resetIdleTimer() {
      clearTimeout(idleTimer);
      // idleTimer = setTimeout(function () {
        // Perform the redirect when the user is idle
        localStorage.clear();
        
        alert('Session Expired')

        window.location.href = redirectUrl;
      // }, idleDuration);
    }


  }

}
