import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import * as moment from 'moment';
@Component({
  selector: 'app-roster-journal',
  templateUrl: './roster-journal.component.html',
  styleUrls: ['./roster-journal.component.css']
})
export class RosterJournalComponent implements OnInit {
  trainee_url= "/trainee-notes";
  usrName : any;
  journalMsg:any;
  journalDate:any;
  journal_Type:any;
  getJournalList = [];
  journalTypes:any;
  journalUserId:any;
  constructor(private router: Router,private ApiService: ApiService)  {}

  ngOnInit() {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }
    this.journalUserId =  localStorage.getItem('peerid');
    console.log(this.journalUserId,'this.journalUserId...');
this.getShareJournalListApi();



// peerid
  }

  async getShareJournalListApi() {

    var postApi = "/user_share_journal_list";
    var postjournalUserId = {
      "user_id": this.journalUserId
    };
   (await this.ApiService.postApi(postApi,postjournalUserId)).subscribe(
      res => {
              if (res) {
          if (res.status === 200) {

            this.getJournalList = res.data;
            for(let item of  this.getJournalList){
              this.usrName = item.thusr_name;
             var journal_Msg = item.thjournal_title;
             this.journalMsg   = journal_Msg;
            //  this.journalMsg   = "data:audio/mp3;base64,"+journal_Msg;

            var journal_date= item.thjournal_date;
            this.journalDate = moment(journal_date).format('DD-MM-YYYY');
            console.log(this.journalDate);
            this.journal_Type = item.thjournal_input_type;


            // this.journal_Type   = "data:audio/mp3;base64,"+ this.journalMsg;

            // console.log(this.journal_Type,'types');

            }

          }
        }
   });
  }

  playsounds(){
    if(this.journal_Type == "audio"){
      var snd = new Audio("data:audio/ogg;base64," + this.journalMsg );
  snd.play();
  console.log(snd,'audio')
    }

//   var audio = document.getElementById('my-audio')
// audio.play();
  }

  back(){
    this.router.navigate([this.trainee_url]);
  }

}
