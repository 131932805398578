<!-- Sidenav -->
<!--<app-sidebar></app-sidebar> class="main-content"-->
<div >
  <!-- Top navbar -->
  <app-navbar *ngIf="router != '/signup'"></app-navbar>
  <!-- Pages -->
  <router-outlet></router-outlet>
 <!--<div class="container-fluid">
    <app-footer></app-footer>
  </div>-->
</div>
 