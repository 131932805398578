<div class="header pb-8 bgr_clr_f5fcff ">
    <div class="container-fluid">
        <div class="header-body">

            <div class="">
                <div class="padd-l-30 padd-t-40 padd-b-20">
                    <ol class="breadCrumb_flex fsize24" *ngIf="joinIds == 1">
                        <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                                [routerLink]="['/happiness-community']">Back</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Happiness Community Comment</li>
                    </ol>
                    <ol class="breadCrumb_flex fsize24" *ngIf="joinIds == 0">
                        <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                                [routerLink]="['/happiness-community-notes']">Back</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Happiness Community Comment</li>
                    </ol>

                </div>
                <div class="row marg-2rem ">
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2   ">

                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 ">



                        <div style="padding-left: 48px;">
                            <!-- <div class=" cardNotes" style="margin-bottom: 32px;">
                                <div class="f-right"><img height="36" width="36"
                                        src="assets/img/Icon/Close - Purple.png" alt=""></div>
                                <div class="padd-26-43" style="padding-bottom: 15px;">

                                    <div class="fsize14 font_clr_6a7387 marg-t-20 ">
                                        xfbcfnmn
                                    </div>

                                    <div class="marg-t-32">
                                        <div class="row marg-0">

                                            <div>
                                                <input type="text" class="bdr_input">
                                            </div>

                                            <div class="marg-l-10">
                                                <button type="button" class="comment_btn" data-toggle="modal"
                                                    data-target="#exampleModal">
                                                    Comment</button>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="marg-t-12 fsize14"
                                        *ngFor="let item of getlistpostComments; let i = index">
                                        <span class="">
                                            {{item.comment}}
                                        </span>

                                        <span class="f-right">
                                            <span class="marg-r-10">
                                                <img class="h-12 w-13 cursor_Pointer" (click)="commentsLike(item)"
                                                    src="\assets\img\Icon\heart - active.png" alt="">
                                            </span>

                                            <span class="marg-r-10">
                                                <img class="h-12 w-13 cursor_Pointer" (click)="commentsDislike(item)"
                                                    src="\assets\img\Icon\dislike - gray.png" alt="">
                                            </span>
                                            <span class="fsize10">

                                                <span (click)="removeComments(item)"
                                                    class="cursor_Pointer">Delete</span>
                                            </span>

                                        </span>

                                    </div>
                                </div>
                            </div> -->

                            <div>
                                <table>
                                    <tr *ngFor="let item of getlistposts | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize "
                                        style="margin-bottom: 32px;">

                                        <td>
                                            <div class=" cardNotes" style="margin-bottom: 20px;">
                                                <!-- *ngIf="showDeleteCircleIcon"  this.loginUserId   -->
                                                <div class="f-right" *ngIf="item.user_id === loginUserId"><img
                                                        height="36" width="36" src="assets/img/Icon/Close - Purple.png"
                                                        alt="" data-toggle="modal" data-target="#post_delete"
                                                        (click)="deletePostApi(item)"></div>
                                                <div class="padd-26-43" style="padding-bottom: 15px;">
                                                    <!-- 
                                                    <div>
                                                        <span class="fsize14 font_clr_6a7387">Name :
                                                            <span style="margin-left: 4px;" >{{item.user_name}}</span>
                                                            </span>
                                                    </div> -->

                                                    <div>
                                                        <span class="fsize14 font_clr_6a7387">Post
                                                            Date : <span
                                                                style="margin-left: 4px;">{{item.post_date}}</span>
                                                        </span>

                                                    </div>
                                                    <div>
                                                        <span class="fsize14 font_clr_6a7387">Post Time :
                                                            <span style="margin-left: 4px;">{{item.post_time}}</span>
                                                        </span>

                                                    </div>

                                                    <div class=" fsize20 font_clr_6a7387 marg-t-20 ">

                                                        <!-- <p   style="border: none; outline: none; overflow: hidden;min-height: 100px;"> -->
                                                        <!-- </p>  -->

                                                        <div id="wrapper" [innerHTML]="item.post">

                                                            <!-- {{item.post}} -->

                                                        </div>
                                                        <!-- <div [innerHTML]="item.post"></div> -->

                                                    </div>


                                                    <div class="marg-t-20">
                                                        <div class="row marg-0">

                                                            <div>
                                                                <input type="text" data-toggle="modal"
                                                                    style="background-color: #fff; padding-left: 15px;"
                                                                    class="bdr_input" [(ngModel)]="item.postComments"
                                                                    (ngModelChange)="onChange($event, item)">
                                                                <!-- (click)="comment_postid(item)" data-target="#exampleModal"   -->
                                                            </div>

                                                            <div class="marg-l-10">
                                                                <button type="button" class="comment_btn"
                                                                    (click)="comment_postid(item)">
                                                                    Comment</button>
                                                                <!-- data-toggle="modal"data-target="#exampleModal" -->
                                                            </div>

                                                        </div>
                                                    </div>



                                                    <!-- <div class="marg-t-12 fsize14" *ngFor="let item of getlistposts; let i = index"> -->
                                                    <!-- 

                                                    <div *ngFor="let items of item.comments; ">

                                                        <div>
                                                            <span class="fsize14 font_clr_6a7387">Name:
                                                                {{items.comment_user_name}}</span>
                                                        </div>

                                                        <div>

                                                            <span class="fsize14 font_clr_6a7387">Post
                                                                Date:{{items.comment_date}}</span>

                                                        </div>
                                                        <div>
                                                            <span class="fsize14 font_clr_6a7387">Post Time
                                                                :{{items.comment_time}}</span>

                                                        </div>

                                                    </div> -->

                                                    <div *ngFor="let items of item.comments; let i = index"
                                                        class="marg-t-10">


                                                        <span class="fsize14 font_clr_6a7387">
                                                            {{items.comment}} <span>

                                                                <span *ngIf="items.user_id === loginUserId"
                                                                    style="margin-left: 6px;">By:
                                                                    {{items.comment_user_name}}</span>


                                                                <span style="margin-left: 4px;">Date: <span
                                                                        style="margin-left: 4px;">{{items.comment_date}}</span>
                                                                </span>
                                                            </span>

                                                        </span>

                                                        <span class="f-right">
                                                            <span class="marg-r-10">
                                                                <img class="h-12 w-13 cursor_Pointer"
                                                                    *ngIf="items.user_like === 1 "
                                                                    style="margin-top: 3px;"
                                                                    src="/assets/img/Icon/heart - active.png" alt="">


                                                                <img *ngIf="items.user_like === null || items.user_like === 2"
                                                                    style="margin-top: 3px;"
                                                                    class="h-12 w-13 cursor_Pointer"
                                                                    (click)="commentsLike(items)"
                                                                    src="/assets/img/Icon/heart - gray.png" alt="">

                                                            </span>

                                                            <span class="marg-r-10">
                                                                <img class="h-12 w-13 cursor_Pointer"
                                                                    *ngIf="items.user_like === null || items.user_like === 1"
                                                                    style="margin-top: 3px;"
                                                                    (click)="commentsDislike(items)"
                                                                    src="/assets/img/Icon/dislike - gray.png" alt="">


                                                                <img class="h-12 w-13 cursor_Pointer"
                                                                    *ngIf="items.user_like === 2 "
                                                                    style="margin-top: 3px;"
                                                                    src="/assets/img/Icon/dislike - active.png" alt="">

                                                            </span>
                                                            <span class="fsize10">

                                                                <span (click)="removeComments(items)"
                                                                    class="cursor_Pointer">
                                                                    <!-- Delete -->
                                                                    <img class="h-12 w-13 cursor_Pointer"
                                                                        data-toggle="modal"
                                                                        data-target="#comment_delete"
                                                                        src="/assets/img/Icon/Delete_icon.png" alt="">

                                                                </span>
                                                            </span>

                                                        </span>
                                                    </div>
                                                    <!-- </div> -->

                                                </div>
                                            </div>
                                        </td>








                                        <!-- ------------------------------------------- -->


                                    </tr>
                                </table>
                                <ngb-pagination [collectionSize]="getlistposts.length" [(page)]="page"
                                    [maxSize]=totalPostList [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                                </ngb-pagination>
                            </div>


                            <div class="marg-t-60">
                                <div class="row marg-0">
                                    <div class="marg-l-36">
                                        <button class="org_clr-btn" (click)="back()">Leave Conversation</button>
                                    </div>
                                    <div class="marg-l-20">
                                        <button class="org_clr-btn" data-toggle="modal"
                                            data-target="#exampleModalOne">Create a Post</button>
                                    </div>
                                </div>

                            </div>
                        </div>








                        <!-- <div *ngFor = "let item of getlistposts"  style="margin-bottom: 32px;">


                    </div> -->
                        <!-- ------------------------------------------------------------------ -->

                        <div>
                            <!-- ---------------Comment Delete Pop up ------------------------------------------------- -->

                            <div class="modal fade" id="comment_delete" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content" style="height: 200px !important;">
                                        <div class="modal-body">
                                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                                            <div class="fsize16 marg-t-16" style="padding: 25px 0px;"> Are you sure you
                                                want to delete this Comment?
                                            </div>
                                        </div>
                                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                                            <button type="button" class="btn_submit_cancel bgr_clr_red"
                                                (click)="removeCommentApi()" data-dismiss="modal">Delete</button>
                                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                                data-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- ---------------Post Delete Pop up ------------------------------------------------- -->

                            <div class="modal fade" id="post_delete" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content" style="height: 200px !important;">
                                        <div class="modal-body">
                                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                                            <div class="fsize16 marg-t-16" style="padding: 25px 0px;"> Are you sure you
                                                want to delete this Post?
                                            </div>
                                        </div>
                                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                                            <button type="button" class="btn_submit_cancel bgr_clr_red"
                                                (click)="removePostDeleteApi()" data-dismiss="modal">Delete</button>
                                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                                data-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- ------------------------------------------ -->
                            <!-- Button trigger modal -->
                            <!-- <button type="button" class="btn btn-primary" data-toggle="modal"
                                data-target="#exampleModal">
                                Launch demo modal
                            </button> -->

                            <!-- Modal -->
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">

                                        <div class="f-right"><img height="36" width="36" class="f-right"
                                                src="assets/img/Icon/Close - Purple.png" data-dismiss="modal" alt="">
                                        </div>
                                        <div class="modal-body">
                                            <div class="fsize18" style="padding: 6px;">
                                                Create Post Comment
                                            </div>
                                            <div class=" popup_bdr">
                                                <textarea
                                                    style="height:160px; width: 526px; padding-left: 15px; padding-top: 15px;"
                                                    class="font_clr_6a7387 fsize14" [(ngModel)]="postComments"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <!-- Lorem ipsum dolor sit amet, consectur
                                                    elit ut aliquam -->

                                                    </textarea>

                                            </div>
                                        </div>
                                        <div class="padd-25-84 ">
                                            <button type="button" class="popup_btn" data-dismiss="modal"
                                                (click)="CommunityPostSubmit()">Submit</button>
                                            <button type="button" class="popup_btn marg-l-20"
                                                data-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="exampleModalOne" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog " role="document">
                                    <div class="modal-content ">


                                        <div class="f-right"><img height="36" width="36" class="f-right"
                                                src="assets/img/Icon/Close - Purple.png" alt="" data-dismiss="modal">
                                        </div>

                                        <div class="modal-body">

                                            <div class="fsize18" style="padding: 6px;">
                                                Create Community Post
                                            </div>

                                            <div class=" popup_bdr">
                                                <textarea
                                                    style="height:160px; width: 526px; padding-left: 15px; padding-top: 15px;"
                                                    class="font_clr_6a7387 fsize14" [(ngModel)]="postData"
                                                    [ngModelOptions]="{standalone: true}">


                                                </textarea>


                                            </div>
                                        </div>
                                        <div class="padd-25-84 ">
                                            <button type="button" class="popup_btn" data-dismiss="modal"
                                                (click)="addPost_Createapi()">Submit</button>
                                            <button type="button" class="popup_btn marg-l-20"
                                                data-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2   ">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>