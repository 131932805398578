import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SignupComponent } from './signup/signup.component';
import { AppointmenthistoryComponent } from './appointmenthistory/appointmenthistory.component';
import { ForgotmailpasswordComponent } from './pages/reset-mail-password/reset-mail-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';


// import { SampleRedirectPageComponent } from './sample-redirect-page/sample-redirect-page.component'


const routes: Routes = [

  // {
  //   path: 'sample-redirect-page',
  //   component: SampleRedirectPageComponent
  // },

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },

  {
    path: 'user-reset-password',
    component: ResetPasswordComponent,
  },

  { path: 'reset-mail-password', component: ForgotmailpasswordComponent },


  {
    path: 'for',
    component: SignupComponent,
    // pathMatch: 'full',
  },

  {
    path: 'appointment/history',
    component: AppointmenthistoryComponent,
    // pathMatch: 'full',
  },




  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      }
    ]
  },


  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      }
    ]
  }, {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
