<!-- @author:Rajapandi k
      Date : 23/12/2021 -->
<div class="header pb-8 bgr_clr_f5fcff ">
    <div class="container-fluid">
        <div class="header-body">

            <div class="text-center fsize30 font_clr_red marg-t-76">
                Happiness Trainee: XYZ
            </div>
            <div class="text-center  font_clr_red fsize20 marg-t-14">
                Message Center

            </div>

            <div class="marg-t-60">
                <div class="row marg-2rem ">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12   ">

                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12  ">
                        <div class=" cardNotes ">
                            <div class="padd-t-42 padd-l-46 padd-r-42">

                                <div class="fsize21 font_clr_green">
                                    Date: 12/11/2021
                                </div>

                                <div class="fsize16 font_clr_6a7387 marg-t-20 text-right">

                                    <span>hi</span>
                                   
                                   <img class="imgIcon marg-l-8" src="..\assets\img\Img\av2.png" alt="">

                                </div>
                                <div class="fsize16 font_clr_6a7387 marg-t-20 ">
                                   

                                    <div class="row marg-0">
                                        <img class="imgIcon " src="..\assets\img\Img\av1.png" alt="">

                                        <div class="marg-l-8">
                                            hello
                                         </div>
                                         </div>
                                      
                                  
 
                                 </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12   ">
                    </div>

                </div>
            </div>

            <div class="text-center marg-t-60">
                <button class="done_btn">Done</button>
            </div>

        </div>
    </div>
</div>