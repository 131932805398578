import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
@Component({
  selector: 'app-happiness-community',
  templateUrl: './happiness-community.component.html',
  styleUrls: ['./happiness-community.component.css']
})
export class HappinessCommunityComponent implements OnInit {
  getCommunity_ListApi: any;
  trainee_url = "/happiness-community-notes";
  traineeComment_Url = "/happiness-community-comment"
  returnUrl = "/dashboard";
  imageUrl:any;
  showLoaderDiv = false;
  isShown: boolean = false;
  communityIds:any;
  joinedId:any;
  joine_Ids:any;

  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }

    this.getCommunityListApi();
  }

  join_community(items:any) {
    this.ApiService.community_Id = items.id;
    this.communityIds = items.id ;
    this.joine_Ids = items.joined;
    console.log( this.joine_Ids,'allItems');
    localStorage.setItem('joinCommunityId', items.joined);
    localStorage.setItem('communityTopicName', items.topic_name);
    // this.joinedId = items.joined;
    //   console.log(this.joinedId,'joinedId');
    this.joinCommunityApi();

  }
  async joinCommunityApi(){
  var postapi = "/join_community";
  var communityIdData = {
    "community_id": this.communityIds ,

  };
  // console.log(communityIdData,'communityIds');
  (await this.ApiService.postApi(postapi, communityIdData)).subscribe(
    res => {

      if (res) {
        if (res.status === 200) {
          // swal.fire({
          //   icon: 'success',
          //   title: 'Success',
          //   text: ' Add Post Comment Successfully',
          // })

       if(this.joine_Ids == 0){
        this.router.navigate([this.trainee_url]);

       }
       else if (this.joine_Ids == 1){
        this.router.navigate([this.traineeComment_Url]);

       }
}
  }
});
 }


  back() {
    this.router.navigate([this.returnUrl]);
  }

  async getCommunityListApi() {
    this.showLoaderDiv = true;
    var getapi = "/list_community";

    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res.data);
      this.getCommunity_ListApi = res.data;
      this.showLoaderDiv = false;
      for(let item of this.getCommunity_ListApi ){
        this.joinedId = item.joined;
        console.log( this.joinedId,'join')
      }

        console.log( this.getCommunity_ListApi,'urlimage')
   // this.imageUrl = this.getCommunity_ListApi.image;
      // console.log( this.getCommunity_ListApi.image,'urlimage')
        // for(let item of  this.getCommunity_ListApi){
        //      this.imageUrl = item.image;

        // }
    });

  }




}
