import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
@Component({
  selector: 'app-notification-module',
  templateUrl: './notification-module.component.html',
  styleUrls: ['./notification-module.component.css']
})
export class NotificationModuleComponent implements OnInit {
  trainee_url= "/notification";
  listNotification = [];
  notificationMsg:any;
  notificationDate:any;
  pages: any;
  page = 1;
  pageSize = 5;
  isShown: boolean = false;
  constructor(private router: Router , private ApiService: ApiService) { }

  ngOnInit() {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }
    this.getListNotificationApi();
  }

  async getListNotificationApi() {

    var postApi = "/list_notification";

    (await this.ApiService.postApi(postApi,'')).subscribe(
      res => {
  this.listNotification = res.data;
  // for(let item of  this.listNotification  ){
  //   this.notificationMsg = item .message;
  //   this.notificationDate = item.date;

  // }

   });
  }

  go_to_trainee(){
    this.router.navigate([this.trainee_url]);
  }
  handlePageChange(e: any) {
    // this.page = e;
    console.log(e);
    this.page = e;
  }

}
