<!-- @author:Rajapandi k
      Date : 23/12/2021 -->
<div class="header pb-8 bgr_clr_f5fcff ">
    <div class="container-fluid">
        <div class="header-body">
            <div class="padd-80-50">
                <div class="">
                    <!-- <nav aria-label="breadcrumb"> -->
                    <ol class="breadCrumb_flex fsize24">
                        <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                                [routerLink]="['/dashboard']">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Availability</li>
                    </ol>
                    <!-- </nav> -->
                </div>

                <div class=" cardNotes ">
                    <div class="">
                        <div class="row marg-0">
                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 ">

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                <div class="padd-t-90 row justify-content-center">
                                    <div class="calendar_bdr ">


                                        <div class="text_center padd-t-50"><img class="h-80 w-80 text_center"
                                                src="assets/img/Characters/happy.png"></div>

                                        <div class="font_clr_white font_family_gilroy text-center fsize30">
                                            Availability
                                        </div>
                                        <!-- <div class="font_clr_white_1 text-center fsize30 mt-5">
                                            <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" 
                                                class="calendar_bdr2" [(ngModel)]="fromDate" [displayMonths]="1"
                                                [dayTemplate]="t" outsideDays="hidden">
                                            </ngb-datepicker>

                                            <ng-template #t let-date let-focused="focused">
                                                <span class="custom-day" [class.focused]="focused"
                                                    [class.range]="isRange(date)"
                                                    [class.faded]="isHovered(date) || isInside(date)"
                                                    (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                                    {{ date.day }}
                                                </span>
                                            </ng-template>
                                        </div> -->
                                        
                                            <img class="calendar_image" src="assets/img/Calander/calender.png">
                                        
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 ">

                            </div>

                        </div>

                        <div class="marg-t-40 ">
                            <div class="text-center font_clr_red fsize28 ">

                                Welcome to the Happiness Project! Update your
                                <div> availability so patients can book
                                    sessions with you.</div>

                            </div>
                        </div>



                        <!-- -------------------------content------------------------------------------- -->
                        <div>
                            <div class="text-center marg-t-30 padd-b-90">
                                <button class="done_btn" (click)="update_availability()">Update availability</button>

                                <!-- <button class="pop_up marg-l-10 " type="button" data-toggle="modal"
                                    data-target="#exampleModal">Pop Up</button> -->

                            </div>

                        </div>

                        <!-- <div>
                                    <mat-card class="demo-inline-calendar-card">
                                        <mat-calendar [(selected)]="selected"></mat-calendar>
                                    </mat-card>
                                    <p>Selected date: {{selected}}</p>
                                </div> -->
                        <!-- ------------------------------------------------------------- -->

                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">

                                    <div class="f-right">

                                        <img height="36" width="36" class="f-right"
                                            src="assets/img/Icon/Close - Purple.png" alt="">


                                    </div>
                                    <div class="modal-body">
                                        <div class=" ">

                                            <div class="text-center">
                                                <img height="75" width="85" class="text-center"
                                                    src="/assets/img/Icon/warning.png" alt="">
                                            </div>

                                            <div class="text-center fsize27 font_clr_orng marg-t-30 font_family_gilroy">
                                                <div> Looks like it’s time to update your </div>

                                                <div> schedule for the next two weeks!
                                                    Click </div>

                                                <div> on update availability and get started!</div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>