<!-- @author:Rajapandi k
      Date : 23/12/2021 -->
<div class="header pb-8 bgr_clr_f5fcff ">
  <div class="container-fluid">
    <div class="header-body">
      <div class="padd-t-80">
        <div class="">
          <!-- <nav aria-label="breadcrumb"> -->
          <ol class="breadCrumb_flex fsize24">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                [routerLink]="['/dashboard']">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Availability </li>
          </ol>
          <!-- </nav> -->
        </div>
        <div class="row marg-2rem ">
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12  padd-0 ">

          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 padd-0 ">
            <div class=" cardNotes ">
              <div class="padd-36-74">
                <div class="fsize16 font_clr_6a7387 marg-t-20">
                  <div>
                    <span class="fsize23 font_family_bold font_family_Gilroy"> Availability
                      for:</span>
                    <span class="text-right  f-right" style="cursor: pointer;">
                      <span class="{{active_span_day}}"> <a class="fsize16 font_family_bold "
                          (click)="day_div();gettype('day')"> Day</a> </span>
                      <span class="marg-l-25 {{active_span_week}} "><a class="fsize16 font_family_bold "
                          (click)="week_div();gettype('week')">Week</a></span>
                      <span class="marg-l-25 {{active_span_month}}"> <a class="fsize16 font_family_bold"
                          (click)="month_div();gettype('month')">Month</a></span>
                    </span>
                  </div>
                  <div *ngIf="show_day_div" class="fsize13">
                    {{slotdate }}
                  </div>
                  <div *ngIf="show_week_div" class="fsize13">
                    {{slotdate }}
                  </div>

                  <div *ngIf="show_month_div" class="fsize13">
                    {{slotdate }}
                  </div>
                </div>
                <ng-container *ngFor="let availabiltyslot of getavailabilityresult; let ind = index">
                  <!-- -------------------------content------------------------------------------- -->
                  <div class="marg-t-32">
                    <div *ngIf="show_day_div">
                      <div class="row marg-0">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 "
                          *ngFor="let slotitem of availabiltyslot.time_slot; let i=index">
                          <div class="w-160 marg-b-20">

                            <div class="slotBookTime time_btn" [disabled]="slotitem.status== '2'"
                              [ngClass]="slotitem.status== '2'? 'Pass' : 'Fail'">

                              <span style="float:right;" id="{{slotitem.slot_id}}" *ngIf="slotitem.status!= '2'"
                                (click)="sweetdelete($event)">

                                <img height="24" width="24" src="assets/img/Icon/Close - Purple.png" alt=""
                                  id="{{slotitem.slot_id}}"> </span>
                              <div>

                                <div style="padding-left:8px;">

                                  <div>


                                    <button type="button" style=" outline: none; transition: unset;"
                                      class="btn tooltip-primary" data-toggle="tooltip" data-placement="top"
                                      title="{{slotitem.duration}}">

                                      <label style="font-size:18px;">
                                        <input *ngIf="slotitem.status!= '2'" type="checkbox" name="trades"
                                          style="margin-right:4px; font-size: 16px;"
                                          [(ngModel)]="slotitem.selected">{{slotitem.slot_start_time}}
                                      </label>

                                    </button>


                                    <!-- <span class="tooltip-danger"><a data-toggle="tooltip" data-container=".tooltip-danger" title="Some tooltip text!">Hover over me</a></span> -->



                                    <!-- -------------------------------------------------------- -->

                                    <!-- <a  data-toggle="tooltip" data-placement="bottom"
                                                                        title="" data-original-title="Tooltip on bottom"
                                                                        class="red-tooltip">Tooltip on bottom</a> -->





                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <!-- --------------------------------------- -->
                        <label class="btn btn-filter" style="font-size:18px;">
                          <input type="checkbox" name="checkboxes" style="margin-right: 8px; font-size: 18px;   "
                            (change)="toggleCheckboxAll($event ,availabiltyslot.time_slot, ind)" />
                          Select All
                        </label>
                        <label class="btn btn-filter" style="font-size:18px;">
                          <input type="button" name="checkboxes" style="font-size: 18px; height:18px; width:18px;"
                            data-toggle="modal" data-target="#comment_delete"
                            (click)="deleteSelectSlots($event, ind)" />
                          Delete Slot
                        </label>
                        <!-- ---------------------pop up-------------------- -->




                        <!-- <div *ngIf="dayDeleteSlot">
                                                        <button style="height: 40px;
                                                        margin-top: 8px;
                                                        width: 120px;border: 1px solid #CFD7E0;"

                                                        data-toggle="modal"
                                                            data-target="#comment_delete">Delete Slot</button>
                                                    </div> -->



                      </div>
                    </div>
                    <div *ngIf="show_week_div">
                      <!-- -------------------------------------------------Show Weak Div--------------------------------------------------------------- -->
                      <ng-container *ngFor="let slotitemfirst of availabiltyslot.time_slot;let first = first;">

                        <div *ngIf="first" class="fsize15 font_clr_orng marg-t-32">
                          {{slotitemfirst.slot_start_date }}

                        </div>
                      </ng-container>
                      <div class="marg-t-20">
                        <div class="row marg-0">
                          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 "
                            *ngFor="let slotitem of availabiltyslot.time_slot; let i = index">
                            <div class="w-160 marg-b-20">
                              <div class="slotBookTime time_btn" [ngClass]="slotitem.status== '2'? 'Pass' : 'Fail'">
                                <!--
                                                                    <button
                                                                        [ngClass]="slotitem.status== '2'? 'Pass1' : 'Fail1'"
                                                                        style="border: none;"> -->
                                <span style="float:right;" id="{{slotitem.slot_id}}" *ngIf="slotitem.status!= '2'"
                                  (click)="sweetdelete($event)">

                                  <img height="24" width="24" src="assets/img/Icon/Close - Purple.png" alt=""
                                    id="{{slotitem.slot_id}}"> </span>
                                <div>

                                  <div style="padding-left:8px; padding-top: 10px;">

                                    <div>

                                      <label style="font-size:18px;" (change)="singleCheckbox(slotitem , i)">
                                        <input *ngIf="slotitem.status!= '2'" type="checkbox" name="trades"
                                          style="margin-right:4px; font-size: 16px;" [(ngModel)]="slotitem.selected">

                                        {{slotitem.slot_start_time}}
                                      </label>
                                    </div>
                                  </div>

                                </div>
                                <!-- </button> -->
                              </div>

                            </div>


                            <!-- <div class="slotBookTime"
                                                            *ngIf = "i+1== availabiltyslot.time_slot.length"
                                                            style="padding-left:8px; padding-top: 10px; height:50px; padding-right: 8px;">
                                                            <label class="">
                                                                <input type="checkbox" name="checkboxes"
                                                                    style="margin-right:4px; font-size: 18px;"
                                                                    (change)="toggleCheckboxAll(slotitem)">Check
                                                                All
                                                            </label>
                                                </div> -->







                          </div>

                          <!-- -------show week all---- -->

                          <label class="btn btn-filter" style="font-size:18px;">
                            <input type="checkbox" name="checkboxes" style="margin-right:8px;"
                              (change)="toggleCheckboxAll($event ,availabiltyslot.time_slot, ind)" />Select
                            All
                          </label>

                          <label class="btn btn-filter" style="font-size:18px;">
                            <input type="button" name="checkboxes" style="font-size: 18px; height:18px; width:18px;"
                              data-toggle="modal" data-target="#comment_delete"
                              (click)="deleteSelectSlots($event, ind)" />
                            Delete Slot
                          </label>

                          <!-- <div *ngFor="let slotitem of availabiltyslot.time_slot">
                                                            <label class="btn btn-filter">
                                                              <input type="checkbox" name="trades" [checked]="slotitem.selected" (change)="singlecheckbox($event)"/>{{ checkbox.label }}
                                                            </label>
                                                          </div> -->



                        </div>
                      </div>


                    </div>

                  </div>

                  <!-- ------------------------------------------Month tab content----------------------------------------------------------- -->

                  <div class="" *ngIf="show_month_div">

                    <ng-container *ngFor="let slotitemfirst of availabiltyslot.time_slot;let first = first;">

                      <div *ngIf="first" class="fsize15 font_clr_orng marg-t-32">
                        {{slotitemfirst.slot_start_date}}

                      </div>
                    </ng-container>
                    <div class="marg-t-20">
                      <div class="row marg-0">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 padd-0 "
                          *ngFor="let slotitem of availabiltyslot.time_slot;">
                          <!-- <div class="w-160 marg-b-20">

                                                            <button class="time_btn" [disabled]="slotitem.status== '2'"
                                                                [ngClass]="slotitem.status== '2'? 'Pass' : 'Fail'">
                                                                {{slotitem.slot_start_time}}
                                                                <span id="{{slotitem.slot_id}}"
                                                                    *ngIf="slotitem.status!= '2'"
                                                                    (click)="sweetdelete($event)"><img height="24"
                                                                        width="24" src="assets/img/Icon/Close - Purple.png"
                                                                        alt="" id="{{slotitem.slot_id}}"> </span>
                                                            </button>

                                                        </div> -->


                          <!-- --------------------------------------------------------------------------- -->


                          <div class="w-160 marg-b-20">
                            <div class="slotBookTime time_btn" [ngClass]="slotitem.status== '2'? 'Pass' : 'Fail'">

                              <!-- <button [ngClass]="slotitem.status== '2'? 'Pass1' : 'Fail1'"
                                                                    style="border: none;"> -->
                              <span style="float:right;" id="{{slotitem.slot_id}}" *ngIf="slotitem.status!= '2'"
                                (click)="sweetdelete($event)">

                                <img height="24" width="24" src="assets/img/Icon/Close - Purple.png" alt=""
                                  id="{{slotitem.slot_id}}"> </span>
                              <div>

                                <div style="padding-left:8px; padding-top: 10px;">
                                  <div> <label style="font-size:18px;" (change)="singleCheckbox(slotitem , i)">
                                      <input *ngIf="slotitem.status!= '2'" type="checkbox" name="trades"
                                        style="margin-right:4px; font-size: 16px;" [(ngModel)]="slotitem.selected">

                                      {{slotitem.slot_start_time}}
                                    </label>
                                  </div>
                                </div>

                              </div>
                              <!-- </button> -->
                            </div>
                          </div>

                        </div>

                        <label class="btn btn-filter" style="font-size:18px;">
                          <input type="checkbox" name="checkboxes" style="margin-right:8px;"
                            (change)="toggleCheckboxAll($event ,availabiltyslot.time_slot, ind)" />Select
                          All
                        </label>

                        <label class="btn btn-filter" style="font-size:18px;">
                          <input type="button" name="checkboxes" style="font-size: 18px; height:18px; width:18px;"
                            data-toggle="modal" data-target="#comment_delete"
                            (click)="deleteSelectSlots($event, ind)" />
                          Delete Slot
                        </label>


                      </div>
                    </div>
                  </div>

                </ng-container>

                <!-- --------------------------------------------------------------------------------------------------------- -->
                <div class="text-center marg-t-50">
                  <span>
                    <button class="done_btn" (click)="next()">Finish</button>
                  </span>

                  <span style="margin-left: 20px;">
                    <button class="done_btn" (click)="updatenext()">Update</button>
                  </span>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12  padd-0 ">
        </div>

      </div>
    </div>
  </div>
</div>






<!-- -------------------------------------- -->


<div class="modal fade" id="comment_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="height: 200px !important;">
      <div class="modal-body">
        <div class="fsize18 font_bold_gilroy clr_black  fw_600">
          Confirmation ! </div>
        <div class="fsize16 marg-t-16 clr_black" style="padding: 25px 0px;"> Are you want to Delete
          The Slots ?..
        </div>
      </div>
      <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
        <button type="button" class="deleteSlotBtn" data-dismiss="modal"
          (click)="deleteMultipleFunction()">Delete</button>
        <button type="button" class="cancelSlotBtn marg-l-10 bgr_clr_blue" style="margin-left: 20px;"
          data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
<!-- ----------------------------------------------------------------- -->


<!-- ------------------------------------ -->

<!--
    <div class="modal fade" id="comment_delete" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="height: 200px !important;">
            <div class="modal-body">
                <div class="fsize18 font_bold_gilroy"> Confirmation ! </div>
                <div class="fsize16 marg-t-16" style="padding: 25px 0px;">  Are you want to cancel the session
                </div>
            </div>
            <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                <button type="button" class="btn_submit_cancel bgr_clr_red"
                    data-dismiss="modal" >Delete</button>
                <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue" style="margin-left: 20px;"
                    data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
    </div> -->



<!-- ----------------------------------- -->


<!-- <table id="notificationsTableId" style="width: 1744px;">
        <thead>
            <tr role="row">
                <th rowspan="1" colspan="1" style="width: 10px;" aria-label="" align="center">
                    <input type="checkbox" id="selectall"   /> <span>Select All</span>
                </th>
                <th tabindex="0" rowspan="1" colspan="1" style="width: 203px;" align="left">Type</th>

            </tr>
        </thead>
        <tbody role="alert">
            <tr class="results-table-row odd">
                <td align="center">
                    <input type="checkbox" class="selectedId" name="selectedId"  />
                </td>
                <td>Checkbox 1</td>

            </tr>
            <tr class="results-table-row even">
                <td align="center">
                    <input type="checkbox" class="selectedId" name="selectedId" />
                </td>
                <td>Checkbox 2</td>

            </tr>
            <tr class="results-table-row even odd">
                <td align="center" class=" ">
                    <input type="checkbox" class="selectedId" name="selectedId" />
                </td>
                <td class=" ">Checkbox 3</td>

            </tr>
        </tbody>
    </table> -->



<!-- ----------------------------------------------------------- -->

<!-- <span class="tooltip-danger"><a data-toggle="tooltip" data-container=".tooltip-danger" title="Some tooltip text!">Hover over me</a></span> -->
