<div class="padd-l-30 padd-t-20">
    <ol class="breadCrumb_flex fsize24">
        <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                [routerLink]="['/dashboard']">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Billing Overview</li>
    </ol>

    <div class="padd-l-30">
        <div class="text-center fsize28 fontClr_6cc3B8">
            Billing Overview
        </div>

        <!-- <div class="fontFamily_gilroymedium fsize18 fontClr_525f7f marg-t-30">
            No.of.Sessions : {{appoinmenCounts}}
        </div>

        <div class="fontFamily_gilroymedium fsize18 fontClr_525f7f marg-t-30">
            Cost Per Session : Rs. {{couchFees}}
        </div> -->

        <div class="row" style="display: block;padding: 0px 110px 0px 30px;">

            <span style="color: #27272a;font-weight: 600;font-size: 20px;">
                Hello {{usernames}}!
            </span>
            <span style="float: right;">
                <div class="input-group date" data-provide="datepicker" id='picker'  >
                    <input type='text' id="clickable-picker"   placeholder="Please Select Date" autocomplete="off"  class="form-control" style="width:240px; border: 1px solid #000; border-radius: 6px; ">
                    <!-- <img src="" alt=""> -->
                    <span class="input-group-addon">
                      <span class="glyphicon glyphicon-calendar"  ></span>
                    </span>
                  </div>
            </span>
        </div>



        <div class="row marg-t-30">

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div
                    style="border: 1px solid #66C63F; height: 150px; width: 340px;border-radius: 20px;background-color: #66C63F;  padding: 25px;">

                    <div>
                        <div class="text-center fsize28 fontClr_fff">
                            Session Hours Booked

                        </div>
                        <div class="text-center fsize28 fontClr_fff">
                            {{sessionBooked}}
                        </div>

                    </div>

                </div>

            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

                <div
                    style="border: 1px solid 
                    #FFA94B; height: 150px; width: 340px;border-radius: 20px;background-color: #FFA94B; padding: 25px;">
                    <div>

                        <div class="text-center fsize28 fontClr_fff">
                            Session hours missed

                        </div>
                        <div class="text-center fsize28 fontClr_fff">
                           {{sessionHoursMissed}}
                        </div>

                    </div>


                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

                <div
                    style="border: 1px solid #6D56CB; height: 150px; width: 340px;border-radius:20px;background-color: #6D56CB; padding: 25px;">

                    <div>

                        <div class="text-center fsize28 fontClr_fff">
                            New Clients

                        </div>
                        <div class="text-center fsize28 fontClr_fff">
                       {{new_Client}}
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div class="row marg-t-30">

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div
                    style="border: 1px solid #00B0F0; height: 150px; width: 340px;border-radius: 20px;background-color: #00B0F0;  padding: 25px;">

                    <div>
                        <div class="text-center fsize28 fontClr_fff">
                            Total Session Hours

                        </div>
                        <div class="text-center fsize28 fontClr_fff">
                    {{total_sessionHours}}
                        </div>

                    </div>

                </div>

            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

                <div
                    style="border: 1px solid #F4B183; height: 150px; width: 340px;border-radius: 20px;background-color: #F4B183; padding: 25px;">
                    <div>

                        <div class="text-center fsize28 fontClr_fff">
                            Earnings before fee and Tax deduction

                        </div>
                        <div class="text-center fsize28 fontClr_fff">
                            {{totalFees}}
                                               </div>

                    </div>


                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

                <div
                    style="border: 1px solid #EA4D89; height: 150px; width: 340px;border-radius: 20px;background-color: #EA4D89; padding: 25px;">

                    <div>

                        <div class="text-center fsize28 fontClr_fff">
                            Gross Net Earnings
                        </div>
                        <div class="text-center fsize28 fontClr_fff">
                 {{grossNetEarnings}}
                        </div>

                    </div>

                </div>

            </div>

        </div>
<!-- -------------------------------------------------------------------------------------------------------->
      
<div class="row">
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
        </div>
      
        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-12">
            <div class="marg-t-30" *ngIf="getAllcoach_statistics.length > 0">
                <div class="" style="background-color: #fff;"  >
                    <table   id="my_Table"  class=""  style="width: 100%;"   >
                        <thead  style="background-color:#ed7d31;color: #fff;" >
                            <tr>
                                <!-- <th>Sl No</th> -->
                                <th>Client Id</th>
                                <th>Client Name
                                </th>
                                <th>Date of Session
                                </th>
                                <th>Duration of session 
                                </th>
                                <th>Session status
                                </th>
                                <th>Start time
                                </th>
                                <th>End time
                                </th>
                                <!-- <th style="border-right: 0px;">Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor = "let item of getAllcoach_statistics | paginate: { itemsPerPage: 5, currentPage: page }"  >
        
                                <!-- <td>{{i}}</td> -->
                                <td>{{item.client}}</td>
                                <td>{{item.clientName}}</td>
                                <td>{{item.date}}</td>
                                <td>{{item.duration}}</td>
                                <td   >{{item.sessionStatus == "booked" ? "No Show":item.sessionStatus}}</td>
                                <td>{{item.startTime}}</td>
                                <td>{{item.endTime}}</td>
                                <!-- <td><img style="height: 20px; width: 20px;" src="assets/img/Icon/Delete_icon.png" alt=""></td> -->
        
                         
                            </tr>
        
               
                      
        
        
                        </tbody>
                    </table>
                </div>


                <div style="margin-top:30px; display:flex; justify-content: center;">
                    <pagination-controls (pageChange)="handlePageChange($event)" previousLabel="Previous" nextLabel="Next"></pagination-controls>
                  </div>
                
        
            </div>



        </div>

        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
        </div>


   

</div>

  </div>

 

  <!-- <div class="row">
<div class="col-2">
    <div class="row">
        <div>raja</div>
        <div>
            <input type="radio">
        </div>

    </div>


</div>

<div class="col-2">
    <span>raja</span>
  
    <input type="radio">
</div>
<div class="col-8">
  
</div>
  

  </div> -->



</div>