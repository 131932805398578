<div class="header pb-8  ">
  <div class="container-fluid">
    <div class="header-body">
      <!-- Card stats -->
      <div class="row ">
        <div class="col-2  mt-3">
          <div class="image_border text-center">
            <!-- <img class="img-fluid header_image" src="assets/img/Characters/happy.png"> -->
            <img class="img-fluid header_image imgFitSize" style="border-radius: 70px; height: 135px; width: 135px;"
              [src]="brainImageUrl">

          </div>
        </div>
        <div class="col-10 mt-3 ">
          <p class="page_heading pl-4">Welcome {{usernames}}</p>
        </div>
      </div>

      <div class="padd-l-30 padd-t-20">
        <!-- <nav aria-label="breadcrumb"> -->
        <ol class="breadCrumb_flex fsize24">
          <li class="breadcrumb-item">
            <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/dashboard']">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Happiness Trainee List</li>
        </ol>
        <!-- </nav> -->
      </div>



      <div class="row">
        <div class="card table_card">
          <table id="myTable" class="row-border hover dataTable no-footer">
            <thead>
              <tr>
                <!-- <th width="25%">First Name</th> -->
                <th width="20%" style="padding-left: 10px;">Full Name</th>
                <th width="10%">ID_Number</th>
                <th width="20%">Type</th>
                <th width="20%">Institution</th>
                <th width="20%" style="border-right: 0px;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of Alldatas; let i = index">
                <td>{{item.thusr_name}}</td>
                <td>{{item.user_id}}</td>
                <td>{{item.user_type}}</td>
                <td>{{item.institution}}</td>
                <td><button type="button" class="btn message" (click)="view_history(item , i)">View History</button>
                </td>
              </tr>
              <!-- <tr></tr> -->
            </tbody>
          </table>
        </div>
      </div>


    </div>
  </div>
</div>