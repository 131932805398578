import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// import { Router } from '@angular/router';


import { ConfirmPasswordValidator } from './confirm-reset-password.validator';
declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-reset-mail-password',
  templateUrl: './reset-mail-password.component.html',
  styleUrls: ['./reset-mail-password.component.css']
})
export class ForgotmailpasswordComponent implements OnInit {


  isShown: boolean = false;
  forgetEmail: any;
  forgetpasswordKey: any;
  password: any;
  confirmPassword = '';
  registerForm: FormGroup;
  submitted: boolean = false;
  constructor(private activatedRoute: ActivatedRoute, private Router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  ngOnInit() {
    // if (!localStorage.getItem('authtoken') && window.location.hash != '#/forgotmailpassword') {
    //   window.location.href = '#/'
    // }
    this.forgetEmail = JSON.parse(localStorage.getItem('currentUser'));
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params.email, 'paramsEmail');
      this.forgetEmail = params.email;
      this.forgetpasswordKey = params.key;
      console.log(params.key, 'paramsKey');
      const userId = params['userId'];
      console.log(userId);
    });



    this.registerForm = this.fb.group(
      {
        password: ["", [Validators.required]],
        confirmPassword: ["", Validators.required]
      },
      {
        validator: ConfirmPasswordValidator("password", "confirmPassword")
      }
    );
  }


  //   const passwordControl = this.registerForm.get('password');       //      Pattern for the password 

  //   if (passwordControl.hasError('pattern')) {
  //     return 'Password must contain at least one uppercase letter';
  //   }
  //   if (passwordControl.hasError('required')) {
  //     return 'Password is required';
  //   }



  async onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var PostAPi = '/update_password_coach';

    const password = this.registerForm.value.password;

    const signupdata = {
      email: this.forgetEmail,
      password: password,
      key: this.forgetpasswordKey
    };

    console.log('API data:', signupdata);

    (await this.ApiService.postApi(PostAPi, signupdata)).subscribe(
      res => {
        console.log('API response:', res);
        if (res.status === 200) {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Password Updated Successfully',
            type: 'success'
          }).then(result => {
            if (result) {
              this.Router.navigate(['login']);
            }
          });
        } else if (res.status === 404) {
          swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Key does not match',
          });
        }
      },
      err => {
        console.error('API call error:', err);
      }
    );
  }

  async submit_Btn() {

  }

}
