<!-- @author:Rajapandi k
      Date : 23/12/2021 -->
<div class="header pb-8 bgr_clr_f5fcff ">
    <div class="container-fluid">
        <div class="header-body">

            <div class="text-center fsize30 font_clr_red marg-t-76">
                Happiness Trainee: {{usrName}}
            </div>
            <div class="text-center  font_clr_red fsize20 marg-t-14">
                Happiness Trainee: {{usrName}}
            </div>

            <div class="marg-t-60">
                <div class="row marg-2rem ">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12   ">

                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12  ">
                        <div class=" cardNotes ">
                            <div class="padd-t-42 padd-l-46 padd-r-42">

                                <div class="fsize21 font_clr_green">
                                    Date: {{journalDate}}


                                </div>



                                <div class="fsize16 font_clr_6a7387 marg-t-20 "   *ngFor="let item of getJournalList"     >
                                    <!-- {{journalMsg}} -->
                                    <div *ngIf="item.thjournal_input_type =='text'"> {{item.thjournal_title}}  </div>

                                    <div *ngIf="item.thjournal_input_type == 'image'">

                                        <img class="" style="height:240px" [src]="item.thjournal_title">

                                    </div>




                                    <div *ngIf="item.thjournal_input_type == 'audio'">

                                        <!-- <audio controls="controls" autobuffer="autobuffer" autoplay="autoplay">
                                            <source ng-src="data:audio/mp3;base64,{{journalMsg}}">
                                        </audio> -->

                                        <!-- <audio id="audio" (click)="playsounds()"></audio> -->
                                        <audio controls loop src="data:audio/mp3;base64,{{item.thjournal_title}}">
                                        </audio>
                                        <!-- <button (click)="playsounds()">play</button> -->

                                        <!-- ------------------------------------ -->



                                        <!-- {{journalMsg}} -->
                                    </div>

                                    <!-- <audio controls><source src="data:audio/mpeg;base64,SUQzAwAAAAA2PVRDT04AAAAFAAAAKDApAFRZRVIAAAA..."></audio> -->



                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12   ">
                    </div>

                </div>
            </div>
            <div class="">

                <div class="row marg-t-40 ">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12   ">

                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12   ">
                        <!-- <div class="smartcardNotes marg-l-20">
                            <div class="row marg-0  ">
                                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 ">

                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12  padd-t-40">



                                    <div class="row marg-0 ">

                                        <div class="marg-l-40">
                                            <img class="h-40" src="assets/img/Icon/pause - gray.png" alt="">
                                        </div>

                                        <div class="marg-l-40">
                                            <img class="h-40" src="assets/img/Icon/stop - pink.png" alt="">
                                        </div>


                                        <div class="marg-l-40">
                                            <img class="h-40" src="assets/img/Icon/play - gray.png" alt="">
                                        </div>

                                    </div>





                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12   ">

                                </div>

                            </div>
                        </div> -->


                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12   ">

                    </div>
                </div>


            </div>


            <div class="text-center marg-t-60">
                <button class="done_btn" (click)="back()">Done</button>
            </div>

        </div>
    </div>
</div>