import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from '../service/api.service';
import { ConfirmPasswordValidator } from '../user-reset-password/confirm-password.validator';
import { Router } from '@angular/router';
import { PasswordStrengthValidator } from "./password-strength.validators"
declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  form: FormGroup;
  filenames: "";
  fileToUploadblog: File | null = null;
  filePath: string;
  isShown: boolean = false;
  base64textString: any;
  type_Type: any;
  firstSessionFree: any;
  redirectLogin_url = "/";
  dropdownList: any = [];
  dropdownLanguageList: any = [];
  // showYesDiv = false;
  firstSession_Free:any;
  showNoDiv = false;
  subsequent_price:any;
  firstSessionsFree:any;
  showYesDiv = false;
  constructor(private fb: FormBuilder, private formBuilder: FormBuilder, private ApiService: ApiService, private router: Router) { }

  ngOnInit() {

    // if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
    //   window.location.href='#/'
    // }
    this.registerForm = this.fb.group(
      {
        email: ["", [Validators.required, Validators.email]],
        password: [null, Validators.compose([
          Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
        confirmPassword: ["", Validators.required],
        fullName: ["", Validators.required],
        // areaOfExpertise: ["",Validators.required],
        methodology: ["", Validators.required],
        exPerience: ["", Validators.required],
        accreditingBody: ["", Validators.required],
        // language: ["",Validators.required],
        firstTherapySession: ["", Validators.required],
        firstSessionFree: ["", Validators.required],
      },
      {
        validator: ConfirmPasswordValidator("password", "confirmPassword")
      }

    );

    this.expertiseGetApi();
    this.languageGetApi();
  }

  // get f() { return this.registerForm.controls; }

  async onSubmit() {
    this.submitted = true;

    var experiseId = $('#multiple-checkboxes').val();
    var experiseTotalId = experiseId.join();
    // multiple-checkboxesOne

    var languageId = $('#multiple-checkboxesOne').val();
    var languageTotalId = languageId.join();

    if (this.registerForm.invalid) {
      return;
    }

    var postapi = "/sign_up_coach";
    var email = $("#email").val();
    var password = $("#passWord").val();
    var fullname = $("#fullName").val();
    var methodology = $("#methodology").val();
    var experience = $("#exPerience").val();
    var licencenumber = $("#licenceNumber").val();
    var accredtingBody = $("#accreditingBody").val();
    var noOfTrainee = $("#trainees").val();
    var areaOfExpertise = $("#areaOfexpertise").val();
    var language1 = $("#language").val();
    var therapySession = $("#firstTherapySession").val();
    var subEquent = $("#subsequent").val();
    // -------------------------------------------------------
    // this.firstSessionsFree
    // if(this.firstSessionsFree == "0" ){
    //    this.firstSession_Free = "0"
    // }
    // if(this.firstSessionsFree == "1" ){
    // }
    this.firstSession_Free = ($("#sessionPrice").val())?$("#sessionPrice").val():0;

    var signupAddData = {
      "email": email,
      "password": password,
      "experience": experience,
      "name": fullname,
      // "license": licencenumber,
      "types_of_therapy": methodology,
      "no_of_trainees": noOfTrainee,
      "accredting_body": accredtingBody,
      "area_expertise": experiseTotalId,
      // "document":  this.base64textString,
      "language": languageTotalId,
      "expectable": therapySession,
      "firstfees": this.firstSession_Free,
      // "firstfree" :"0",
      "subfees" : subEquent


    };

    (await this.ApiService.postApi(postapi, signupAddData)).subscribe(
      res => {
        console.log(res);
        if (res.status === 200) {

          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Added Signup Details ',
          })
          this.router.navigate([this.redirectLogin_url]);
        }
        else if (res.status == 208) {

          swal.fire({
            icon: 'error',
            title: 'error',
            text: 'Email Id Already Exits',
          })

        }
        else if (res.status == 204) {

          swal.fire({
            icon: 'error',
            title: 'error',
            text: 'Already registerd,Waiting for approval',
          })

        }


      });

  }

  async signin() {

  }

  //  handleFileInput(files: FileList) {

  //   this.fileToUploadblog = files.item(0);

  // }

  // imagePreview(e) {

  //   const file = (e.target as HTMLInputElement).files[0];
  //  const reader = new FileReader();
  //  reader.onload = () => {
  //   this.filePath = reader.result as string;

  //   }
  //  reader.readAsDataURL(file)

  // }
  // ---------------------Base64----------------------------
  handleFileSelect(evt) {
    var files = evt.target.files;

    var file = files[0];
    this.type_Type = file.type

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
    console.log(this.base64textString, 'base64');

  }

  alreadyAccount() {
    this.router.navigate([this.redirectLogin_url]);
  }


  async expertiseGetApi() {
    var getapi = "/expertise_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res);
      this.dropdownList = res.result;
      $(document).ready(function () {

        $('#multiple-checkboxes').multiselect({

          includeSelectAllOption: true,
        });
      });


    });
  }

  async languageGetApi() {
    var getapi = "/language_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.dropdownLanguageList = res.result;
      $(document).ready(function () {
        $('#multiple-checkboxesOne').multiselect({
          includeSelectAllOption: true,
        });
      });
    });
  }


  questionOne(e) {
  this.firstSessionsFree = e.target.value;
    if ( this.firstSessionsFree == '0') {
      this.showNoDiv = false;
      this.showYesDiv = true;
      // this.firstSession_Free = "0";
    }
    if (  this.firstSessionsFree == '1') {
      this.showYesDiv = false;
      this.showNoDiv = true;
    }
  }



}
