import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  router: string;
  constructor(private _router: Router) {
    this.router = _router.url;
   }

  ngOnInit() {
    // var autoLogoutTimer;
    // var count=0;
    // resetTimer();
    // $(document).on('mouseover mousedown touchstart click keydown mousewheel DDMouseScroll wheel scroll',document,function(e){
    //     resetTimer();
    // });
    // function resetTimer(){
    //     count=0
    //     clearInterval(autoLogoutTimer)
    //     autoLogoutTimer = setInterval(idleLogout,60000)
    // }
    // function idleLogout(){
    //     count+=1
    //     console.log(count)
    //     if(count == 30){
    //       localStorage.clear()
    //       count=0
    //       window.location.href="#/"
    //     }
    // }
  }

}
