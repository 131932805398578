import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute } from '@angular/router';

declare var swal: any;
import Recorder from 'recorder-js';
declare var MediaRecorder: any;
import * as moment from 'moment';
@Component({
  selector: 'app-trainee-add-newtip',
  templateUrl: './trainee-add-newtip.component.html',
  styleUrls: ['./trainee-add-newtip.component.css']
})
export class TraineeAddNewtipComponent implements OnInit {
  isShown: boolean = false;
  addNewGoals: any;
  session_newId: any;
  sessionUserid: any;
  // session_id: any;
  backUrl = "/trainee-notes";

  audioContext = new AudioContext({ sampleRate: 16000 });
  recorder = new Recorder(this.audioContext, {});
  base64data: any;
  blobFile;
  showhide: boolean;
  recordAudio;
  showhidediv = true;
  voiceText: boolean ;
  showAge:any;
  notesDob:any;
  audiofiles:string;
  song: any;
  audioNotesRecord:any;
  constructor(private router: Router, private ApiService: ApiService,private route: ActivatedRoute) { }

  ngOnInit() {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }

    // this.session_id = localStorage.getItem("session_id");

    this.session_newId = localStorage.getItem('session_id');
    this.sessionUserid = localStorage.getItem('sessionHistoryUsrid');

this.getAudioFileApi();

       // ----------------------------------audio file----------------

       this.recordAudio = () => {
        return new Promise((resolve) => {
          navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            const mediaRecorder = new MediaRecorder(stream, {
              mimeType: 'audio/webm',
              numberOfAudioChannels: 1,
              audioBitsPerSecond: 16000,
            });
            const audioChunks = [];

            mediaRecorder.addEventListener('dataavailable', (event) => {
              audioChunks.push(event.data);
            });

            const start = () => {
              mediaRecorder.start();
            };

            const stop = () => {
              return new Promise((resolve) => {
                mediaRecorder.addEventListener('stop', () => {
                  const audioBlob = new Blob(audioChunks, {
                    type: 'audio/mpeg',
                  });
                  const reader = new FileReader();
                  reader.readAsDataURL(audioBlob);
                  reader.addEventListener(
                    'load',
                    () => {
                      this.base64data = reader.result;
                      console.log(this.base64data, 'base64');
                      this.editVoiceNoteApi();
                      // this.sendObj.audio = base64data;
                      // this.http.post('apiUrl', this.sendObj, httpOptions).subscribe(data => console.log(data));
                    },
                    false
                  );
                  const audioUrl = URL.createObjectURL(audioBlob);
                  console.log('Audiourl', audioUrl);
                  const audio = new Audio(audioUrl);
                  const play = () => {
                    audio.play();
                  };
                  resolve({ audioBlob, audioUrl, play });
                });

                mediaRecorder.stop();
              });
            };
            resolve({ start, stop });
          });
        });
      };
  // -------------------------------------------------------------------------------

  }

  async saveAddgoalsApi() {


    var todayDate = new Date().toISOString().slice(0, 10);
    console.log(todayDate);


    var postApi = "/add_notes_voice";
    var getlistMsgdata = {

      "session_id":this.session_newId,
      // "session_id": this.session_newId,
      "date": todayDate,
      "value": this.addNewGoals,
      "user_id": this.sessionUserid,
      "type": "goal"
    };
    (await this.ApiService.postApi(postApi, getlistMsgdata)).subscribe(
      res => {
        if (res.status === 200) {

          swal.fire({
           icon: 'success',
           title: 'Success',
           text: '  New Tips Added Successfully',
         })

         .then(() => {
          this.router.navigate(['/trainee-notes'])
        })
       }

      })
  }

  addNewTip(){
    this.router.navigate([this.backUrl]);
  }

  async getAudioFileApi(){
    var postapi = "/view_session_history";
    var postCommentdata = {

      "session_id": this.session_newId
    };
    (await this.ApiService.postApi(postapi, postCommentdata)).subscribe(
      res => {
        console.log(res);

        // this.addNotesList = res.data[0].thchhistory_value;
        // var noteDate = res.data[0].thchhistory_date;
        // this.notesDate = moment(noteDate).format('DD-MM-YYYY');
        // console.log(this.notesDate);
        var addGoalData = res.data.reverse();
        for(let item of addGoalData ){
          var type = item.thchhistory_category;

          if(type == "goal"){
            // this.audiofiles = item.thchhistory_voice;
            // console.log(this.audiofiles ,'audiofiles');


          this.ageCalculator();
          }
         }

      });
  }

  // -----------------------------------------

    // -------------audio file------------------------

    async startPlay() {
      this.recorder = await this.recordAudio();
      this.recorder.start();
      this.showhide = true;
      this.showhidediv = false;
      this.voiceText = true;
    }

    async stopPlay() {
      const audio = await this.recorder.stop();
      this.audioNotesRecord = audio;
      this.voiceText = false;

    }
    runplay() {
      this.audioNotesRecord.play();
    }

    async editVoiceNoteApi() {
      var postapi = "/edit_voice_notes";
      var postCommentdata = {
        "id": this.session_newId,
        "voice": this.base64data
      };
      (await this.ApiService.postApi(postapi, postCommentdata)).subscribe(
        res => {
          console.log(res);
        });
    }

    ageCalculator(){
      if(this.notesDob){
        const convertAge = new Date(this.notesDob);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);
        console.log( this.showAge, "showage");
      }
    }

    playAudio(){
      const audio = new Audio();
      audio.src = this.audiofiles;
      audio.load();
      audio.play();
    }
    skip(time) {


      // this.song = new Audio;
      // this.song.type = 'audio/mpeg';
      // this.song.src = 'https://www.bensound.com/bensound-music/bensound-summer.mp3';//Audio file source url
        if (time == 'back') {
          this.song.currentTime = (this.song.currentTime - 5);
        } else if (time == 'fwd') {
          this.song.currentTime = (this.song.currentTime + 5);
        }
      }

      playpause() {
        // var song: any;
        this.song = new Audio;
        this.song.type = 'audio/mpeg';
      this.song.src = this.audiofiles;//Audio file source url
        if (!this.song.paused) {
          this.song.pause();
        } else {
          this.song.play();
        }
      }





}
