import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

declare var $: any;
@Component({
  selector: 'app-trainee-roster-list',
  templateUrl: './trainee_roster_list.component.html',
  styleUrls: ['./trainee_roster_list.component.scss']
})
export class TraineeRosterListComponent implements OnInit {
  title = 'datatables';
  Alldatas: any;
  getlivechatdata: any;
  dtOptions: DataTables.Settings = {};
  constructor(private router: Router, private ApiService: ApiService) { }
  returnUrl = "/dashboard";
  historyUrl = "/trainee-notes"
  usernames = '';
  isShown: boolean = false;
  brainImageUrl = 'https://coach.thap.in/thap_coach/assets/img/Characters/happy.png'
  async ngOnInit() {
    if (!localStorage.getItem('authtoken') && window.location.hash != '#/login') {
      window.location.href = '#/'
    }

    this.usernames = localStorage.getItem('userName');
    // var getapi = "/coach/coaches";


    // ----------------------------

    var getapi = "/coach_users";
    var table;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res);
      this.Alldatas = res.result;
      console.log(this.Alldatas);
      localStorage.setItem('userId', res.result.thusr_id);
      localStorage.setItem('user_Ids', res.result.user_id);
      localStorage.setItem('user_Ids', res.result.user_type);
      $(function () {
        table = $("#myTable").DataTable({
          "ordering": false,
          destroy: true
        });
      })
    });

    this.getProfilePictureApi();

  }
  view_history(selectedItem: any, i) {
    this.ApiService.user_ids = selectedItem.user_id;
    this.ApiService.index_userId = i + 1;
    localStorage.setItem('indexId', i + 1);
    localStorage.setItem('userListName', selectedItem.thusr_name);
    localStorage.setItem('sessionHistoryUsrid', selectedItem.user_id);
    localStorage.setItem('user_type', selectedItem.user_type);
    localStorage.setItem('institution', selectedItem.institution);
    localStorage.setItem('peerid', selectedItem.user_id);
    localStorage.setItem('session_newId', selectedItem.id);
    this.router.navigate([this.historyUrl]);

  }
  back() {
    this.router.navigate([this.returnUrl]);
  }
  async getProfilePictureApi() {
    var userids = JSON.parse(localStorage.getItem('userid'));
    var postApi = "/coach_view_profile_picture";
    // --------------------
    var uploadPicPost = {

      "id": userids
    };
    (await this.ApiService.postApi(postApi, uploadPicPost)).subscribe(
      res => {

        var getProfilePicdata = res.result;
        for (let item of getProfilePicdata) {

          var imagedata = item.thcoach_image;

          // if (getProfilePicdata.length > 0) {
          //   this.brainImageUrl  = imagedata;
          // }
          // else if (getProfilePicdata.length = 0) {
          //   this.brainImageUrl  = 'https://fatneedle.com/thap_coach/assets/img/Characters/happy.png';
          // }
          if (imagedata == null) {
            this.brainImageUrl = 'https://coach.thap.in/thap_coach/assets/img/Characters/happy.png';
          }
          else if (imagedata != null) {
            this.brainImageUrl = imagedata;

          }

        }
      });
  }
}
