import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import * as moment from 'moment';
declare var $: any;
declare let swal: any;

@Component({
  selector: 'app-appointmenthistory',
  templateUrl: './appointmenthistory.component.html',
  styleUrls: ['./appointmenthistory.component.css']
})
export class AppointmenthistoryComponent implements OnInit {
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  message_url = "/trainee-roster";
  session_url = "/happiness-training-session";
  returnUrl = "/dashboard";
  appointmentAllList = [];
  channelid: any = '';
  usernames = '';
  isShown: boolean = false;
  appointmentTime: any;
  timing = [];
  timings = [];
  dummyarr = [];
  tokens: any;
  cancel_Id: any;
  durationTime:any;
  brainImageUrl = 'https://coach.thap.in//thap_coach/assets/img/Characters/happy.png';

  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
      window.location.href='#/'
    }

    // ----------------------------
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 4,
    //   processing: true
    // };
    this.getCoachListApi();
    this.getProfilePictureApi();


  }

  show_more_menu(e) {
    if (!confirm(`Go to ${e.target.href} ?`)) e.preventDefault();
  }

  async getCoachListApi() {

    var getapi = "/coach_appointment_total_list";

    this.usernames = localStorage.getItem('userName');

    var token = this.ApiService.loginToken;
    this.tokens = localStorage.getItem('authtoken');
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6OCwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20ifSwiaWF0IjoxNjUxMTM5Nzc3fQ.Asn9aydUmT9lz96o09x8uitH2Sa9RdhTlGkmml04VdE"
    (await this.ApiService.postApi(getapi, this.tokens)).subscribe((res) => {
      var table;

      this.timings = res.data;
      $(function () {
        table = $("#myTable").DataTable({
     "ordering": false,
     destroy: true
       });
       })

      for (let item of this.timings) {

        // var appointmentstatus = item.appointment_status;
        // if (appointmentstatus == "Booked" || appointmentstatus == "booked"  || appointmentstatus == "Reschedule" ) {

          var timeString = item.time;
          var hourEnd = timeString.indexOf(":");
          var H = +timeString.substr(0, hourEnd);
          var h = H % 12 || 12;
          var ampm = H < 12 ? "AM" : "PM";
          this.appointmentTime = h + timeString.substr(hourEnd, 3) + ampm;
          this.timing = this.appointmentTime
          // document.write(timeString);
          // console.log(this.appointmentTime,'timing')

         var duration_Time = item.duration;
         console.log(duration_Time ,'....time');
         console.log(moment(duration_Time,"HH:mm:ss").format("hh:mm"));

          var durationTime = moment(duration_Time,"HH:mm:ss").format("HH[ hour(s), ]mm[ minute(s).]");
          duration_Time  = "00:30:00" ? "30":"60";
          console.log(durationTime,'durationTime');


         //  durationTime

          this.dummyarr.push({ ...item, time: this.timing ,duration:durationTime,booked_datetime: (item.reschedule_datetime)?item.reschedule_datetime:(item.booked_datetime)?item.booked_datetime:item.cdate });
          // this.appointmentAllList.push( this.timing);
        // }


      }
      console.log(this.dummyarr, 'dummy')
      this.appointmentAllList = res.data;
      console.log(this.appointmentAllList);


      if (res) {
        if (res.status === 200) {

        }
        else if (res.status === 401) {
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Unauthorized access!',

          })
        }
      }
    });
  }

  
  message(item: any) {
    // localStorage.setItem('coach_id',item.coach_id);
    localStorage.setItem('peerid', item.user_id);
    localStorage.setItem('session_id', item.id);
    localStorage.setItem('session_UsrName', item.thusr_name);

    this.router.navigate([this.message_url]);
  }
  session(items: any) {
    localStorage.setItem('appnumber', items.app_number);
    localStorage.setItem('sessionUsrId', items.user_id);
    localStorage.setItem('session_id', items.id);
    localStorage.setItem('session_Date', items.date);
    localStorage.setItem('session_CoachId', items.coach_id);
    this.ApiService.appointmentSessionId = items.id;
    this.ApiService.appointmentCoachId = items.coach_id;
    localStorage.setItem('coachUserName', items.thusr_name);
    localStorage.setItem('timeDuration', items.duration);
    localStorage.setItem('peerid', items.user_id);
    this.router.navigate([this.session_url]);
  }
  cancel() {
    this.router.navigate([this.returnUrl]);
  }
  async cancelAppointment(cancelItems: any) {

    this.cancel_Id = cancelItems.id;
    console.log(this.cancel_Id, 'delId')

    // var postApi = "/cancel_appointment";
    // var cancelId = {
    //   "id": cancel_Id
    // };
    // (await this.ApiService.postApi(postApi, cancelId)).subscribe(
    //   res => {
    //     this.getCoachListApi();
    //   });
  }

  async cancelAppointmentDeleteApi() {

    var postApi = "/cancel_appointment";
    var cancelId = {
      "id": this.cancel_Id
    };
    (await this.ApiService.postApi(postApi, cancelId)).subscribe(
      // res => {
      //   this.getCoachListApi();
      // });

      res => {
        console.log(res);

        if (res.status == 200) {
          swal.fire({
            icon: 'Success',
            title: 'Success',
            text: 'Appointment Cancel Sucessfully!',

          })
          window.location.reload();
          this.getCoachListApi();
        }

      });




    // this.getCoachListApi()

  }




  async getProfilePictureApi() {
    var userids = JSON.parse(localStorage.getItem('userid'));
    var postApi = "/coach_view_profile_picture";
    // --------------------
    var uploadPicPost = {

      "id": userids
    };
    (await this.ApiService.postApi(postApi, uploadPicPost)).subscribe(
      res => {

        var getProfilePicdata = res.result;
        for (let item of getProfilePicdata) {

          var imagedata = item.thcoach_image;

          // if (getProfilePicdata.length > 0) {
          //   this.brainImageUrl  = imagedata;
          // }
          // else if (getProfilePicdata.length = 0) {
          //   this.brainImageUrl  = 'https://fatneedle.com/thap_coach/assets/img/Characters/happy.png';
          // }
          if (imagedata == null) {
            this.brainImageUrl = 'https://coach.thap.in/thap_coach/assets/img/Characters/happy.png';
          }
          else if (imagedata != null) {
            this.brainImageUrl = imagedata;

          }
        }
      });
  }

}
