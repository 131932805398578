import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, Params } from '@angular/router';

// import { ConfirmPasswordValidator } from "./confirm-password.validator";
import { ConfirmPasswordValidator } from './confirm-password.validator';
declare var swal: any;

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  isShown: boolean = false;
  mail: any;
  key: any;
  type: any;
  password: any;
  confirmPassword = '';
  registerForm: FormGroup;
  submitted: boolean = false;
  constructor(private activatedRoute: ActivatedRoute, private Router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  ngOnInit() {
    // if (!localStorage.getItem('authtoken') || window.location.hash != '#/forgotmailpassword') {
    //   window.location.href = '#/'
    // }
    this.mail = JSON.parse(localStorage.getItem('currentUser'));
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params.email, 'paramsEmail');
      this.mail = params.email;
      this.key = params.key;
      this.type = params.device;
      console.log(params.key, 'paramsKey');
      console.log(params.device, 'Device')
      const userId = params['userId'];
      console.log(userId);
    });

    this.registerForm = this.fb.group(
      {

        password: ["", [Validators.required]],
        confirmPassword: ["", Validators.required]
      },
      {
        validator: ConfirmPasswordValidator("password", "confirmPassword")
      }

    );

  }
  

  async onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var postapi = "/update_password";

    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log(currentUser, 'currentusers');

    var password = this.registerForm.value.password;

    console.log(password, 'newpass');

    var signupdata = {
      "email": this.mail,
      "password": password,
      "key": this.key,
    };

    console.log('Api_datas', signupdata);

    (await this.ApiService.postApi(postapi, signupdata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Password Updated Successfully',
              type: 'success'
            })
              .then((result) => {
                if (result) {
                  // Redirect based on device type
                  if (this.type == 'IOS') {
                    window.location.href = 'https://thap.in/login';
                  } else {
                    window.location.href = 'https://thap.in/login';
                  }
                }
              })
          }

          else if (res.status == 404) {
            swal.fire({
              icon: 'error',
              title: 'error',
              text: 'Key does not match',
            })
          }
        }
      },
      err => {
        console.error('API call error:', err);
      }
    );
  }

  async submit_Btn() {

  }

}
