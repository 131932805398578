<div class="header pb-8  ">
  <div class="container-fluid">
    <div class="header-body">
      <!-- Card stats -->
      <div class="row ">
        <div class="col-2  mt-3">
          <div class="image_border text-center">
            <img class="img-fluid header_image imgFitSize" style="border-radius: 70px; height: 135px; width: 135px;"
              [src]="brainImageUrl">
            <!-- <img class="img-fluid header_image" src="assets/img/Characters/happy.png"> -->

          </div>
        </div>
        <div class="col-10 mt-3 ">
          <p class="page_heading pl-4">Welcome {{usernames}}</p>
        </div>
      </div>
      <div class="padd-l-30 padd-t-20">
        <!-- <nav aria-label="breadcrumb"> -->
        <ol class="breadCrumb_flex fsize24">
          <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
              [routerLink]="['/dashboard']">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">MessageCenter List</li>
        </ol>
        <!-- </nav> -->
      </div>
      <div class="row">
        <div class="card table_card">
          <table id="myTable" class="row-border hover dataTable no-footer">
            <thead>
              <tr>
                <th width="20%">Name</th>
                <th width="20%">New Message</th>
                <th width="10%" style="border-right: 0px;">Action</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of messageList">
              <tr [ngClass]="item.coach_view == 0 ? 'red' : ''">
                <td>{{item.thusr_name}}</td>
                <td>{{item.message}}</td>
                <td>
                  <div>
                    <button type="button" class="btn message" (click)="messageBtn(item)">Message</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>