import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
@Component({
  selector: 'app-forgotpassword-otp',
  templateUrl: './forgotpassword-otp.component.html',
  styleUrls: ['./forgotpassword-otp.component.css']
})
export class ForgotpasswordOtpComponent implements OnInit {
  isShown:boolean = false;
  constructor(private router: Router , private ApiService: ApiService) { }
  forgotpasswordOtp_url = '/reset-password';
  ngOnInit(): void {
    // if(!localStorage.getItem('authtoken') && window.location.hash != '#/login'){
    //   window.location.href='#/'
    // }

  }

  async submit_btn(){
    this.router.navigate([this.forgotpasswordOtp_url]);
    var postapi = "/otp_validate_coach";
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  console.log(currentUser , 'rajal');
    var emailotp = localStorage.getItem('otp');

    var signupdata = {
      'email': currentUser,
      'otp' : emailotp

    };

 (await this.ApiService.postApi(postapi, signupdata)).subscribe(
   res => {
        localStorage.setItem('token', '');
        localStorage.setItem('token', res.token);

        if (res) {
          if (res.status === 200 && res.message == 'OTP verified successfully'  ) {


          }

          // else if (res.status === 200) {
          //     return [{ status: res.status, json: res }]
          // }
        }

    });


  }

}
